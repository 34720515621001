html {
  font-size: 0.73vw;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #303030;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  font-size: 1.6rem;
}

/* Header starts here */
#header {
  padding: 4rem 7rem;
  display: flex;
  justify-content: space-between;
}

.header-left {
  display: flex;
  gap: 2.3rem;
}

.header-left img {
  width: 6.2rem;
  height: 6.2rem;
}

.header-left h1 {
  font-size: 2.4rem;
}

.header-left div .header-heading-subtext {
  font-size: 1.6rem;
  display: flex;
  gap: 1rem;
}

.header-left div .header-heading-subtext p span {
  opacity: 0.4;
}

.header-left div .header-heading-subtext p:first-child {
  color: #ff0000;
  opacity: 1;
}

.header-right .steps {
  display: flex;
  width: 100%;
  font-size: 1.4rem;
}

.steps p {
  padding: 0.2rem 0.9rem;
  border-radius: 50%;
  background-color: #e7e5e9;
  opacity: 0.8;
}

.steps p:first-child {
  padding: 0.2rem 1.1rem;
}

.steps hr {
  border: none;
  background-color: #000;
  height: 0.2rem;
  opacity: 0.08;
  width: 100%;
  margin-top: 1.1rem;
}


.step-names {
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.step-names p {
  font-weight: 600;
  font-size: 1.2rem;
  opacity: 0.6;
  text-align: center;
}

.step-names p:nth-child(1) {
  text-align: left;
}

.step-names p:nth-child(3) {
  opacity: 1;
}

.step-names p:nth-child(3) {
  margin-left: 3rem;
}

.step-names p:last-child {
  margin-left: 5.5rem;
}

#container.ncdstep3 {
  padding: 0rem 7rem 16rem 7rem;
  grid-template-columns: auto;
  min-height: 450px;
}

.single_input input {
  background: #f0f0f0;
  border: none;
  width: 100%;
  padding: 0 20px;
  height: 60px;
  border-radius: 10px;
}

.single_input label {
  display: block;
  margin-bottom: 0;
}

.wpimain {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 0fr;
  gap: 96px;
}

.wpimain p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #1D1D1D;
}

.upiwrap {
  display: flex;
  width: 100%;
  max-width: 500px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #F5F7FF;
  position: relative;
}

.upiwrap select {
  border-radius: 0;
  padding-left: 10px;
  border: none;
  background: rgba(18, 18, 18, 0.02) !important;
  height: 4.8rem;
  width: 55%;
  border-left: 1px solid #ccc;
}

.upiwrap img {
  position: absolute;
  right: 10px;
}

.bank-name select,
.location select {
  border: 1px solid #F5F7FF;
}

.bank-name select option,
.location select option {
  padding: 0 10px;
}

.header-heading-subtext p span {
  opacity: 1 !important;
}

.wpimain>label {
  opacity: 0.6;
}

p.upifetchfailed {
  color: #f40000;
}

.upimsg {
  margin-top: 15px;
}

p.upifetchsuccess {
  color: #025002;
  font-size: 14px;
}

section#container.ncdstep3 h1 {
  font-size: 20px !important;
}

#container h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
}

#container div p {
  font-size: 1.4rem;
  opacity: 0.6;
  margin-bottom: 1rem;
}

.type {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: rgb(0, 0, 0, 0.06);
  height: 4.8rem;
  width: 26.4rem;
  box-sizing: border-box;
  font-size: 1.6rem;
  color: rgb(0, 0, 0, 0.5);
  padding: 0;
  border-radius: 5rem;
}

.type span {
  cursor: pointer;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.type .switch {
  position: absolute;
  background-image: linear-gradient(107deg, #21f3f3 -77%, #214cf3 96%);
  color: #ffff;
  left: 0;
  top: 0;
  width: 50%;
  height: 4.8rem;
  border-radius: 5rem;
  box-sizing: border-box;
  padding: 1.1rem 0;
  text-align: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.row-2 {
  margin: 3.6rem 0 4.8rem 0;
  display: flex;
  gap: 2.4rem;
}

.bank-name {
  /* width: 26.4rem; */
  height: 4.8rem;
  box-sizing: border-box;
  border-radius: 0.8rem;
  background: rgba(18, 18, 18, 0.02) !important;
  position: relative;
}

select {
  font-family: "Poppins", sans-serif;
  color: #303030;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  z-index: 1;
  position: relative;
  cursor: pointer;
  /* opacity: 0.6; */
}

input[type="text"]:focus,
select:focus {
  outline: none;
}

input[type="text"],
input[type="number"] {
  height: 4.8rem;
  width: 26.4rem;
  background-color: rgb(0, 0, 0, 0.06);
  border: none;
  box-sizing: border-box;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  border-radius: 0.8rem;
  font-family: "Poppins", sans-serif;
  color: rgb(0, 0, 0, 0.6);
  outline: none;
}

.row-3 .location {
  box-sizing: border-box;
  height: 4.8rem;
  width: 26.4rem;
  background: rgba(18, 18, 18, 0.02) !important;
  border: 1px solid #F5F7FF;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
}

.row-3 .location input[type="text"] {
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
}

.row-3 .location img {
  width: 2.4rem;
  height: 2.4rem;
}

footer {
  background-image: linear-gradient(94deg, #21f3f3 -79%, #214cf3 93%);
  padding: 1rem 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer a {
  display: flex;
  align-items: center;
}

footer p {
  color: #fff;
  font-size: 2.4rem;
}

footer p span {
  display: block;
  font-size: 1.6rem;
  opacity: 0.6;
}

footer .buttons {
  display: flex;
  gap: 7.5rem;
}

footer .buttons .prev-btn {
  font-size: 2rem;
  font-weight: 400;
  color: #ffff;
  gap: 1.6rem;
}

footer .buttons .next-btn {
  font-weight: 600;
  justify-content: space-between;
  width: 15.7rem;
  color: #241f55;
  background-color: #fff;
  font-size: 2.4rem;
  padding: 1.6rem 2.4rem 1.6rem 2.4rem;
  border-radius: 1.2rem;
}



.step_three_wraop label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0;
}

.step_three_wraop input[type="text"],
.step_three_wraop input[type="number"] {
  height: 4.8rem;
  width: 100% !important;
  background: rgba(18, 18, 18, 0.02) !important;
  border: 1px solid #F5F7FF;
  box-sizing: border-box;
  padding: 0 1.6rem;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 30px;
  color: #0E0E0E;
}

.upiwrap input[type="text"] {
  text-align: right;
}

.isSelf_oth {
  font-weight: 500;
  color: #000000;
}

.step_connect_upi {
  display: block;
  content: "";
  height: 88px;
  position: absolute;
  border-left: 2px dashed #B4B4B4;
  left: 9px;
  top: 233px;
}

.payment_check {
  z-index: 111;
}

.ncd_step_one_header {
  padding-top: 40px;
  padding-bottom: 30px;
}

.total_invest_series h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #25212E !important;
}

.total_invest_series p {
  margin-bottom: 0;
}

.asba_pay_wrap p {
  margin-bottom: 10px !important;
  color: #1D1D1D !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.apply_as p {
  margin-bottom: 10px !important;
}

.asba_pay_wrap {
  padding: 40px 0;
}


.disclamer {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #635F5F;
  letter-spacing: 0px;
  padding-bottom: 100px;
}

.disclamer span {
  color: #7D21C4;
  font-weight: 700;
  cursor: pointer;
}

.disclamer_content h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding-bottom: 20px;
}

.disclamer_content ul li {
  list-style: disc;
  margin-left: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 30px; */
  color: #4E4E4E;
  padding-bottom: 10px;
}

.dis_close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
}

.dis_close img {
  cursor: pointer;
}

.disclaimerPop {
  padding: 30px 30px 10px 30px !important;
  background: #FFFFFF;
  border-radius: 20px !important;
}


@media (max-width: 769px) {
  .dis_close img {
    width: 15px;
  }

  .dis_close {
    top: 4px;
    right: 15px;
  }

  .disclaimerPop {
    padding: 15px !important;
  }

  .disclamer_content h3 {
    font-size: 14px;
  }



  .step_three_wraop {
    background: #f9f8ff;
    height: 100%;
    padding-bottom: 100px;
  }

  html {
    font-size: 1.29vw;
  }

  .referral_code {
    padding: 30px 0 10px 0;
  }

  .referral_code input[type="text"] {
    background: #fff !important;
  }

  .referral_code p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #1D1D1D;

  }

  .total_invest_series h2 {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }

  #header {
    flex-direction: column-reverse;
    justify-content: column-reverse;
    gap: 3rem;
    padding: 4rem;
  }

  .header-left div .header-heading-subtext {
    flex-direction: column;
    gap: 0;
  }

  .header-left div .header-heading-subtext .seperator {
    display: none;
  }

  .step-names {
    gap: 11rem;
  }

  #container {
    padding: 6rem 4rem 8rem 4rem;
  }

  .row-2 {
    flex-direction: column;
  }

  .bank-name,
  input[type="text"],
  .row-3 .location {
    width: 100%;
    /* max-width: 400px; */
  }

  footer {
    padding: 1rem 4rem;
  }

  footer .buttons {
    gap: 2.5rem;
  }

  .wpimain {
    grid-template-columns: auto;
  }

  #container.ncdstep3 {
    padding: 0rem 3rem 20rem 3rem;
    grid-template-columns: auto;
  }

  .ncd_step_one_header {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .step_three_wraop input[type="text"],
  .step_three_wraop input[type="number"] {
    height: 48px;
    width: 100% !important;
    border: 1px solid #F5F7FF;
    font-size: 14px;
  }

  .step_three_wraop select {
    height: 48px !important;

  }

  .step_three_wraop .wpimain select {
    width: 132px;
    font-size: 14px;
    text-overflow: ellipsis;
    padding-right: 42px;
  }

  .bank-name {
    height: 48px;
    padding: unset;
  }

  .step_three_wraop .row-3 .location {
    height: 48px;
    padding: unset;
  }

  .payment_box_shodow {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
  }

  .step_connect_upi {
    left: 10px;
    top: 225px;
  }
}


@media (min-width: 320px) and (max-width: 480px) {

  html {
    font-size: 1.9vw;
  }

  #header {
    padding: 4rem 2rem;
  }

  .step-names {
    gap: 4rem;
  }

  #container {
    padding: 6rem 2rem 8rem 2rem;
  }

  footer {
    padding: 1rem 2rem;
  }

  footer p span {
    font-size: 1rem;
  }

  footer .buttons {
    gap: 1rem;
  }

  footer .buttons .prev-btn {
    gap: 1rem;
    font-size: 1.8rem;
  }

  footer .buttons .next-btn {
    width: 11.5rem;
    font-size: 2rem;
  }

  .step_connect_upi {
    left: 11px;
    top: 226px;
  }
}