.asba_confirm_model_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 1000;
    background: #222222bf;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.mobile_asba_confirm_model_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile_asba_confirm_model {
    width: 520px;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    border: 1px solid #ddd;
}

.asba_confirm_model {
    width: 520px;
    background: #FFFFFF;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.asba_pay_approve {
    background: #FAFAFA;
    border-radius: 7px;
    max-width: 381px;
    width: 100%;
    margin: 0px auto;
    padding: 16px 10px 14px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_checkdd {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 9.5px;
    width: 24px;
    height: 23px;
    background: #FFEACE;
    border-radius: 6px;
    color: #C16F00;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 23px;
    margin-right: 20px;
    margin-top: 3px;
}

.asba_confirm_model_container {
    width: 381px;
}


.payment_info img {
    width: 21.99px
}

.pay_approve img {
    width: 30px;
}

.payment_info {
    margin-right: 20px;
    margin-top: 5px;
    z-index: 111;
    background: white;
}

.asba_view_app_Btn a {
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: linear-gradient(90deg, #7328D9 0%, #8D26D2 100%);
    border-radius: 50px;
    border: none;
    outline: none;
    color: #ffffff !important;
    text-align: center;
    cursor: pointer;
}

.asba_confirm_Done_buttn button {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border-radius: 50px;
    border: 1px solid #8728D5;
    outline: none;
    color: #8728D5;
    margin-top: 20px;
}

.stepsWrap {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 14px;
    padding: 20px;
    position: relative;
}

.payment_checkdd span {
    color: #1D1D1F;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.Your_next_steps {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #282828;

}

.asba_close_icon {
    position: relative;
}

.asba_close_icon img {
    position: absolute;
    right: -20px;
    top: 5px;
    cursor: pointer;
}

@media(max-width:768px) {
    .asba_confirm_model_container {
        width: 100%;
        padding: 0 15px;
    }

    .asba_close_icon img {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .mobile_asba_confirm_model {
        border: none;
    }

    .asba_confirm_model {
        width: 100%;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .asba_close_icon img{
        top: -20px;
    }
}