.ncd_wrap_grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
}


@media(max-width:768px) {
    .ncd_detail_wraper {
        border-top: 1px solid #F5E9FF;
    }

    .sec_detail_box {
        background: #ffffff;
        border-radius: 8px;
        padding-bottom: 20px !important;
    }

    .ncd_wrap_grid {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: unset;
    }
}