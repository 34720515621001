html {
  font-size: 0.73vw;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #303030;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  font-size: 1.6rem;
}

/* Header starts here */
#header {
  padding: 4rem 7rem;
  display: flex;
  justify-content: space-between;
}

.header-left {
  display: flex;
  gap: 2.3rem;
}

.header-left img {
  width: 6.2rem;
  height: 6.2rem;
}

.header-left h1 {
  font-size: 2.4rem;
}

.header-left div .header-heading-subtext {
  font-size: 1.6rem;
  display: flex;
  gap: 1rem;
}

.header-left div .header-heading-subtext p span {
  opacity: 0.4;
}

.header-left div .header-heading-subtext p:first-child {
  color: #ff0000;
  opacity: 1;
}

.header-right .steps {
  display: flex;
  width: 100%;
  font-size: 1.4rem;
}

.steps p {
  padding: 0.2rem 0.9rem;
  border-radius: 50%;
  background-color: #e7e5e9;
  opacity: 0.8;
}

.steps p:first-child {
  padding: 0.2rem 1.1rem;
}



.steps hr {
  border: none;
  background-color: #000;
  height: 0.2rem;
  opacity: 0.08;
  width: 100%;
  margin-top: 1.1rem;
}


.step-names {
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.step-names p {
  font-weight: 600;
  font-size: 1.2rem;
  opacity: 0.6;
  text-align: center;
}

.step-names p:nth-child(1) {
  text-align: left;
}

.step-names p:nth-child(2) {
  opacity: 1;
}

.step-names p:nth-child(3) {
  margin-left: 3rem;
}

.step-names p:last-child {
  margin-left: 5.5rem;
}

#container.ncdcontainer2 {
  padding: 0rem 7rem 16rem 7rem !important;
  grid-template-columns: auto;
}

#container h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
}

.fields {
  display: grid;
  grid-gap: 3rem;
  gap: 3rem;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1016px;
}

.fields h2 {
  grid-column: 1/-1;
  margin-bottom: -10px;
  margin-top: 25px;
}

.invest-as select {
  background: none;
  border: none;
}

.single_input input {
  background: #f0f0f0;
  border: none;
  width: 100%;
  padding: 0 20px;
  height: 60px;
  border-radius: 10px;
}

.single_input label {
  opacity: 0.6;
}

.lable_info_icon {
  display: flex;
  align-items: center;
}

.lable_info_icon small {
  position: relative;
  cursor: pointer;
  margin-left: 7px;
}

.ncdcontainer2 .fields div p {
  font-size: 14px !important;
  opacity: 0.6;
  margin-bottom: 1rem;
}

.type {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: rgb(0, 0, 0, 0.06);
  height: 4.8rem;
  width: 26.4rem;
  box-sizing: border-box;
  font-size: 1.6rem;
  color: rgb(0, 0, 0, 0.5);
  padding: 1.1rem 4.7rem;
  border-radius: 5rem;
}

.type span {
  cursor: pointer;
}

.type .switch {
  position: absolute;
  background-image: linear-gradient(107deg, #21f3f3 -77%, #214cf3 96%);
  color: #ffff;
  left: 0;
  top: 0;
  width: 50%;
  height: 4.8rem;
  border-radius: 5rem;
  box-sizing: border-box;
  padding: 1.1rem 0;
  text-align: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.invest-as {
  width: 400px;
  height: 4.8rem;
  box-sizing: border-box;
  border-radius: 0.8rem;
  position: relative;
}

select {
  font-family: "Poppins", sans-serif;
  color: #303030;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 1.6rem;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.invest-as select {
  padding: unset;
  color: black;
}

select:focus {
  outline: none;
}

#container h2:last-child {
  color: #2e276c;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 5rem;
}

#container h2:last-child img {
  width: 2.4rem;
  height: 2.4rem;
}

footer {
  background-image: linear-gradient(94deg, #21f3f3 -79%, #214cf3 93%);
  padding: 1rem 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer a {
  display: flex;
  align-items: center;
}

footer p {
  color: #fff;
  font-size: 2.4rem;
}

footer p span {
  display: block;
  font-size: 1.6rem;
  opacity: 0.6;
}

footer .buttons {
  display: flex;
  gap: 7.5rem;
}

footer .buttons .prev-btn {
  font-size: 2rem;
  font-weight: 400;
  color: #ffff;
  gap: 1.6rem;
}

footer .buttons .next-btn {
  font-weight: 600;
  justify-content: space-between;
  width: 15.7rem;
  color: #241f55;
  background-color: #fff;
  font-size: 2.4rem;
  padding: 1.6rem 2.4rem 1.6rem 2.4rem;
  border-radius: 1.2rem;
}

.investing_as,
.apply_for {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3E3E3E;
}

@media (max-width: 769px) {
  html {
    font-size: 1.29vw;
  }

  #header {
    flex-direction: column-reverse;
    justify-content: column-reverse;
    gap: 3rem;
    padding: 4rem;
  }

  .header-left div .header-heading-subtext {
    flex-direction: column;
    gap: 0;
  }

  .header-left div .header-heading-subtext .seperator {
    display: none;
  }

  .step-names {
    gap: 11rem;
  }

  #container {
    padding: 4rem 4rem 16rem 4rem;

  }

  footer {
    padding: 1rem 4rem;
  }

  footer .buttons {
    gap: 2.5rem;
  }

  .fields {
    flex-direction: column;
  }

  .invest-as {
    width: 100%;
  }

  section#container.ncdcontainer2 .fields {
    grid-template-columns: auto;
    max-width: max-content;
  }

  #container.ncdcontainer2 {
    padding: 0rem 3rem 20rem 3rem !important;
    grid-template-columns: auto;
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  html {
    font-size: 1.9vw;
  }

  #header {
    padding: 4rem 2rem;
  }

  .step-names {
    gap: 4rem;
  }

  #container {
    padding: 4rem 2rem 16rem 2rem;

  }

  footer {
    padding: 1rem 2rem;
  }

  footer p span {
    font-size: 1rem;
  }

  footer .buttons {
    gap: 1rem;
  }

  footer .buttons .prev-btn {
    gap: 1rem;
    font-size: 1.8rem;
  }

  footer .buttons .next-btn {
    width: 11.5rem;
    font-size: 2rem;
  }
}



/* Demat css part */
.addHolder {
  background: none;
  width: max-content;
  font-size: 16px;
  font-weight: 500;
  color: #8d26d2;
  display: flex;
  border: none;
  align-items: center;
}

.add_holder {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #8D26D2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.addHolder img {
  max-width: 25px;
  height: auto !important;
}


.singledemat .inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  align-items: end;
  margin-top: 15px;
}

.singledemat .inputs .removeDmat {
  width: 75px;
  height: 4.8rem;
  border-radius: 32px;
  box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
  border: solid 1px #f36f21;
  background: none;
}

#container .singledemat .inputs .removeDmat img {
  width: 35px;
  height: auto;
}

.singledemat .inputs span {
  display: block;
  opacity: 0.6;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  margin-bottom: 15px;
}

a.dAccountOpen {
  background-image: linear-gradient(107deg, #21f3f3 -77%, #214cf3 96%);
  color: #ffff;
  border-radius: 5rem;
  box-sizing: border-box;
  padding: 1.1rem 0;
  text-align: center;
  transition: all 0.5s ease;
  cursor: pointer;
  display: inline-block;
  max-width: 220px;
}

a.dAccountOpen:hover {
  color: #fff;
  opacity: 0.8;
}


@media screen and (max-width:767px) {
  .singledemat .inputs {
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }

  .singledemat .inputs .removeDmat {
    grid-column: 1/-1;
    margin: auto;
    width: 100px;
    height: 50px;
    padding: 0;
  }

  .addHolder {
    width: 100%;
    text-align: left;
  }
}

/* End dmat css part */


.holder_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  background: #FCF8FF;
  border-radius: 6px;
  padding: 5px 15px;
}

.holder_header img {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  border: 1px solid #DF514C;
  cursor: pointer;
}

.ncd_step_two_wrap h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}


.ncd_step_two_wrap input[type="text"],
.ncd_step_two_wrap input[type="number"] {
  height: 55px;
  width: 90% !important;
  background: rgba(18, 18, 18, 0.02);
  border: 1px solid #F5F7FF;
  box-sizing: border-box;
  padding: 0 1.6rem;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 30px;
  color: #0E0E0E;
  margin-top: 10px;
}

.ncd_step_two_wrap input[type="text"]:focus,
.ncd_step_two_wrap input[type="number"]:focus {
  border: 1px solid #7B23C7;
}

.single_input label {
  color: #1D1D1D !important;
  opacity: 1 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.apply_for_radio {
  display: flex;
  gap: 0.8rem;
  position: relative;
  padding: 0;
  align-items: center;
}

.apply_for_radio label {
  margin-bottom: 0;
}

.apply_for_radio input {
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  appearance: none;
  border: 2px solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply_for_radio input:checked {
  border: 2px solid #8D26D2;
}

input[type='radio']:after {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  position: relative;
  /* background-color: #d1d3d1; */
  content: '';
  display: inline-block;
  visibility: visible;
}

input[type='radio']:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  position: relative;
  background-color: #8D26D2;
  content: '';
  display: inline-block;
  visibility: visible;
}



.confirm_model_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1000;
  background: #222222bf;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.confirm_model {
  padding: 20px;
  width: 638px;
  height: 613px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  justify-content: center;
}

.pay_approve {
  height: 156px;
  text-align: center;
  background: #FFFBF4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment_check {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
}
.payment_check img {
  width: 21px;
  height: 21px;
}

.confirm_model_container {
  max-width: 381px;
  width: 100%;
  position: relative;
}

.your_appl span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */


  color: #1D1D1D;
}

.pay_approve img,
.payment_info img {
  width: 20px;
}

.payment_info {
  margin-right: 20px;
  margin-top: 5px;
}

.view_app_Btn button {
  width: 320px;
  height: 48px;
  background: linear-gradient(90deg, #7328D9 0%, #8D26D2 100%);
  border-radius: 50px;
  border: none;
  outline: none;
  color: #ffffff;
}

.confirm_Done_buttn button {
  width: 320px;
  height: 48px;
  background: #ffffff;
  border-radius: 50px;
  border: 1px solid #8728D5;
  outline: none;
  color: #8728D5;
  margin-top: 20px;
}

.category_dropdown {
  position: relative
}

.category_dropdown select {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.category_dropdown_select {
  position: absolute;
  top: 14px;
  right: 10px;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #8D26D2;
}

.category_dropdown_select select option {
  color: #111 !important;
}

.dpid_notif {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  text-transform: unset !important;
  top: -93px;
  left: -49px;
  width: 434px;
  background: #2e276f;
  border: 1px solid #F4F4F4;
  box-shadow: 0px 0px 12px rgb(0 0 0 / 4%);
  border-radius: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 15px;
  transition: 0.2s all linear;
}


.angle_icon {
  position: absolute;
  background: #2e276f;
  width: 15px;
  height: 15px;
  bottom: -5px;
  left: 50px;
  transform: rotate(45deg);
}

.lable_info_icon small:hover .dpid_notif {
  visibility: visible;
  opacity: 1;
}




@media(max-width:768px) {

  .bef_noteif {
    left: -109px !important;
  }

  .bef_noteif .angle_icon {
    left: 109px !important;
  }


  .dpid_notif {
    top: -107px;
    left: -50px;
    width: 310px;
  }

  .apply_for_radio input {
    width: 20px;
    height: 20px;
  }

  input[type='radio']:after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }

  input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }

  .ncd_step_two_wrap h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }

  .holder_header {
    background: none;
    padding: 5px 0;
  }


  .ncd_step_two_wrap {
    background: linear-gradient(90deg, #F9F8FF 33.22%, rgba(250, 249, 255, 0) 133.24%);
  }

  .ncd_step_two_wrap input[type="text"],
  .ncd_step_two_wrap input[type="number"] {
    height: 48px;
    width: 100% !important;
    background: #ffffff;
    border: 1px solid #F5F7FF;
    font-size: 12px;
  }

  .single_input label {
    font-size: 14px;
  }

  .apply_for_radio label {
    font-size: 14px;
  }

  .addHolder {
    font-size: 13px;
  }

  .step_one_btn button {
    width: 100%;
    height: 50px;
    font-size: 14px;
  }

  .invest-as select {
    display: flex;
    align-items: end;
  }

  .confirm_model_wrapper {
    /* position: unset;
    width: unset;
    height: unset;
    left: unset;
    top: unset;
    z-index: unset;
    background: unset;
    display: unset;
    justify-content: unset;
    align-items: unset; */
  }

  .confirm_model {
    width: 100%;

  }

  .confirm_model_container {
    width: 100%;

  }

  .view_app_Btn {
    /* margin-top: 100px; */
  }

  .category_dropdown {
    border: 1px solid transparent;
  }
}