.demat-account-modal {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 726px;
  background: linear-gradient(121.13deg, #FFFFFF 3.08%, #FFFFFF 95%);
  border: 1px solid #DDD9FF;
  border-radius: 20px;
  padding-bottom: 30px;
}

.demat-account-modal-close-icon {
  text-align: right;
  padding-top: 20px;
  padding-right: 20px;
}

.demat-account-modal-close-icon img {
  cursor: pointer;
}

.demat-account-modal-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.demat-account-modal-info h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.demat-account-modal-info p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #7F7F7F;
  padding: 10px 0 30px 0;
  width: 43%;
}

.demat-account-modal-stats-icon {
  width: 38px;
  height: 38px;
  left: 398px;
  top: 351px;
  background: linear-gradient(132.33deg, #5511A6 16.46%, #AA56F1 105.06%);
  border-radius: 7px;
}

.demat-account-modal-stats {
  display: flex;
  align-items: center;
}

.demat-account-modal-stats p,
.demat-account-modal-stats p span {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #7B7B7B;
}

.demat-account-modal-stats p {
  font-weight: 400;
  margin-bottom: 0;
  padding-left: 10px;
}

.demat-account-modal-stats p span {
  font-weight: 600;
}

.demat-account-modal-stats-wrap {
  padding: 30px 70px;
}

.open-acc-btn button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  color: #FFFFFF;
  padding: 10px 20px;
  outline: none;
  border: none;
  height: 47px;
  background: #F36F21;
  border-radius: 200px;
}

.open-acc-btn {
  text-align: center;
}

.open-acc-btn p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
  padding-top: 25px;
}


@media(max-width:768px) {
  .demat-account-modal {
    width: 90%;
  }

  .demat-account-modal-stats-wrap {
    padding: 30px 20px;
  }

  .demat-account-modal-info p {
    width: 100%;
  }

  .demat-account-modal-info h2 {
    font-size: 14px;
  }

  .open-acc-btn button {
    font-weight: unset;
    font-size: 12px;
  }
}