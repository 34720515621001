.login {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-backdrop-filter: blur(50px);
	backdrop-filter: blur(50px);
	background-color: #160f595e;

}

.main {
	width: 650px;
	height: 648px;
	max-width: 100%;
	max-height: 100%;
	padding: 12px;
	border-radius: 24px;
	-webkit-backdrop-filter: blur(50px);
	backdrop-filter: blur(50px);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border: solid 1px rgba(255, 255, 255, 0.34);
	background-color: rgba(255, 255, 255, 0.527);
	position: relative;
}

.mainVerify {
	width: 650px;
	height: 489px;
	max-width: 100%;
	max-height: 100%;
	padding: 12px;
	border-radius: 24px;
	-webkit-backdrop-filter: blur(50px);
	backdrop-filter: blur(50px);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border: solid 1px rgba(255, 255, 255, 0.34);
	background-color: rgba(255, 255, 255, 0.527);
	position: relative;
	@media screen and (max-width: 380px) {
		height: auto;
	}
}

.container {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(30px);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	padding: 40px 24px 24px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	@media only screen and (max-width: 767px) {
		overflow: auto;
	}

	span,
	label {
		opacity: 0.8;
		font-family: Poppins;
		font-size: 14px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}
}

.close {
	$dia: 20px;
	width: $dia;
	height: $dia;
	display: grid;
	place-content: center;
	background: none;
	outline: none;
	border: none;
	position: absolute;
	z-index: 200;
	top: 10px;
	right: 10px;

	img {
		height: $dia;
	}
}

.pattern {
	width: 236.7px;
	height: 200px;
	object-fit: contain;
	position: absolute;
	right: 18px;
	bottom: 24px;
	@media only screen and (max-width: 767px) {
		display: none;
	}
}

.head {
	h3 {
		font-family: Poppins;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
		margin-bottom: 12px;
	}
}

.body {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;

	.input {
		width: 100%;
		display: flex;
		flex-direction: column;

		input {
			width: 320px;
			height: 48px;
			border-radius: 8px;
			background-color: #30303015;
			border: none;
			outline: none;
			padding: 12px 16px;
			margin-top: 9px;
			@media only screen and (max-width: 480px) {
				width: 100%;
			}

			&::placeholder {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 16px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.5;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}
		}

		a {
			margin-left: 8px;
		}
	}
}

.margin {
	margin-bottom: 80px;
}

.foot {
	@media screen and (max-width:767px) {
		margin-top: 20px;
	}
	button {
		width: 217px;
		height: 64px;
		max-width: 100%;
		padding: 0 16px 0 24px;
		border-radius: 12px;
		box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
		border: solid 1px #f36f21;
		background-image: linear-gradient(113deg, #ff9556 8%, #f36f21 83%);
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 24px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.24px;
			text-align: right;
			color: #fff;
		}

		img {
			width: 32px;
			height: 32px;
			object-fit: contain;
		}
	}
}

.verifyContainer {
	display: grid !important;
	grid-template-columns: 60px 60px 60px 60px 60px 60px;
	flex-wrap: wrap;
	gap: 0.5em;
	@media screen and (max-width: 380px) {
		grid-template-columns: auto auto auto auto auto auto;
	}
}
.verifyCharacter {
	background: #fff;
	color: #000;
	font-size: 1.8rem;
	height: 60px;
	padding: 0.2em 0;
	border-radius: 0.1em;
	border-radius: 8px;
	background-color: #30303013;
}
.verifyCharacterInactive {
	background-color: #30303013;
}
.verifyCharacterSelected {
	background-color: #30303013;
	border: 1px solid black;
}
