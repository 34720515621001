.top_section {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.top_content h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 28px !important;
    line-height: 42px;
    color: #341f73;
}

.top_content p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7860bf;
}

.whatIsGsec h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    padding-top: 60px;
    padding-bottom: 10px;
}

.whatIsGsec ul li {
    list-style: disc;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7f7f7f;
    padding-bottom: 20px;
    margin-left: 25px;
}

.why-gsec h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    padding-top: 30px;
}

.why-gsec p{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    padding-bottom: 20px;
}

.why_gsec_box {
    background: #f8f8ff;
    padding: 50px;
}

.why_gsec_box ul li {
    list-style: disc;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7f7f7f;
    padding-bottom: 10px;
    margin-left: 25px;
}

.keyandadv{
    padding-top: 60px;
    position: relative;
    overflow: hidden;
}

.keyandadv ul{
    margin-bottom: 0;
}
.keyandadv ul li {
    padding-bottom: 10px;
}

.keyandadv p{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
}

.why_gsec_card {
    display: flex;
    align-items: flex-start;
}

.why_gsec_card img {
    margin-right: 30px;
}

.why_gsec_card h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #1d1d1d;
}

.why_gsec_card p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #7f7f7f;
}

/* TYPE OF GSEC START */

.type_gsec {
    /* padding: 200px 0; */
}

.type_gsec h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    padding-top: 60px;
    padding-bottom: 20px;
}

.type_gsec_card {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 30px 40px;
    text-align: center;
    height: 100%;
}

.type_gsec_card h3 {
    padding-top: 20px;
    padding-bottom: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1d1d1d;
}

.type_gsec_card ul li {
    list-style: disc;
    text-align: start;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #7f7f7f;
    padding-bottom: 10px;
}

/* TYPE OF GSEC END */

.gsec_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 307px;
    height: 56px;
    background: linear-gradient(90deg, #5c15bc 0%, #8523c6 100%);
    border-radius: 50px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    color: #ffffff;
    cursor: pointer;
}

.gsec_button_wrap {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

/* FAQ SECTION START */

.faq_section {
    padding: 100px 0 150px 0;
}

.faq_section h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 20px;
}

.faq_card {
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 25px 30px;
}

.faq_card_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.faq_card_title p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1D1D1D;
    margin: 0;
}

.faq_card_content {
    padding-top: 10px;
    width: 90%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #9A9A9A;
}

/* FAQ SECTION END */

/* GSEC TYPE MOBILE START */
.type_gsec_mobile h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 20px 0;
}

.type_gsec_mobile_card {
    display: flex;
    align-items: flex-start;
}

.type_gsec_mobile_card img {
    margin-right: 20px;
}

.type_gsec_mobile_card h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1d1d1d;
}

.type_gsec_mobile_card ul li {
    list-style: disc;
    text-align: start;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7f7f7f;
    padding-bottom: 10px;
}

/* GSEC TYPE MOBILE END */

.type_gsec_mobile {
    display: none;
}

.under_gsec_wrap {
    text-align: center;
    display: none;
    justify-content: center;
    padding-top: 15px;
}

.under_gsec {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 24px;
    background: #f0e4ff;
    border-radius: 63px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #8728d5;
}

.under_gsec img {
    margin-right: 5px;
}

.gsec_howitwork .how_it_work {
    padding-top: 50px;
    padding-bottom: 0;
}

.faq_mobile {
    padding-bottom: 60px;
    display: none;
}

.gsec_read_wrapp {
    padding-left: 60px;
}

.gsec_read_haeder {
    width: 100%;
    height: 87px;
    line-height: 87px;
    background: #ffffff;
    zoom: 0.8;
    padding-left: 100px;
}

.choose-government {
    display: none;
}

@media (max-width: 1200px) {
    .type_gsec_card {
        height: 100%;
    }
}

@media (max-width: 992px) {
    .jkjhkjhk {
        padding-right: 10px !important;
    }

    .fgsdfsdf {
        padding-left: 10px !important;
    }

    .viewallfaq p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #8728d5;
        text-align: center;
        padding-top: 40px;
    }

    .faq_0 {
        height: 60px;
        background: #f0e4ff8a;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .faq_0 img {
        margin-right: 10px;
    }

    .faq_1,
    .faq_2 {
        width: 100%;
        height: 160px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-top: 20px;
    }

    .faq_1 {
        background: #e8f7f7;
        position: relative;
    }

    .faq_1 img,
    .faq_2 img {
        position: absolute;
        top: 40px;
    }

    .faq_2 {
        background: #ffe7e7;
        position: relative;
    }

    .faq_1 p,
    .faq_2 p,
    .faq_0 p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        color: #1d1d1d;
        mix-blend-mode: luminosity;
        padding-top: 10px;
    }

    .faq_1 p,
    .faq_2 p {
        padding-top: 90px;
    }

    .faq_3 {
        height: 60px;
        background: #faf6de;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .faq_3 p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        color: #1d1d1d;
        mix-blend-mode: luminosity;
        margin: 0;
        width: 150px;
        padding-top: 15px;
    }

    .faq_3 img {
        padding-top: 10px;
    }

    .faq_mobile h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        mix-blend-mode: luminosity;
        padding: 50px 0 20px 0;
    }

    .hkhkhkjhkjh {
        margin-left: -25px;
    }

    .watch_gsec {
        display: none;
    }

    .under_gsec_wrap {
        display: flex;
    }

    .top_section {
        padding-top: 80px;
        display: block;
        text-align: center;
    }

    .shield_img img {
        width: 68px;
    }

    .top_content h1 {
        font-size: 16px !important;
        line-height: 24px;
    }

    .top_content p {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
    }

    .why_gsec_box {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .gsec_howitwork .how_it_work {
        margin-top: 40px;
        padding-bottom: 0;
        background: #F8F8FF;
        padding: 15px 15px 50px 15px;
    }


    .howWorkPlay .under_gsec {
        width: 105px;
    }

    .gsec_read_wrapp {
        padding-left: unset;
    }

    .faq_section {
        display: none;
    }

    .faq_mobile {
        display: block;
    }

    .gsec_button {
        height: 48px;
        width: 231px;
    }

    .why_gsec_card img {
        margin-right: 20px;
    }

    .keyandadv{
        padding: 20px 0;
    }
    
    .keyandadv p{
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #000;
    }

    .type_gsec_mobile_card ul {
        padding-left: 16px;
    }

    .why-gsec h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding-top: 0;
    }

    .why-gsec p{
        font-size: 12px;
    }

    .choose-government {
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding-bottom: 20px;
    }

    .why_gsec_box ul li {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7f7f7f;
    }

    .type_gsec {
        display: none;
    }

    .type_gsec_mobile {
        display: block;
    }

    .whatIsGsec {
        padding-bottom: 20px;
    }

    .whatIsGsec h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding-top: 40px;
    }

    .whatIsGsec ul li,
    .why_gsec_card p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7f7f7f;
    }

    .why_gsec_card h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #1d1d1d;
    }
}

.trasp-border {
    position: absolute;
    content: "";
    height: 90%;
    border-right: 2px dashed #ddd;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
}
.treasury{
    padding-bottom: 0 !important;
}
@media(max-width:768px) {
    .trasp-border {
        display: none;
    }
    .treasury{
        padding-bottom: 20px !important;
    }
}