.gsecPagination {
  display: flex;
  align-items: center;
  gap: 20px;
}

.gsecPaginationBtn,
.gsecPaginationBtnx {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  color: #d1d1d1;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.gsecPaginationBtn.active,
.gsecPaginationBtnx.active {
  color: #313131;
}

.gsecPaginationBtn.active img {
  transform: rotate(180deg);
}

.gsecPaginationBtn img {
  width: 10px;
}


.gsecPaginationBtnx.active img {
  transform: rotate(0deg);
}

.gsecPaginationBtnx img {
  width: 10px;
  transform: rotate(180deg);
}

.paginationNum {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #313131;
  cursor: pointer;
}

.paginationNum.active {
  color: #6219bf;
  background: #f7f1ff;
}