html {
  font-size: 0.73vw;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #303030;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* Header starts here */
#sgb-ap3-header {
  padding: 4rem 7rem;
  display: flex;
  justify-content: space-between;
}

.sgb-ap3-header-left {
  display: flex;
  gap: 2.3rem;
}

.sgb-ap3-header-left img {
  width: 6.2rem;
  height: 6.2rem;
}

.sgb-ap3-header-left h1 {
  font-size: 2.4rem;
}

.sgb-ap3-header-left div .header-heading-subtext {
  font-size: 1.6rem;
  display: flex;
  gap: 1rem;
}

.sgb-ap3-header-left div .header-heading-subtext p {
  opacity: 0.4;
}

.sgb-ap3-header-left div .header-heading-subtext p:last-child {
  color: #ff0000;
  opacity: 1;
}

.sgb-ap3-header-right .steps {
  display: flex;
  width: 100%;
  font-size: 1.4rem;
}

.sgb-ap3-header-right .steps p {
  padding: 0.2rem 0.85rem;
  border-radius: 50%;
  background-image: radial-gradient(circle at 0 0, #21f3f3, #214cf3);
  color: #ffff;
  opacity: 1;
}

.sgb-ap3-header-right .steps p:first-child {
  padding: 0.2rem 1rem;
}

.sgb-ap3-header-right .steps hr {
  border: none;
  height: 0.2rem;
  width: 100%;
  margin-top: 1.1rem;
  background-image: radial-gradient(circle at 0 0, #21f3f3, #214cf3);
  opacity: 1;
}

.sgb-ap3-header-right .step-names {
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
}

.sgb-ap3-header-right .step-names p {
  font-weight: 600;
  opacity: 1;
}

.sgb-ap3-header-right .step-names p:last-child {
  opacity: 1;
}

.sgb-ap3-header-right .step-names p:nth-child(3) {
  margin-left: 3rem;
  opacity: 1;
}

.sgb-ap3-header-right .step-names p:last-child {
  margin-left: 5.5rem;
  opacity: 1;
}

#sgb-ap3-container {
  padding: 6rem 7rem 9rem 7rem;
}

#sgb-ap3-container img {
  width: 6rem;
  height: 6rem;
}

#sgb-ap3-container h1 {
  margin: 2.4rem 0 0.8rem 0;
  font-size: 2.4rem;
}

#sgb-ap3-container .subtext {
  display: flex;
  font-size: 1.6rem;
  gap: 1.6rem;
}

#sgb-ap3-container .subtext p {
  font-weight: 600;
}

#sgb-ap3-container .subtext p span {
  opacity: 0.6;
  font-weight: 500;
}

.sgb-ap3-next-steps {
  margin-top: 7.2rem;
}

.sgb-ap3-next-steps h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.sgb-ap3-next-steps ul {
  list-style: none;
}

.sgb-ap3-next-steps ul li {
  font-size: 1.4rem;
  margin-bottom: 5rem;
  opacity: 0.9;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.sgb-ap3-next-steps ul li span {
  opacity: 0.8;
  font-size: 2rem;
  background-color: rgb(0, 0, 0, 0.14);
  padding: 0.3rem 1.4rem 0.4rem 1.4rem;
  width: 4rem;
  height: 3.9rem;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #241f55;
  font-weight: 600;
}

.sgb-ap3-next-steps ul li:first-child span {
  padding: 0.3rem 1.36rem 0.4rem 1.7rem;
}

#sgb-ap3-footer {
  background-color: #241f55 !important;
  background-image: none !important;
  padding: 1rem 7rem;
  display: flex;
  justify-content: space-between;
}

#sgb-ap3-footer a {
  display: flex;
  align-items: center;
}

#sgb-ap3-footer .view {
  width: 38rem;
  padding: 1.6rem 3.6rem 1.5rem;
  font-weight: 600;
  justify-content: space-between;
  color: #241f55;
  background-color: #fff;
  font-size: 2.4rem;
  border-radius: 1.2rem;
}

@media (max-width: 769px) {
  html {
    font-size: 1.29vw;
  }

  #sgb-ap3-header {
    flex-direction: column-reverse;
    justify-content: column-reverse;
    gap: 3rem;
    padding: 4rem;
  }

  .sgb-ap3-header-left div .header-heading-subtext {
    flex-direction: column;
    gap: 0;
  }

  .sgb-ap3-header-left div .header-heading-subtext p:nth-child(2) {
    display: none;
  }

  .sgb-ap3-header-right .step-names {
    gap: 11rem;
  }

  #sgb-ap3-container {
    padding: 1rem 5rem 6rem 5rem;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  html {
    font-size: 1.9vw;
  }

  #sgb-ap3-header {
    padding: 4rem 2rem;
  }

  .sgb-ap3-header-right .step-names {
    gap: 4rem;
  }

  #sgb-ap3-container {
    padding: 1rem 2rem 6rem 2rem;
  }

  #sgb-ap3-container .subtext {
    flex-direction: column;
    margin-top: 2rem;
    gap: 0;
  }

  #sgb-ap3-container .subtext p:nth-child(2) {
    display: none;
  }

  #sgb-ap3-footer {
    padding: 1rem 2rem;
  }
}
