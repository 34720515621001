/* body {
    font-family: Arial, sans-serif;
    background: url(http://www.shukatsu-note.com/wp-content/uploads/2014/12/computer-564136_1280.jpg) no-repeat;
    background-size: cover;
    height: 100vh;
} */

.ncd_popup {
    /* border: 1px solid red; */
    /* position: relative; */
}

.ncd_popup .showpopup_box {
    /* width: 40%; */
    /* margin: 0 auto;
    background: rgba(255, 255, 255, 0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
    border: 1px solid green; */
}

.showpopup_box span {
    text-decoration: underline !important;
    color: #1448FF;
    cursor: pointer;
}


.ncd_popup .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    height: 100vh;
    overflow: auto;

}

.ncd_popup .overlay.showPopup {
    visibility: visible;
    opacity: 1;
}

.ncd_popup .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 60%;
    position: relative;
    /* transition: all 5s ease-in-out; */
}

.ncd_popup .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}

.ncd_popup .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.popup .close:hover {
    color: #DF514C;
    cursor: pointer;
}

.ncd_popup .popup .content {
    /* max-height: 30%; */
    /* overflow: auto; */
    /* height: 200vh; */
    /* border: 1px solid red; */
    padding-top: 40px;
}

.mobile_top_haeder {
    zoom: 0.8;
    margin-right: 100px;
}


@media screen and (max-width: 700px) {
    .ncd_popup .showpopup_box {
        width: 70%;
    }

    .ncd_popup .popup {
        width: 90%;
    }
}

/* ================================== */
.custom_wrapper {
    margin: 0 200px;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
}


.ncd_haeder {
    width: 100%;
    height: 87px;
    line-height: 87px;
    background: #FFFFFF;
    /* box-shadow: 0px 4px 7px #ECDDF3; */
    padding-left: 60px;
}

.ncd_top_section {
    width: 100%;
    background: #f9f8ff;
    padding-bottom: 30px;
    padding-left: 60px;
}

.ncd_rating {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #1448FF;
    padding: 0 10px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DDE7FF;
    border-radius: 15px;
    margin-right: 20px;
}

.ncd_perc h1 {
    color: #8D26D2;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px !important;
    /* line-height: 24px; */
}

.ncd_perc sub {
    font-size: 17px;
    bottom: 1px !important;
}

.ncd_perc p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #404040;
}

.ncd_categories {
    margin-right: 30px;
}

.ncd_categories h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #404040;
}

.text-title {
    font-weight: 600;
    font-size: 14px;
}

.ncd_categories h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #1D1D1D;
    position: relative;
}

.border1-des {
    border-left: 1px solid rgb(0 0 0 / 10%);
}

.ncd_series_section {
    margin-bottom: 200px;
}

.interest_payout h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 33px;
    color: #1D1D1D;
}

.ncd_categories h2 span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #1D1D1D;
}

.ncd_categories h4 {
    display: flex;
    align-items: center;

}

.ncd_categories h4 small {
    position: relative;
    cursor: pointer;
    margin-left: 7px;
}

.ncd_categories h4 small span {
    text-transform: lowercase;
}

.ncd_categories {
    /* position: relative; */
}

.tringle_pointer {
    position: absolute;
    background: #2e276f;
    width: 15px;
    height: 15px;
    bottom: -6px;
    left: 49px;
    transform: rotate(45deg);
}


.maturity_period_notif {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -105px;
    left: -50px;
    width: 434px;
    background: #2e276f;
    border: 1px solid #F4F4F4;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 15px;
    transition: 0.2s all linear;
}

.ncd_categories h4 small:hover .maturity_period_notif {
    visibility: visible;
    opacity: 1;
}

.interest_apay {
    top: -73px;
    width: 400px;
}


.ncd_detail_wraper {
    padding-left: 80px;
}

.ncd_series_option {
    padding-top: 20px;
    padding-bottom: 50px;
}


.ncd_series_option {
    display: flex;
}

.ncd_series_option p {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-right: 20px;
    width: 130px;
    height: 37px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #7C7C7C;
}

.active p {
    background: linear-gradient(90deg, #7328D9 0%, #8D26D2 100%);
    color: #fff;
}

.series_detail p {
    font-family: 'Poppins';
    font-style: normal;
    line-height: 21px;
    color: #040404;
    font-size: 15px;
}

.series_perce_detail {
    display: flex;
    gap: 5px;
    align-items: center;
}

@media(max-width:992px) {
    .series_perce_detail {
        display: block;
        gap: unset;
    }
}

.series_perce_detail .title_name {
    font-family: "Poppins";
    font-style: normal;
    margin: 10px 0 5px 0;
    font-size: 12px;
}

.series_perce_detail span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    color: #109D60;
    display: flex;
    gap: 5px;
}

.series_perce_detail sub {
    font-weight: 400;
}

.detail__name_custom strong {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* line-height: 18px; */
}

.detail__name_custom p {
    margin-bottom: 10px !important;
}



.series_amount {
    color: #25212E;
}

.enter_invest_detail {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.enter_invest_detail img {
    cursor: pointer;
    width: 20px;
}

.enter_invest_detail input {
    width: 151px !important;
    height: 35px !important;
    background: #F8F6FF !important;
    border: 1px solid #F2E2FF !important;
    border-radius: 8px !important;
    font-family: 'Poppins';
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 27px;
    color: #8D26D2 !important;
    outline: none !important;
    margin: 0 20px;
}

.ncd_series_card {
    background: #FFFFFF;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px 25px;
}



.maturity_amt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #1D1D1D;
}

.ncd_series_wrapper {
    padding-left: 60px;
}

.total_invetment_sec_wrap {
    background: linear-gradient(90deg, #7328D9 0%, #8D26D2 100%);
    border-radius: 20px 20px 0px 0px;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding-left: 60px;
}

.total_invetment_sec {
    height: 110px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.total_invetment_sec button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
    gap: 10px;
    width: 172px;
    height: 56px;
    background: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 50px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(29, 29, 29, 0.79);
}

.total_invetment_sec h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 38px !important;
    line-height: 44px;
    color: #FFFFFF;
}


.total_invetment_sec p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px;
    color: #FFFFFF;
    padding-top: 0px;
    margin-bottom: 0px;
}

.ncd_header_top h1 {
    font-size: 36px !important;
}

.ncd_header_top p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    margin: 10px 0 !important;
    color: #DF514C;
}

.ncd_logo_name {
    width: 66px;
    height: 66px;
    border-radius: 50%;
}



/* =================== */

.duration_ncd_page {
    display: grid;
    grid-template-columns: auto;
    gap: 3rem;
}

.duration_ncd_page_wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

.duration_ncd_page div label {
    display: flex;
    gap: 0.8rem;
    position: relative;
    padding: 0;
}

.duration_ncd_page div label input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    appearance: none;
    border: 0.1rem solid #333;
    z-index: 1;
    border-radius: 4px;
}

.duration_ncd_page div label input:checked {
    background: #A532F2;
    border: 0.1rem solid #A532F2;
    background-image: url('../../assets/images/NCDDetail/series_check.svg');
    background-position: center;
    background-size: 11px;
    background-repeat: no-repeat;
}

.duration_ncd_page div label p {
    font-size: 1.6rem;
}

.duration_ncd_page div label p span {
    display: block;
    color: #37dc6b;
    font-size: 1.3rem;
}

.ncd_rating_mobile {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #1F3EB7;
    padding: 20px 5px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D8E0FF;
    border-radius: 4px;
}

.see_more_detail {
    display: none;
}

.see_more_detail span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #8D26D2;
}

.ncd_product_download a {
    font-size: 20px;
}

.series_detail {
    position: relative;
}

.jjhkjh {
    display: block;
    content: "";
    position: absolute;
    border-left: 1px solid #ddd;
    left: 0;
    top: 0;
    bottom: 0;
}

.radio_label {
    display: flex;
    width: 130px;
    height: 40px;
    background: transparent;
    border-radius: 76.92px;
    padding-left: 15px;
    align-items: center;
}

.radio_label label {
    padding-left: 5px;
    font-weight: 400;
    font-size: 18px;
}

.radio_label.active {
    background: #FAF7FF;
}

.series_detail_amount_mobile h2 {
    font-size: 20px;
    font-weight: 600;
}

.series_detail_amount_mobile p {
    font-size: 15px;
}

.zoom_wrapper {
    zoom: 0.8;
}

@media(max-width:1200px) {
    .custom_wrapper {
        margin: 0 150px;
    }

    .ncd_series_wrapper {
        margin-bottom: 250px !important;
    }
}

@media(max-width:1024px) {
    .custom_wrapper {
        max-width: 1060px;
    }
}

@media(max-width:992px) {
    .custom_wrapper {
        margin: 0 100px;
    }

    .ncd_series_wrapper {
        margin-bottom: 150px !important;
    }
}

.select_series {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.ncd_series_card_col .col-5 {
    padding: 0;
    /* padding-left: 50px; */
}


@media(max-width:1300px) {
    .ncd_categories h2 span {
        font-size: 16px;
    }

    .interest_payout h2 {
        font-size: 16px;
    }
}

@media(max-width:900px) {
    .border1-des {
        border: none;
    }
}

@media(max-width:768px) {

    .ncd_series_wrapper,
    .total_invetment_sec_wrap {
        padding-left: unset;
    }

    .ncd_categories h2 span {
        font-size: 15px;
    }

    .interest_payout h2 {
        font-size: 15px;
    }
}


@media(max-width:767px) {

    .series_detail_amount_mobile h2 {
        font-size: 18px;
        font-weight: 500;
    }

    .series_detail_amount_mobile p {
        font-size: 12px;
    }

    .ncd_series_section {
        margin-bottom: 100px;
    }

    .ncd_categories h4 small span {
        font-size: 12px;
    }

    .total_invetment_sec button {
        width: 135px;
        height: 40px;
        font-size: 14px;
    }

    .total_invetment_sec h1 {
        font-size: 18px !important;
        line-height: unset;
        margin-bottom: unset;
    }

    .series_detail p {
        color: #040404;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .ncd_series_card {
        border: 1px solid #EAD0F8;
    }

    .total_invetment_sec p {
        font-size: 12px !important;
        padding-top: unset;
        margin-bottom: unset;
    }

    .maturity_period_notif {
        left: -133px;
        width: 310px;
        font-size: 9px;
        padding: 10px;
        top: -109px;
    }

    .tringle_pointer {
        left: 133px;
        bottom: -5px;
    }

    .interest_apay {
        top: -61px;
    }

    .ncd_header_top p {
        font-size: 12px;
    }

    .custom_wrapper {
        margin: 0 15px;
    }

    .ncd_header_top h1 {
        font-size: 16px !important;
    }

    .ncd_detail_wraper {
        padding-left: unset;
    }

    .ncd_categories h4 {
        font-size: 14px;
        font-weight: 400;
    }

    .ncd_categories h2 {
        font-size: 14px;
        font-weight: 500;
    }

    .ncd_categories h2 span {
        font-size: 14px;
        font-weight: 500;
    }

    .interest_payout h2 {
        font-size: 15px;
    }

    .ncd_categories h4 small {
        /* padding-left: unset; */
        /* height: 16px; */
        /* width: 16px; */
    }

    .ncd_series_option p {
        /* margin-right: unset; */
        width: unset;
        height: unset;
        padding: 0 10px;
    }

    .ncd_series_option {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .enter_invest_detail {
        padding: 5px 0 10px 0;
        margin-bottom: 0;
    }

    .series_detaissssl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .enter_invest_detail input {
        width: 80px !important;
    }

    /* =============================== */
    .mobile_sec_border {
        border-top: 1px solid #ddd;
        padding-top: 20px;
        margin-top: 20px;
    }

    .mobile_sec_border .col-7 {
        padding-left: unset !important;
    }

    .mobile_sec_border .col-4 {
        word-wrap: break-word;
        padding-left: unset;
    }

    .ncd_series_card_col .col-6 {
        padding-right: unset !important;
        padding-left: unset !important;
    }

    .ncd_top_section {
        padding: 75px 15px 15px 15px;
    }


    .see_more_detail {
        display: block;
    }

    .download_doc_mobile a small {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #1F3EB7;
    }

    .ncd_series_wrapper {
        background: #f9f8ff;
    }

    .total_invetment_sec {
        height: 72px;
    }

    .ncd_series_card {
        padding: 27px;
    }

    .detail__name_custom span,
    .detail__name_custom strong {
        font-size: 12px;
    }

    .ncd_categories {
        margin-right: unset;
    }
}

@media(max-width:480px) {
    .ncd_series_card_col .col-5 {
        padding: 0;
        padding-left: 25px;
        padding-bottom: 3px;
    }

    .ncd_series_option p {
        font-size: 12px;
    }

    .showpopup_box span {
        margin-right: 50px;
    }
}

@media (max-width: 330px) {
    .ncd_header_top p {
        font-size: 9px;
    }
}