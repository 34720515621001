.GsecWebWrap {
  // padding-left: 50px;
  .topWrapper {
    background: linear-gradient(
      181.41deg,
      #f4f2ff 33.22%,
      rgba(250, 249, 255, 0) 133.24%
    );
    padding-left: 155px;
    padding-top: 60px;
    padding-bottom: 100px;
    display: flex;
    gap: 60px;
    .topLeft {
      max-width: 100%;
    }
    .topRight {
      max-width: 660px;
      h5 {
        background: linear-gradient(90deg, #5c15bc 0%, #8523c6 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 500;
        font-size: 15px;
        line-height: 170%;
        // text-transform: capitalize;
      }
      h2 {
        font-weight: 600;
        font-size: 34px;
        line-height: 51px;
        color: #000000;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #7860bf;
      }
      .investmentDetails {
        display: flex;
        justify-content: space-between;
        margin: 40px 0;

        div {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-transform: capitalize;
            color: #808080;
          }
          h3 {
            font-weight: 700;
            font-size: 22px;
            line-height: 33px;
            color: #5c44c6;
            small {
              font-weight: 700;
              font-size: 16px;
            }
          }
        }
      }
      button {
        width: 160px;
        height: 40px;
        left: 324px;
        top: 343px;
        background: linear-gradient(90deg, #5c15bc 0%, #8523c6 100%);
        border-radius: 50px;
        font-weight: 600;
        font-size: 14px;
        line-height: 170%;
        color: #ffffff;
        border: none;
        a {
          color: #ffffff;
        }
      }
    }
  }
  .MiddleSection {
    padding-left: 155px;
    width: 100%;
    padding-right: 155px;
    display: flex;
    flex-wrap: wrap;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      width: 100%;
      margin-bottom: 20px;
    }
    .middleLeft {
      width: 70%;
      float: left;
      padding-right: 25px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;

      .singleBox {
        background: #ffffff;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        padding-left: 70px;
        position: relative;
        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #1d1d1d;
          margin-bottom: 10px;
          @media screen and (min-width: 1400px) {
            font-size: 18px;
          }
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #7f7f7f;

          @media screen and (min-width: 1400px) {
            font-size: 15px;
            line-height: 22px;
          }
        }
        img {
          max-width: 30px;
          left: 20px;
          position: absolute;
          top: 20px;
        }
      }
    }
    .middleRight {
      width: 30%;
      float: left;
      padding-left: 25px;
      position: relative;

      & > h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        position: absolute;
        bottom: 100%;
      }
      .middleShild {
        background: linear-gradient(90deg, #7328d9 0%, #8d26d2 100%);
        border-radius: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 30px;

        h2 {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #e6e6e6;
        }

        button {
          width: 89px;
          height: 26px;
          left: 120px;
          top: 287px;
          background: #ffffff;
          border-radius: 50px;
          font-weight: 600;
          font-size: 12px;
          border: none;
          margin-top: 20px;
          span {
            line-height: 18px;
            background: linear-gradient(90deg, #7328d9 0%, #8d26d2 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }
  }

  .InDemandWrap {
    padding: 0 155px;

    .InDemand {
      background: #161126;
      border-radius: 12px;
      margin-top: 70px;
      padding: 60px 140px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      .leftSection {
        h2 {
          font-weight: 700;
          font-size: 32px;
          line-height: 48px;
          letter-spacing: 0.05em;
          color: #ffffff;
        }
        .iconbadge {
          display: flex;
          align-items: center;
          gap: 20px;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;
          margin: 25px 0;
          width: 100%;
          float: left;
        }
      }
    }
  }

  .mainResults {
    padding: 0 155px;
    margin-top: 65px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;

    & > h2 {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
    }

    .filterWrap {
      flex: 1;
      background: #fafafa;
      border-radius: 12px;
      padding: 35px 20px;
      border-radius: 10px;
      .FilterSection {
        & > h2 {
          font-weight: 500;
          font-size: 20px;
          line-height: 12px;
          margin-bottom: 20px;
        }
        & > p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 12px;
          margin-bottom: 10px;
        }
        & > input {
          width: 100%;
          border: 1px solid #a6a6a6;
          background: none;
          border-radius: 40px;
        }

        .filters {
          margin-top: 60px;
          h2 {
            font-size: 20px;
            margin-bottom: 25px;
          }
          h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            margin-bottom: 20px;
          }

          .list {
            span {
              color: #7f7f7f;
              border: 1px solid #bebebe;
              margin-right: 15px;
              margin-bottom: 15px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 4px 10px;
              border-radius: 30px;
              cursor: pointer;
            }
            span.active {
              background: #7328d9;
              color: #fff;
            }
          }
        }
      }
    }
    .resultsrWrap {
      flex: 3;
    }
  }
}

.noResult_web h1 {
  color: #ff3e3e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 30px;
}

.invest_sec_card {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}

.middleRight h2 button {
  border: none;
  background: #f8f0ff;
  border-radius: 4px;
  padding: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #8728d5;
  margin-left: 15px;
}

.middleRight h2 button img {
  width: 20px;
}

.reset_gsec_filter button {
  background: #5c15bc;
  border: none;
  padding: 5px 20px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 50px;
}
