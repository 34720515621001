.otherGsecCard {
    height: 340px;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 20px;
    cursor: pointer;
    width: 300px;
}

.otherGsecCard_img {
    width: 46px;
    height: 45px;
    background: #faf6ff;
    border-radius: 6px;
    margin-bottom: 15px;
}

.otherGsecCard_img img {
    width: 46px;
    height: 45px;
    padding: 5px;
}

.otherGsecCard h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 10px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: block;
}

.otherGsecCard p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #7f7f7f;
    padding-bottom: 10px;
    margin-bottom: 0;
}

.otherGsecCard span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #d71625;
}

.gsec_info {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gsec_info h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.inductive_yield {
    padding-top: 20px;
}

.inductive_yield h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 25px !important;
    line-height: 30px;
    color: #0fad74 !important;
}

.inductive_yield p {
    display: flex;
    align-items: center;
}

.inductive_yield p img {
    width: 18px;
    margin-left: 7px;
}

/* =================================== */

.inductive_yield small {
    position: relative;
    cursor: pointer;
}

.inductive_yield .tringle_pointer {
    position: absolute;
    background: #2e276f;
    width: 15px;
    height: 15px;
    bottom: -6px;
    left: 58px;
    transform: rotate(45deg);
}

.inductive_yield .maturity_period_notif {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -105px;
    left: -50px;
    width: 434px;
    background: #2e276f;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    padding: 15px;
    transition: 0.2s all linear;
}

.inductive_yield small:hover .maturity_period_notif {
    visibility: visible;
    opacity: 1;
}

.inductive_yield .interest_apay {
    top: -124px;
    width: 345px;
}


@media(max-width:768px) {

    .gsec_info_icon img,
    .gsec_info_icon .maturity_period_notif {
        display: none;
    }
}