.content_wrapper {
    padding-top: 50px;
    /* padding-bottom: 100px; */
}

.sche_name {
    display: flex;
}

.sche_name_image {
    border-radius: 50%;
    width: 91px;
    height: 91px;
    line-height: 91px;
    background: #faf6ff;
    text-align: center;
    margin-right: 20px;
}

.sche_name img {
    width: 50px;
    height: 50px;
}

.sche_name h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 32px !important;
    line-height: 48px;
    text-transform: uppercase;
    color: #000000;
}

.sche_name p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #9d9d9d;
}

.sche_name p span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #ff4a4a;
    padding-left: 5px;
}

.sche_name p span small {
    color: #000000;
    font-weight: 600;
}

.top_induc_yield {
    text-align: right;
}

.top_induc_yield p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #1d1d1d;
    padding-bottom: 10px;
}

.top_induc_yield h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32.7519px !important;
    line-height: 29px;
    text-align: right;
    color: #009c63;
}

/* ================================== */

.gsec_card_details {
    margin: 50px 0;
}

.gsec_detail_one,
.gsec_card_detail_two {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 40px;
}

.gsec_detail_one p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #9d9d9d;
}

.gsec_detail_one h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1d1d1d;
}

.gsec_card_detail_two h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 20px;
}


.gsec_card_detail_two .acc_interest {
    margin-bottom: 15px;
    display: flex;
}

.gsec_card_detail_two .acc_interest p,
.gsec_card_detail_two .acc_interest span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #1D1D1D;
    opacity: 0.8;
}

.gsec_card_detail_two .acc_interest p {
    padding-left: 5px;
}

/* ===================================== */

.invset_now_card {
    background: #3f3890;
    border-radius: 16px;
    padding: 40px;
}

.invset_qty {
    text-align: center;
}

.invset_qty h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: #ffffff;
}

.invset_qty span,
.input_qty span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #c9c5f4;
}

.input_qty {
    text-align: center;
    padding: 40px 0;
}

.input_qty img {
    margin: 0 20px;
    cursor: pointer;
}

.input_qty input {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10.1635px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
    width: 189px;
    height: 44px;
}

.invt_amt {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #ffffff;
}

.invt_amt h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.invt_detls h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
}

.invt_detls span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #cdcdcd;
}

.gsec_card_inner_info {
    border-bottom: 1px dashed #ffffff5e;
    padding-bottom: 40px;
}

.invt_total {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.invt_total h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #d7d7d7;
}

.invt_total h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 48px;
    color: #ffffff;
    padding: 10px 0 20px 0;
}

.inst_now_btn {
    background: linear-gradient(144.66deg, #ff9556 3.94%, #f36f21 91.87%);
    border: 1px solid #f36f21;
    border-radius: 50px;
    width: 280px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
}

.inst_now_btn p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 0;
}

.inst_now_btn p span {
    font-weight: 400;
    font-size: 17px;
}

/* ============================================ */

.how_it_work {
    padding-top: 100px;
    padding-bottom: 80px;
}

.howit_work_steps {
    display: flex;
    align-items: flex-start;
}

.how_it_work_wrap {
    background: #161126;
    border-radius: 16px;
    padding: 50px 80px;
}

.howit_work_steps img {
    margin-right: 20px;
    z-index: 1;
}

.howit_work_steps h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
}

.howit_work_steps span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #9a9a9a;
}

.how_it_work h1,
.explore_more h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 30px;
    color: #000000;
    padding-bottom: 25px;
}

.explore_more_head {
    padding-top: 30px;
}

/* ============================================ */

.explore_more_card {
    display: flex;
    gap: 35px;
    padding: 5px 5px 50px 5px;
    overflow: auto;
}

.explore_more_card .otherGsecCard h2 {
    font-size: 15px;
    padding-bottom: 0;
}

.explore_more_card .otherGsecCard p {
    font-size: 12px;
    padding-bottom: 5px;
}

.explore_more_card .otherGsecCard span {
    font-size: 12px;
}

.explore_more_card .gsec_info h3 {
    font-size: 14px;
}

.explore_more_card .inductive_yield {
    padding-top: 5px;
}

.explore_more_card .inductive_yield img {
    width: 14px;
}

.explore_more_card h1 {
    padding-bottom: 0;
}

.explore_more_card .otherGsecCard {
    width: 250px;
    padding: 20px 20px 10px 20px;
}

.explore_more_card .gsec_info {
    padding-top: 10px;
}

.mobile__view {
    display: none !important;
}

.howit_work_steps {
    padding-bottom: 50px;
}

.steps_last {
    padding-bottom: 0px;
}

.invest_big_card_mobile {
    display: none;
}

.gsec_detail_wrapp {
    padding-left: 60px;
}

.gsec_detail_haeder {
    width: 100%;
    height: 87px;
    line-height: 87px;
    background: #ffffff;
    zoom: 0.8;
    padding-left: 100px;
}

.invest_now_login {
    zoom: 0.8;
}

/* ==================== */

.payment_loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.payment_loader h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #1d1d1d;
    padding-top: 30px;
}

.payment_loader .spinner-border {
    color: #f36f21;
    display: inline-block;
    width: 60px;
    height: 60px;
    vertical-align: text-bottom;
    border: 7px solid currentColor;
    border-right-color: #e2e2e2;
    border-radius: 50%;
    animation: spinner-border 1s linear infinite;
}

.howWorkPlay {
    display: none;
}

.explore_more {
    padding-bottom: 150px;
}


@media (max-width: 1200px) {
    .invset_now_card {
        padding: 20px;
    }
}

@media (max-width: 992px) {
    .explore_more_head {
        padding-top: unset;
    }

    .explore_more {
        padding-bottom: 200px;
    }

    .content_wrapper {
        /* padding-bottom: 250px; */
    }

    .invest_big_card_mobile {
        display: block;
    }

    .invest_big_card {
        display: none;
    }

    .invset_now_card {
        padding: 10px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        border-radius: 24px 24px 0px 0px;
    }

    .invest_now_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ffffff5e;
    }

    .input_qty input {
        width: 75px;
        height: 40px;
    }

    .input_qty {
        text-align: center;
        padding: 0;
    }

    .invset_qty span,
    .input_qty span {
        font-size: 10px;
    }

    .invt_total {
        display: flex;
        flex-direction: unset;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 5px;
    }

    .invt_total h1 {
        padding: 0;
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 25px;
    }

    .inst_now_btn {
        margin-top: 0;
        height: 48px;
        width: fit-content;
    }

    .invt_total span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #fe9151;
    }

    .inst_now_btn p {
        font-size: 14px;
    }
}

@media (max-width: 768px) {

    .howWorkPlay {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
    }


    .how_it_work h1 {
        font-size: 16px !important;
        padding-bottom: 0;
    }

    .gsec_detail_wrapp {
        padding-left: unset;
    }

    .input_qty img {
        margin: 0 15px;
        width: 20px;
    }

    .content_wrapper {
        /* padding-top: 70px; */
    }

    .gsec_detail_wrapp {
        padding-top: 20px;
    }

    .gsec_card_detail_two h2 {
        font-size: 12px;
    }

    .gsec_card_detail_two .acc_interest,
    .gsec_card_detail_two .gain {
        font-size: 12px;
    }

    .gsec_card_detail_two .gain {
        padding: 10px 0 5px 0;
    }

    .mobile__view {
        display: block !important;
    }

    .page_wrapper {
        padding-left: 0;
    }

    .how_it_work_wrap {
        padding: 30px 20px;
    }

    .howit_work_steps h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
    }

    .howit_work_steps span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9a9a9a;
    }

    .howit_work_steps {
        padding-bottom: 60px;
    }

    .steps_last {
        padding-bottom: 0px;
    }

    .howit_work_steps {
        position: relative;
    }

    .howit_work_steps::before {
        content: "";
        position: absolute;
        border-right: 2px dashed #797979;
        height: 100%;
        bottom: 0;
        left: 17px;
    }

    .steps_last::before {
        display: none;
    }

    .sche_name h1 {
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px;
    }

    .top_induc_yield h1 {
        font-size: 24px !important;
    }

    .how_it_work {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .sche_name p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }

    .sche_name p span {
        font-size: 20px;
    }

    .desk__view {
        display: none !important;
    }

    .box__one {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .sche_name_image {
        width: 60px;
        height: 60px;
        line-height: 60px;
        margin-right: 10px;
    }

    .sche_name img {
        width: 30px;
        height: 30px;
    }

    .sche_name_image_mobile {
        width: 20%;
    }

    .sche_head_box {
        width: 80%;
    }

    .gsec_detail_one,
    .gsec_card_detail_two {
        padding: 20px;
        box-shadow: none;
    }

    .gsec_detail_one p {
        font-size: 10px;
        margin-bottom: 0;
    }

    .gsec_detail_one h2 {
        font-size: 12px;
    }

    .gsec_card_detail_two {
        background: #fcfcfc;
        border: 1px solid #f5f5f5;
        border-radius: 8px;
    }

    .payment_loader h2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1d1d1d;
    }

    .payment_loader .spinner-border {
        width: 50px;
        height: 50px;
        border-width: 5px;
    }
}