.main {
	width: 100%;
	height: 100%;
	padding-top: 64px;
	display: grid;
	grid-template-rows: 1fr auto;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;

	h2 {
		font-family: Poppins;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		margin-bottom: 16px;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	.input1 {
		display: flex;
		gap: 24px;

		div {
			display: flex;
			flex-direction: column;

			.light {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.86;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}

			select {
				width: 264px;
				height: 48px;
				padding: 0 16px;
				display: flex;
				align-items: center;
				border-radius: 8px;
				background-color: #30303015;
				font-family: Poppins;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
				border: none;
				outline: none;
			}
		}
	}

	.addHolder {
		display: flex;
		align-items: center;
		background: none;
		outline: none;
		border: none;
		width: fit-content;
		gap: 10px;
		margin-top: 48px;

		span {
			font-family: Poppins;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			color: #2e276c;
		}
	}
}

.form {
	h3 {
		margin-top: 48px;
		font-family: Poppins;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	.inputs {
		margin-top: 16px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 24px;

		div {
			display: flex;
			flex-direction: column;
			gap: 9px;

			span {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}
		}

		input {
			height: 48px;
			border: none;
			outline: none;
			padding: 0 16px;
			border-radius: 8px;
			background-color: #30303018;

			&::placeholder {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 16px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}
		}
	}
}

.buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.outline {
		width: 217px;
		height: 64px;
		display: grid;
		place-content: center;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.2px;
		text-align: right;
		color: #f36f21;

		background: none;
		outline: none;
		border: none;
	}

	.primary {
		width: 217px;
		height: 64px;
		padding: 0 16px 0 24px;
		border-radius: 12px;
		box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
		border: solid 1px #f36f21;
		background-image: linear-gradient(113deg, #ff9556 8%, #f36f21 83%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.24px;
		text-align: right;
		color: rgba(255, 255, 255, 0.603);
	}
}
