.nps_form {
  position: relative;
  padding: 20px;
  padding-left: 90px;
  background: linear-gradient(28deg, #F9E6DB 5.26%, #FFF 53.84%);
  height: 100%;

  .logo {
    display: flex;
    gap: 10px;
    align-items: end;
    padding-top: 20px;
    padding-left: 20px;
  }

  .rocket_img {
    img {
      position: absolute;
      right: 80px;
      top: 60px;
      z-index: 10;
    }
  }

  .circle_img {
    img {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 5;
    }
  }

  form {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 550px;

    .form_input_box {
      padding: 0 15px;

      h2 {
        color: #303030;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #303030;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 30px;
      }

      .form-input {
        margin-bottom: 30px;

        span {
          color: #e74c3c;
        }

        label {
          color: rgba(48, 48, 48, 0.60);
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        input {
          width: 100%;
          color: #303030;
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 5px;
          height: 55px !important;
          font-size: 16px !important;
        }
      }
    }


  }

  .form_btn {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    button {
      display: flex;
      width: 250px;
      padding: 12px 40px 10px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid #F36F21;
      background: linear-gradient(90deg, #FF9556 7.2%, #F36F21 94.49%);
      color: #FFF;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .arrer_btn {
      background: #fff;
      color: #F36F21;
      font-weight: 500;
    }
  }

  .popup_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.50);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  .popup_overlay.show {
    visibility: visible;
    opacity: 1;
  }



  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 110;
    width: 530px;
    // height: 332px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #FFF;
    padding: 30px 20px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    h2 {
      color: #303030;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 30px 0 0 0;
    }

    p {
      color: #303030;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .form_btn {
      justify-content: center;
    }
  }

  .popup.show {
    visibility: visible;
    opacity: 1;
  }
}


@media(max-width:768px) {
  .nps_form {
    padding-left: 20px;

    .logo {
      display: none;
    }

    form {
      top: 100px;

      .form_input_box {
        padding: 0 25px;
      }
    }

    .circle_img img,
    .rocket_img img {
      display: none;
    }
  }
}

@media(max-width:576px) {
  .nps_form {
    .form_btn {
      display: flex;
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      gap: 35px;

      button {
        width: 200px;
        padding: 12px 20px 10px 20px;
        font-size: 18px;
        border-radius: 8px;
      }
    }

    form {
      width: 100%;

      .form_input_box h2 {
        font-size: 20px;
        padding-bottom: 5px;
      }
    }

    .popup {
      top: unset;
      transform: translate(0, 0);
      width: 100%;
      height: 350px;
      bottom: -350px;
      right: 0;
      left: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition: all 0.2s ease-in-out;

      h2 {
        font-size: 18px;
      }
    }

    .popup.show {
      bottom: 0;
    }
  }
}