.gsecSort {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 90;
}

.sortingBox {
  position: absolute;
  top: 60px;
  width: fit-content;
  background: #ffffff !important;
  border: 1px solid #d2d2d2;
  box-shadow: -1px 4px 17px rgba(74, 70, 70, 0.25);
  border-radius: 15px;
  padding: 20px 0 10px 20px;
}

.sortingBox label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7f7f7f;
  margin-bottom: 20px;
}

.sortingBox input {
  margin-bottom: 20px;
}

.sortingBox p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15em;
  color: #000000;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.sortByTitle {
  display: flex;
  align-items: center;
}

.sortByTitle {
  background: #f4f4f4;
  border-radius: 24px;
  padding: 8px 10px;
  width: fit-content;
  cursor: pointer;
}

.sortByTitle p {
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  padding-right: 20px;
}

.sortByBox {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.sortByBox span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px;
  color: #000000;
  margin-right: 10px;
}

.mobile-sort {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  border: 1px solid #a6a6a6;
  border-radius: 18px;
  grid-gap: 8px;
  gap: 8px;
  color: #3A3387;
}

@media(max-width:768px) {
  .sortingBox {
    width: 265px;
  }

  .sortingBox label {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .sortingBox input {
    margin-bottom: 15px;
  }

  .sortingBox {
    padding: 15px 0 5px 15px;
  }

  .sortingBox p {
    font-size: 14px;
  }
}