/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"); */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}


* {
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 0.73vw;
}


.SideBar_applicationHelperTooltip__36CvH {
  background: #383182;
  color: #fff;
  min-width: 186px;
  position: absolute;
  right: 30px;
  top: 100%;
  transform: translate(0px, 20px);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #707070;
}

.SideBar_applicationHelperTooltip__36CvH::after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #383182;
  content: "";
  top: -16px;
  right: 52px;
  position: absolute;
}

.SideBar_myApplications__3O0M2 .SideBar_applicationHelperTooltip__36CvH img {
  position: absolute;
  right: 10px;
  top: 10px;
}

.SideBar_mobileMenu__2KRlo {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
}


.SideBar_loginMobileBtn__PMI2Z {
  background: #2e276f;
  color: #fff;
  border: none;
  padding: 0 5px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 100;
}

.sgb-details-price-bubble.pricebubble {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sgb-dp-container {
  margin: 0 !important;
  padding: 0 !important;
  margin-left: 3.9rem !important;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.mobile-line-breaks {
  display: none;
}

.desktop-line-breaks {
  display: inline-block;
}

h1 {
  font-size: 4rem !important;
  margin: 0;
  padding: 0;
  color: #303030;
  position: relative;
  z-index: 1;
  font-weight: 600 !important;
}

h1>span {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}

p {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  color: #303030;
  font-weight: 400;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  font-weight: 500;
}

.sgb-dp-header {
  padding: 4rem 7rem;
  display: grid;
  grid-template-columns: 5fr 1fr;
  position: relative;
  zoom: 0.8;
}

.sgb-dp-header p {
  opacity: 0.6;
}

.sgb-dp-header-left .sgb-dp-header-inner-left-top img {
  width: 8rem;
  height: 8rem;
}

.sgb-dp-header-left .sgb-dp-header-inner-left-top {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.header-left-bottom {
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  margin-left: 1rem;
  gap: 1.6rem;
}

.header-left-bottom>p {
  background-color: #0ad533;
  color: #ffff;
  padding: 0.6rem 1.2rem 0.5rem 1.6rem;
  border-radius: 0.6rem;
  opacity: 1;
}

.header-left-bottom div {
  display: flex;
  gap: 2rem;
}

.header-left-bottom div p {
  color: #303030;
  opacity: 0.8;
  font-weight: 600;
}

.header-left-bottom div p span {
  font-weight: 500;
}

.header-left-bottom div a {
  color: #2e276f;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.headerleft-bottom div a img {
  width: 2.4rem;
  height: 2.4rem;
}

.background-color {
  background-color: #f36f21;
  filter: blur(15rem);
  position: absolute;
  opacity: 0.7;
}

.header-right {
  position: relative;
}

.header-right h1 {
  font-weight: 500 !important;
}

.header-right .background-color {
  height: 10rem;
  width: 27rem;
  right: 0;
  filter: blur(19rem);
}

#SGB-details {
  padding: 0 7rem;
  position: relative;
}

#SGB-details .background-color {
  z-index: -1;
  filter: blur(31rem);
  opacity: 1;
  height: 14rem;
  width: 90%;
  top: 24rem;
}

.sgb-details-container {
  display: grid;
  gap: 1.6rem;
  grid-template-areas:
    " a b c d d"
    " e f g d d";
}

.sgb-details-card {
  width: 22.4rem;
  height: 26.8rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: 0.3rem solid #ffff;
  border-radius: 2.4rem;
  padding: 2.4rem 1rem 2.4rem 1.2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.sgb-details-card:nth-child(1) {
  grid-area: a;
}

.sgb-details-card:nth-child(2) {
  grid-area: b;
}

.sgb-details-card:nth-child(3) {
  grid-area: c;
}

.sgb-details-card:nth-child(4) {
  width: 47.7rem;
  height: auto;
  grid-area: d;

  background-image: linear-gradient(323deg,
      #853f02 -12%,
      #d08f16 35%,
      #f8e150 90%);
  padding: 2.4rem;
  border: none;
  display: block;
}

.sgb-details-card:nth-child(5) {
  grid-area: e;
}

.sgb-details-card:nth-child(6) {
  grid-area: f;
}

.sgb-details-card:nth-child(7) {
  grid-area: g;
}

.sgb-detail-card-heading {
  color: #303030;
  font-weight: 600;
  opacity: 1;
  font-size: 1.5rem;
  text-align: left;
}

.sgb-detail-card-info h3 {
  color: #303030;
  font-size: 1.6rem;
  margin: 0;
}

.sgb-detail-card-info p {
  font-size: 1.3rem;
  font-weight: 400;
}

.sgb-details-card:nth-child(1) .img img,
.sgb-details-card:nth-child(2) .img img {
  width: 19.4rem;
  height: 11.6rem;
  margin-top: 1rem;
}

.sgb-details-card:nth-child(2) .sgb-detail-card-info {
  margin-top: 1.5rem;
}

.sgb-details-card:nth-child(3) .img {
  margin-top: 1.4rem;
}

.sgb-details-card:nth-child(3) .img img {
  height: 11.7rem;
  width: 19.5rem;
  /* margin-left: -1.1rem; */
}

.sgb-details-card:nth-child(3) .sgb-detail-card-info {
  text-align: center;
  margin-top: 1.4rem;
  color: #2e276f;
}

.sgb-details-card:nth-child(5) img {
  width: 18rem;
  height: 11.6rem;
  margin-top: 2rem;
}

.sgb-details-card:nth-child(5) .sgb-detail-card-info,
.sgb-details-card:nth-child(6) .sgb-detail-card-info {
  margin-top: 1rem;
}

.sgb-details-card:nth-child(6) img {
  width: 19.3rem;
  height: 10.6rem;
  margin-top: 1rem;
}

.sgb-details-card:nth-child(7) .img img {
  width: 15.7rem;
  height: 12.9rem;
  margin: 2rem 0 2rem 1rem;
}

.sgb-details-card:nth-child(4) h2 {
  color: #ffff;
  font-size: 2.4rem;
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 600;
}

.sgb-details-card:nth-child(4) p {
  color: #ffff !important;
  opacity: 1;
}

.sgb-details-card:nth-child(4) p:nth-child(2) {
  margin-bottom: 4.8rem;
}

.sgb-details-card:nth-child(4) p:nth-child(3) {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  opacity: 0.8;
}

.sgb-details-price-bubble {
  display: grid;
  position: relative;
  width: 12rem;
  height: auto;
  background-color: #ffff;
  color: #8f4a05;
  font-size: 2rem;
  padding: 0.7rem;
  text-align: center;
  margin-bottom: 0.6rem;
  border-radius: 0.5rem;
  font-weight: 500;
}

.sgb-details-price-bubble:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 45%;
  bottom: -1.3rem;
  border: 0.7rem solid;
  border-color: #ffff transparent transparent;
}

.sgb-more-range {
  position: absolute;
  top: 18rem;
  right: 4rem;
  width: 150px;
  transform: translate(0px, 0px);
}



.sgb-details-price-bubble .sgb-more-range-input {
  width: 100% !important;
  border: none;
  outline: none;
  font-size: 2rem;
  font-weight: 500;
  color: #8f4a05;
}

/* Added more Specificity to the Input so that it doesn't get overridden */
.sgb-details-card:nth-child(4) .price-input .sgb-details-input {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.8rem;
  outline: none;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.3) !important;
  margin-top: 1rem;
  /* Changed Background to none */
  background-image: none;
}

.sgb-details-input::-webkit-slider-thumb {
  height: 4rem !important;
  width: 3rem !important;
  background: url("../../assets/images/Sgb/Group 13935.svg") no-repeat !important;
  background-position: center !important;
  background-size: 4rem;
  cursor: pointer;
  margin-top: 0.5rem;
  -webkit-appearance: none !important;
}

.sgb-details-price-labels {
  font-size: 1.4rem;
  display: flex;
  gap: 3rem;
  opacity: 0.4;
}

.sgb-details-price-labels span:first-child {
  flex: 1;
}

.quantity {
  margin-top: 7rem;
  display: flex;
  justify-content: space-between;
}

.quantity p {
  font-size: 2rem !important;
}

.quantity p:first-child {
  opacity: 0.8;
}

.quantity p:last-child {
  opacity: 1;
  font-weight: 200;
}

.invest-cta {
  margin-top: 4rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sgb-details-card:nth-child(4) .invest-cta a {
  width: 100%;
  text-align: center;
  font-size: 2.3rem;
  color: #8f4a05;
  font-weight: 600;
  padding: 1.6rem 10rem;
  border-radius: 3.2rem;
  box-shadow: 0 0.6rem 4.8rem 0 rgba(28, 28, 28, 0.08);
  background-color: #fff;
}

#goldvs {
  padding: 5rem 7rem;
  position: relative;
  zoom: 0.8;
}

.top-bg-img {
  position: absolute;
  right: 1.8rem;
  top: 7.3rem;
  width: 19.5rem;
  height: 21.2rem;
}

.down-bg-img {
  position: absolute;
  bottom: -1rem;
  left: 1rem;
  width: 19.5rem;
  height: 21.6rem;
}

.down-bg-img img:first-child {
  filter: blur(0.2rem);
  width: 19.5rem;
  height: 21.2rem;
}

.down-bg-img img:last-child {
  position: absolute;
  left: -5rem;
  z-index: -1;
  bottom: -7rem;
  width: 26.4rem;
  height: 27.4rem;
}

.top-bg-img img {
  position: absolute;
}

.top-bg-img img:first-child {
  filter: blur(0.5rem);
  top: 0;
  right: 0;
  width: 19.5rem;
  height: 21.2rem;
}

.top-bg-img img:nth-child(2) {
  top: 5rem;
  right: 5rem;
  filter: blur(0.05rem);
  width: 2.4rem;
  height: 2.4rem;
}

.top-bg-img img:nth-child(3) {
  top: 6rem;
  right: 7rem;
  filter: blur(0.05rem);
  width: 2.6rem;
  height: 2.6rem;
}

.top-bg-img img:last-child {
  top: 6.8rem;
  right: 5.5rem;
  filter: blur(0.05rem);
  height: 2.6rem;
  width: 2.6rem;
}

.sgb-dp-container #benefits {
  zoom: 0.8;
}

.benefits-container {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  gap: 2.1rem;
  margin-top: 4.3rem;
}

.benefits-container .benefits,
.benefits-container .comparisons {
  position: relative;
  height: 55.2rem;
  backdrop-filter: blur(1.1rem);
  border-radius: 2.4rem;
  box-sizing: border-box;
}

.benefits-container .benefits {
  padding: 2.4rem 2.7rem 4.7rem 2.4rem;
}

.benefits-container .comparisons {
  padding: 2.4rem 3.7rem 5.4rem 2.4rem;
}

.rectangle-1 {
  box-shadow: 0 0.3rem 1.2rem 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 100%;
  border-radius: 2.4rem;
  background-color: #c38213;
  position: absolute;
  opacity: 0.15;
  top: 0;
  left: 0;
}

.benefits-container .benefits h2,
.benefits-container .comparisons h2 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-top: 0;
}

.benefits-container .benefits ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 3.9rem 0;
}

.benefits-container .benefits ul li {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.benefits-container .benefits ul li img {
  width: 2.8rem;
  height: 2.8rem;
}

.benefits-container .benefits ul li span {
  font-size: 1.6rem;
  font-weight: 400;
  color: #303030;
}

.benefits-container .benefits hr {
  border: 0;
  border-top: 0.1rem solid #707070;
  opacity: 0.2;
}

.video-section {
  display: flex;
  padding: 4.5rem 0;
  align-items: center;
  gap: 2rem;
}

.video-section .video-info h2 {
  font-size: 1.6rem;
  color: #2e1f00;
  margin-bottom: 0.5rem;
}

.video-section .video-info p {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  opacity: 1;
}

.video-section .video-info p>img {
  opacity: 1 !important;
  width: 2.32rem;
  height: 2.32rem;
  position: relative;
}

.video-section .video-info p span {
  font-size: 1.1rem;
  color: #292929;
  opacity: 0.4;
}

.video-section .video {
  position: relative;
}

.video-section .video img:first-child {
  width: 10.1rem;
  height: 7.6rem;
}

.video-section .video img:last-child {
  position: absolute;
  top: 1.5rem;
  left: 2.5rem;
  width: 4.95rem;
  height: 4.95rem;
}

.comparisons .comparison-container {
  display: flex;
  flex-direction: column;
  gap: 5.5rem;
}

.comparison-container .comparsion-1 {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  margin-top: 3rem;
}

.comparison-container .comparsion-1 .category {
  text-align: center;
}

.comparison-container .comparsion-1:first-child .category img {
  width: 8rem !important;
  height: 8rem !important;
  position: relative;
}

.comparison-container .comparsion-1:last-child .category img {
  width: 8rem !important;
  height: 5.6rem !important;
}

.comparison-container .comparsion-1 .category p:first-child {
  color: #3d1800;
  opacity: 1;
  font-weight: 500;
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.comparison-container .comparsion-1 .category p:last-child {
  opacity: 0.5;
}

.comparison-container .comparsion-1 .category h2 {
  margin: 0;
}

.comparison-container .comparsion-1 .points {
  align-self: center;
}

.comparison-container .comparsion-1 .points ul {
  position: relative;
}

.comparison-container .comparsion-1:first-child .points ul {
  margin-top: 7rem;
}

.comparison-container .comparsion-1:last-child .points ul {
  margin-top: 3rem;
}

.comparison-container .comparsion-1 .points ul li {
  margin-left: 6rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  line-height: 1.8;
  gap: 1rem;
  align-items: center;
}

.comparison-container .comparsion-1 .points ul li img {
  width: 0.8rem;
  height: 0.6rem;
}

.comparison-container .comparsion-1 .points ul li .point-text {
  opacity: 0.6;
}

.comparison-container .comparsion-1 .points span {
  border-radius: 50%;
  background-image: linear-gradient(317deg,
      #853f02 -120%,
      #d08f16 49%,
      #f8e150 106%);
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: -0.4rem;
  z-index: 1;
}

.comparison-container .comparsion-1:last-child .points span {
  top: 3.6rem;
}

.comparison-container .comparsion-1 .points hr {
  border: none;
  border-top: 0.3rem dashed #8f4a05;
  background-color: transparent;
  width: 100%;
  margin: 1.5rem 0;
  opacity: 1;
}

.comparison-container .comparsion-1 .total-returns {
  text-align: center;
  position: relative;
}

.comparison-container .comparsion-1:first-child .total-returns {
  background: linear-gradient(350deg,
      rgba(255, 144, 0, 1) 0%,
      rgba(255, 209, 0, 1) 100%);
  padding: 4rem 1rem 1rem 1rem !important;
  width: 15rem !important;
  height: 15rem !important;
  border-radius: 10rem !important;
}

.comparison-container .comparsion-1:first-child .total-returns p {
  color: #ffff;
  opacity: 1;
  line-height: 1;
}

.comparison-container .comparsion-1:first-child .total-returns p:first-child,
.comparison-container .comparsion-1:first-child .total-returns .after {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.comparison-container .comparsion-1:first-child .total-returns p:nth-child(2) {
  font-size: 2.3rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.comparison-container .comparsion-1:first-child .total-returns>span {
  background-image: linear-gradient(294deg,
      #853f02 35%,
      #d08f16 105%,
      #f8e150 -36%);
  color: #ffff;
  font-size: 1.7rem;
  border-radius: 0.4rem;
  position: absolute;
  bottom: -0.9rem;
  left: 4.5rem;
  padding: 0.2rem 0.7rem 0.2rem 2.5rem;
}

.comparison-container .comparsion-1:first-child .total-returns span>span {
  position: absolute;
  left: 0;
}

.comparison-container .comparsion-1:first-child .total-returns span>span img {
  width: 1rem;
  height: 0.6rem;
}

.comparison-container .comparsion-1:first-child .total-returns span>span img:first-child {
  position: absolute;
  left: 0.7rem;
  top: 0.6rem;
}

.comparison-container .comparsion-1:first-child .total-returns span>span img:last-child {
  position: relative;
  left: 0.71rem;
}

.comparison-container .comparsion-1:last-child .total-returns {
  background-color: rgba(255, 255, 255, 0.58);
  border-radius: 50%;
  backdrop-filter: blur(0);
  width: 14rem;
  height: 14rem;
  padding: 4rem 1.4rem;
  border: 0.2rem solid #ffff;
}

.comparison-container .comparsion-1:last-child .total-returns p {
  color: #ce0808;
  opacity: 1;
  line-height: 1;
}

.comparison-container .comparsion-1:last-child .total-returns p:first-child {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.comparison-container .comparsion-1:last-child .total-returns p:nth-child(2) {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.comparison-container .comparsion-1:last-child .total-returns .after {
  font-size: 1.1rem;
  color: #3d1800;
  opacity: 0.6;
}

.comparison-container .comparsion-1:last-child .total-returns>span {
  padding: 0.2rem 0.6rem 0.2rem 2.5rem;
  border-radius: 0.4rem;
  background-color: #ce0808;
  color: #ffff;
  font-size: 1.4rem;
  position: absolute;
  bottom: -0.9rem;
  left: 4rem;
}

.comparison-container .comparsion-1:last-child .total-returns>span>span {
  position: absolute;
  left: 0;
}

.comparison-container .comparsion-1:last-child .total-returns>span>span img {
  width: 1rem;
  height: 0.6rem;
}

.comparison-container .comparsion-1:last-child .total-returns>span>span img:first-child {
  position: absolute;
  left: 0.5rem;
  top: 0.3rem;
}

.comparison-container .comparsion-1:last-child .total-returns>span>span img:last-child {
  position: relative;
  left: 0.5rem;
}

#benefits {
  padding: 0 7rem;
  position: relative;
}

.benefits-table-container {
  position: relative;
  padding: 3.2rem;
  backdrop-filter: blur(1rem);
}

.benefits-table-container h1 {
  color: #3d1800;
  font-size: 1.8rem;
}

.benefits-table-container table {
  font-size: 1.4rem;
  margin-top: 2.4rem;
  border-spacing: 0.8rem !important;
  overflow: scroll;
  border-collapse: separate !important;
  width: 100%;
}

.benefits-table-container table tr th {
  color: #3d1800;
  text-align: center;
}

.benefits-table-container table tr td {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.58);
  border-radius: 0.4rem;
  backdrop-filter: blur(0rem);
  box-shadow: 0 0.3rem 1.2rem 0 rgba(0, 0, 0, 0.16);
  border: solid 0.1rem #fff;
  width: 28.6rem;
  padding: 1.4rem 1rem;
  box-sizing: border-box;
  font-weight: 400;
}

.benefits-table-container table tr td:first-child {
  text-align: left;
  background-color: transparent;
  box-shadow: none;
  border: none;
  font-weight: 600;
  backdrop-filter: blur(0rem);
}

.sgb-dp-container #about {
  zoom: 0.8;
}

#about {
  padding: 0 7rem;
  position: relative;
  margin-top: 5rem;

}

#about .top-bg {
  top: 0;
  width: 40rem;
  right: 5rem;
  height: 10rem;
  z-index: -1;
  filter: blur(17rem);
}

.down-bg {
  bottom: -12rem;
  left: 0rem;
  height: 10rem;
  z-index: -1;
  filter: blur(17rem);
  width: 34rem;
}

.about {
  display: flex;
  border-radius: 2.4rem;
  padding: 3.2rem 3.3rem 3.4rem 3.2rem;
  box-shadow: 0 0.3rem 2.4rem 0 rgba(0, 0, 0, 0.12);
  border: solid 0.2rem #fff;
  background-color: #fff;
  margin-top: 2.8rem;
}

.about .info {
  flex: 2;
}

.about .video img {
  width: 20rem;
  height: 11rem;
}

.about p {
  font-size: 1.5rem;
  /* margin-bottom: 6rem; */
}

.about-cta {
  color: #2e276f;
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 2.6rem;
}

#others {
  padding: 9rem 7rem;
  position: relative;
}

#others .background-color {
  z-index: -1;
  width: 90%;
  height: 15rem;
  bottom: 15rem;
  filter: blur(57rem);
}

.others-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.2rem;
  padding-top: 5rem;
}

.other-card {
  height: 34rem;
  box-sizing: border-box;
  border-radius: 1.7rem;
  border: 0.3rem solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem 0 4.5rem 0;
  position: relative;
  width: 28.34rem;
}

.other-card .other-logo {
  position: relative;
}

.other-card .other-info {
  padding: 0 2.4rem;
}

.other-card .other-info h2 {
  font-size: 2rem;
  margin-bottom: 0.8rem;
}

.other-card .other-info p {
  font-size: 1.4rem;
}

.other-card .other-info .apply-limit {
  color: #d50a19;
  opacity: 1;
  margin-bottom: 2.4rem;
  font-weight: 500;
}

.other-card .other-info .minimum {
  color: #000;
  opacity: 0.4;
}

.other-card .other-info .rate {
  font-size: 3.2rem;
  font-weight: 600;
  color: #000;
  opacity: 1;
}

.other-card .other-info .rate span {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
  opacity: 0.8;
}

.other-card:nth-child(3) {
  background-image: linear-gradient(323deg,
      #853f02 -12%,
      #d08f16 35%,
      #f8e150 90%);
  border: none;
  color: #ffff;
}

.other-card:nth-child(3) p,
.other-card:nth-child(4) p {
  color: #ffff;
}

.other-card .other-logo img {
  width: 8.4rem;
  height: 8.4rem;
}

.other-card:nth-child(3) .other-logo img {
  width: 4.9rem;
  height: 4.9rem;
  padding: 1.5rem 0 1rem 1.5rem;
}

.other-card:nth-child(4) .other-logo img {
  width: 8.4rem;
  height: 8.4rem;
}

.other-card:nth-child(4) {
  background-image: linear-gradient(155deg, #ed9bd9 -14%, #9858ea 98%);
  border: none;
  color: #ffff;
}

.other-card:nth-child(3) .other-info h2,
.other-card:nth-child(4) .other-info h2 {
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0;
  margin-top: 1rem;
}

.other-card:nth-child(4) .other-info h2 {
  margin: 0;
  margin-bottom: 3.4rem;
}

.other-card:nth-child(3) .other-info p:nth-child(2) {
  opacity: 0.6;
  margin-bottom: 1.6rem;
}

.other-card:nth-child(3) .other-info .apply {
  margin-bottom: 2.4rem;
}

.other-card:nth-child(4) .other-info .apply {
  margin-bottom: 1.5rem;
}

.other-card:nth-child(3) .other-info .minimum,
.other-card:nth-child(4) .other-info .minimum {
  color: #ffff;
  opacity: 1;
  margin-bottom: 1.6rem;
}

.other-card:nth-child(4) .other-info .minimum {
  font-size: 2rem;
}

.other-card:nth-child(3) .other-info .minimum span,
.other-card:nth-child(4) .other-info .minimum span,
.other-card:nth-child(3) .other-info .fix-return span,
.other-card:nth-child(4) .other-info .fix-return span {
  font-size: 1.4rem;
  font-weight: 500;
  opacity: 0.6;
  display: block;
}

.other-card:nth-child(3) .other-info .fix-return,
.other-card:nth-child(4) .other-info .fix-return {
  font-size: 2rem;
  font-weight: 600;
}

.other-card:nth-child(4) .other-info .fix-return {
  font-size: 3.2rem;
}

.tax-benefit {
  padding: 0.6rem 1.2rem 0.6rem 0.8rem;
  border-radius: 3.7rem;
  background-image: linear-gradient(104deg, #fbbf00 2%, #ff421d 99%);
  position: absolute;
  font-size: 1.6rem;
  font-weight: 400;
  top: -1.5rem;
  right: 3rem;
}

.tax-benefit span {
  background-color: #ffff;
  color: #ff421d;
  padding: 0.1rem 0.6rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}

#sgb-details-footer {
  margin-top: 5rem;
  margin-left: 7.9rem;
  padding: 6.5rem 7rem 1.9rem 7rem;
  background-image: none;
  background-color: #241f55;
  display: block;
  zoom: 0.80;
}


#sgb-details-footer .footer-top {
  display: flex;
  padding-bottom: 3.9rem;
  gap: 11.2rem;
}

#sgb-details-footer .footer-top .footer-logo>img {
  margin-bottom: 1.2rem;
  width: 16.4rem;
  height: 4.8rem;
}

#sgb-details-footer hr {
  border: none;
  border-top: 0.1rem solid #a0abb8;
  opacity: 0.4;
}

#sgb-details-footer .footer-top .footer-logo {
  display: flex;
  flex-direction: column;
}

#sgb-details-footer .footer-top .footer-logo p {
  color: #b3bac3;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  opacity: 1 !important;
}

#sgb-details-footer .footer-top .footer-logo p>img {
  width: 12.4rem;
  height: 2rem;
}

#sgb-details-footer .footer-top .footer-info p {
  color: #a0abb8;
  font-size: 1.3rem;
  opacity: 0.4;
}

#sgb-details-footer .footer-top .footer-links ul {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.4rem 8rem;
  font-size: 1.4rem;
}

#sgb-details-footer .footer-top .footer-links ul li a {
  color: #a0abb8;
}

#sgb-details-footer .footer-down p {
  color: #a0abb8;
  margin-top: 1.9rem;
}

@media(max-width:768px) {
  .sgb-details-price-bubble .sgb-more-range-input {
    padding: 0 !important;
  }
}

@media (min-width: 481px) and (max-width: 769px) {


  html {
    font-size: 1.29vw;
  }

  #sgb-details-sidebar {
    width: 100% !important;
  }

  .sgb-dp-header {
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;
    zoom: unset;
  }

  .sgb-dp-container {
    margin-top: 10rem !important;
    margin-left: 0 !important;
  }

  .header-left-bottom {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -3rem;
  }

  .header-left-bottom>p {
    position: absolute;
    top: 13rem;
    left: 12.5rem;
  }

  .header-left-bottom div {
    width: 100%;
    justify-content: space-between;
  }

  .header-left-bottom div>p {
    display: flex;
    flex-direction: column;
  }

  .header-left-bottom div p:nth-child(2) {
    display: none;
  }

  #about,
  #SGB-details,
  #benefits {
    padding: 0 2rem;
    zoom: unset;
  }

  #goldvs {
    padding: 5rem 2rem;
    zoom: unset;
  }

  #others {
    padding: 9rem 0;
  }

  #SGB-details .background-color {
    filter: blur(73rem);
    height: 50rem;
    top: unset;
    bottom: 10rem !important;
  }

  .sgb-details-container {
    grid-template-areas:
      "d d"
      "d d"
      "a b"
      "c e"
      "f g";
  }

  .sgb-details-card {
    width: 100%;
    zoom: 0 !important;
  }

  .sgb-details-card .img {
    text-align: center;
  }

  .sgb-detail-card-heading {
    text-align: center;
  }

  .sgb-details-card:nth-child(4) {
    width: 100%;
  }

  .sgb-details-card:nth-child(3) .img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .comparison-container .comparsion-1:first-child .total-returns p {
    line-height: 1;
  }

  .sgb-details-card:nth-child(6) .sgb-detail-card-info {
    margin-top: 3rem;
  }

  .top-bg-img,
  .down-bg-img {
    display: none;
  }

  .benefits-container {
    grid-template-columns: 1fr;
  }

  .benefits-table-container {
    padding: 3rem 1rem;
  }

  .benefits-table-container h1 {
    padding-left: 1rem;
  }

  .about {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .about .video img {
    width: 100%;
    height: 100%;
  }

  .about p {
    font-size: 1.6rem;
  }

  .others-container {
    overflow: scroll;
    padding: 0 2rem;
    padding-top: 5rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  #others>h1 {
    padding: 0 2rem;
  }

  #sgb-details-footer {
    margin-left: 0;
  }

  #sgb-details-footer {
    padding: 6.5rem 2rem 1.9rem 2rem;
  }

  #sgb-details-footer .footer-top {
    flex-direction: column;
    gap: 5.2rem;
  }

  #sgb-details-footer .footer-logo {
    order: 1;
    flex-direction: row;
    gap: 1rem;
  }

  #sgb-details-footer .footer-info {
    order: 3;
  }

  #sgb-details-footer .footer-links {
    order: 2;
  }

  .sgb-others-container {
    padding: 9rem 0;
  }

  .sgb-others-card-container {
    overflow-x: scroll;
    padding: 0 2rem;
    padding-top: 5rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  html {
    font-size: 1.9vw;
  }

  #sgb-details-sidebar {
    width: 100% !important;
  }

  .mobile-line-breaks {
    display: inline-block;
  }

  .desktop-line-breaks {
    display: none;
  }

  h1 {
    font-size: 3rem;
  }

  .sgb-dp-container {
    margin-top: 7rem !important;
    margin-left: 0 !important;
  }

  .sgb-dp-header {
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
    zoom: unset;
    overflow: hidden;

  }

  .header-right {
    margin-top: 15px;
  }

  .sgb-dp-header-left h1 {
    font-size: 3.5rem;
  }

  .header-left-bottom {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    margin-left: 0;
  }

  .header-left-bottom>p {
    position: absolute;
    top: 5px;
    left: 12.5rem;
  }

  .header-left-bottom div {
    width: 100%;
    justify-content: space-between;
  }

  .header-left-bottom div>p {
    display: flex;
    flex-direction: column;
    font-size: 1.9rem;
  }

  .header-left-bottom div a {
    font-size: 1.9rem;
  }

  .header-left-bottom div p:nth-child(2) {
    display: none;
  }

  .header-right .background-color {
    height: 21rem;
    width: 14rem;
    right: unset;
    top: -16rem;
    z-index: -1;
    filter: blur(14rem);
  }

  #about,
  #SGB-details,
  #benefits {
    padding: 0 2rem;
    overflow: hidden;

  }

  .sgb-details-card .quantity {
    margin-top: 5px;
  }

  .sgb-details-card .price-input {
    margin-bottom: 15px;
  }

  .sgb-details-card .invest-cta {
    margin-top: 5px;
  }

  .sgb-dp-container #SGB-details {
    padding-bottom: 15px;
  }

  .sgb-dp-container #about {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sgb-dp-container+footer {
    margin-top: 0;
  }

  #benefits {
    overflow: scroll;
    zoom: unset;
  }

  /* Hide the Table */
  .benefits-table-container {
    display: none;
    width: 126rem;
  }

  .about {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .about .video img {
    width: 100%;
    height: 100%;
  }

  .sgb-details-card {
    zoom: unset;
  }

  .sgb-details-card:nth-child(3) .sgb-detail-card-heading,
  .sgb-details-card:nth-child(5) .sgb-detail-card-heading {
    text-align: center;
  }

  .sgb-details-card:nth-child(3) .img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sgb-details-card:nth-child(3) .img img {
    margin: 0;
  }

  .sgb-details-card:nth-child(4) h2 {
    font-size: 2.7rem;
  }

  .sgb-details-card:nth-child(4) p:nth-child(2) {
    font-size: 1.8rem;
  }

  .sgb-details-card:nth-child(4) .invest-cta a {
    font-size: 2.4rem;
  }

  .price-labels {
    font-size: 1.9rem !important;
  }

  .price-bubble {
    font-size: 2.4rem;
    width: 11rem;
  }

  #goldvs {
    padding: 5rem 2rem;
    zoom: unset;
  }

  #goldvs h1 {
    font-size: 3.5rem;
  }

  #about .top-bg {
    top: -98rem;
    width: 33rem;
    right: 5rem;
    height: 72rem;
    z-index: -1;
    filter: blur(40rem);
  }

  #others {
    padding: 9rem 0;
  }

  #SGB-details .background-color {
    filter: blur(38rem);
    height: 49rem;
    top: 73rem;
  }

  .sgb-details-container {
    grid-template-areas:
      "d d"
      "d d"
      "a b"
      "c e"
      "f g";
  }

  .sgb-details-card:nth-child(4) {
    width: 100%;
  }

  .sgb-details-card:nth-child(2) .sgb-detail-card-info {
    margin-top: 0rem;
  }

  .sgb-details-card:nth-child(3) .img img {
    margin: 0;
  }

  .top-bg-img,
  .down-bg-img {
    display: none;
  }

  .benefits-container {
    grid-template-columns: 1fr;
  }

  .benefits-container .benefits {
    order: 2;
    height: 59.2rem;
  }

  .video-section .video-info h2 {
    font-size: 2rem;
  }

  .benefits-container .benefits ul li span {
    font-size: 2rem;
  }

  .benefits-container .comparisons {
    padding: 2.4rem 2.4rem 5.4rem 2.4rem;
    height: 75rem;
    order: 1;
  }

  .comparison-container .comparsion-1 {
    grid-template-areas:
      "c t"
      "p p";
    gap: 0 11rem;
    grid-template-columns: unset;
  }

  .comparison-container .comparsion-1 .category {
    grid-area: c;
    text-align: left;
  }

  .comparison-container .comparsion-1 .points {
    grid-area: p;
  }

  .comparison-container .comparsion-1:first-child .points ul {
    margin-top: 3rem;
  }

  .comparison-container .comparsion-1 .points ul li {
    margin-left: 10rem;
    font-size: 1.6rem;
  }

  .comparison-container .comparsion-1:first-child .points hr {
    width: 36%;
    position: absolute;
    top: -14rem;
    left: 12rem;
  }

  .comparison-container .comparsion-1:first-child .points span {
    top: -13rem;
    left: 11.5rem;
    z-index: 1;
  }

  .comparison-container .comparsion-1:first-child .total-returns p {
    line-height: 1;
  }

  .comparison-container .comparsion-1:last-child .points hr {
    position: absolute;
    top: -14rem;
    width: 38%;
    left: 11.3rem;
  }

  .comparison-container .comparsion-1:last-child .points span {
    top: -13rem;
    left: 11rem;
    z-index: 1;
  }

  .comparison-container .comparsion-1 .total-returns {
    grid-area: t;
  }

  .comparison-container .comparsion-1:first-child .total-returns {
    width: 10rem;
    height: 9rem;
    padding: 4rem 2rem 1rem 2rem;
    align-self: center;
  }

  .comparison-container .comparsion-1:first-child .total-returns span {
    left: 4rem;
  }

  .comparison-container .comparsion-1:first-child .total-returns p:nth-child(2) {
    font-size: 2.3rem;
  }

  .comparison-container .comparsion-1:first-child .total-returns p:first-child,
  .comparison-container .comparsion-1:first-child .total-returns .after {
    font-size: 1.1rem;
  }

  .about {
    padding: 2.2rem 2.3rem 2.4rem 2.2rem;
  }

  .about p {
    font-size: 2.15rem;
  }

  .about-cta {
    font-size: 2rem;
  }

  .down-bg {
    bottom: -23rem;
    left: 0rem;
    height: 21rem;
    z-index: -1;
    filter: blur(12rem);
    width: 11rem;
  }

  .others-container {
    overflow: scroll;
    padding: 0 2rem;
    padding-top: 5rem;
  }

  #others .background-color {
    height: 25rem;
    bottom: 0rem;
    filter: blur(31rem);
  }

  ::-webkit-scrollbar {
    display: none;
  }

  #others>h1 {
    padding: 0 2rem;
  }

  #sgb-details-footer {
    margin-left: 0;
    padding: 6.5rem 2rem 1.9rem 2rem;
    zoom: 0.9;
  }

  #sgb-details-footer .footer-top {
    flex-direction: column;
    gap: 5.2rem;
  }

  #sgb-details-footer .footer-top .footer-logo {
    order: 1;
    flex-direction: row;
    gap: 1rem;
  }

  #sgb-details-footer .footer-info {
    order: 3;
  }

  #sgb-details-footer .footer-links {
    order: 2;
  }
}