.main {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  zoom: 0.8;
}

.child {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  flex-direction: column;
  overflow-x: hidden;
}

.Rectangle4997 {
  width: 421px;
  height: 262px;
  margin: 0 548px 110px 4px;
  padding: 40px 78.9px 18px 19px;
  opacity: 0.05;
  border-radius: 255px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
}

.Rectangle4999 {
  width: 271px;
  height: 188px;
  margin: 0 0 184px 175px;
  padding: 40px 70px 87px 56px;
  opacity: 0.05;
  border-radius: 255px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.Rectangle5109 {
  width: 531px;
  height: 632px;
  top: 377px;
  left: 0;
  margin: 0 130px 1px 0;
  padding: 343px 484px 265px 23px;
  opacity: 0.04;
  border-radius: 422px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
}

.Rectangle5108 {
  width: 908px;
  height: 100%;
  margin: 162px 0 6px 458px;
  opacity: 0.08;
  border-radius: 255px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 539px;
}

.Rectangle51081 {
  width: 1328px;
  height: 100%;
  margin: 162px 0 6px 0;
  opacity: 0.08;
  border-radius: 255px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
  left: -350px;
  top: 539px;
}

.Rectangle5076 {
  width: 617px;
  height: 716px;
  margin: 6px 44px 27px 0;
  padding: 88px 177px 261px 102px;
  opacity: 0.04;
  border-radius: 422px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 1547px;
}

.Rectangle4929 {
  width: 906px;
  height: 1002px;
  margin: 149px 0 159px 460px;
  opacity: 0.08;
  border-radius: 255px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 1378px;
}

.Rectangle4950 {
  width: 1366px;
  //height: 1820px;
  margin: 39px 0 115px;
  padding: 60px 0 352.8px 70px;
  opacity: 0.07;
  border-radius: 606px;
  -webkit-filter: blur(42px);
  filter: blur(42px);
  background-color: #f36f21;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 2539px;
}
