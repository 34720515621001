/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'); */


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/Lato-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Lato-Bold.ttf') format('truetype');
}


/* ============== */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}


@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}
/* =================== */

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Rupee';
	font-style: normal;
	font-weight: 600;
	src: local('Rupee'), local('Rupee'), url(./assets/fonts/Rupee_Foradian.ttf) format('opentype');
}

.custom_container {
	margin: 0 auto;
	max-width: 1280px;
	padding: 0 15px;
}


input[type=number]::-webkit-inner-spin-button {
	display: none;
}

.rupee {
	font-family: 'Rupee';
}

.small {
	font-size: 80% !important;
}

html {
	height: 100%;
}

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-family: 'Lato', sans-serif !important;
	font-weight: 400;
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 15px;
	line-height: 1.5;
	letter-spacing: .5px;
	position: relative;
	background: #fff;
	width: 100%;
	height: 100%;
	display: flex;
	min-width: 320px;
}

#root {
	position: relative;
	width: 100%;
	height: 100%;
}

a,
a:hover {
	text-decoration: none !important;
	color: #633ee3;
}

header {
	background: #231965;
}

header .header_nav {
	color: #fff;
}

.section {
	padding: 1.75rem 0;
}

.section .main_title {
	font-size: 1.5rem;
	color: #453e72;
	font-weight: 700;
	margin: 0;
}

.iifl_logo img {
	width: 250px;
}

.header_top_with_banner {
	background: #f5f5f5;
}

.header_nav {
	font-size: 1rem;
	color: #212121;
}

.mrgR3 {
	margin-right: 3px !important;
}

.login,
.login:hover {
	color: #f37921;
	padding-left: 5px;
}

.banner_content h2 {
	font-size: 2.75rem;
	font-weight: 700;
	color: #003f6b;
}

.banner_overlap_band {
	font-size: 1.1rem;
	color: #fff;
	background: #3d4481;
	border-radius: 0px 15px 0px 15px;
	margin-top: -30px;
}

.card_box {
	border: 1px solid #e0e0e0;
	border-radius: 20px;
	padding: 1.5rem;
	position: relative;
	box-shadow: 0px 3px 6px #8686861A;
	background: #fff;
}

.ipo_box_main {
	display: table;
	width: 100%;
}

.ipo_box_main_left {
	display: table-cell;
	width: 60px;
	vertical-align: top;
}

.ipo_box_main_right {
	display: table-cell;
	padding-left: 1rem;
	vertical-align: middle;
}

.ipo_box_main_right h2 {
	font-size: 1.05rem;
	color: #363636;
	font-weight: 700;
}

.ipo_box_main_left span {
	font-size: 12px;
	color: #8f8f90;
}

.innner_grid {
	display: table;
	width: 100%;
	margin: 0;
	padding: 0;
}

.innner_grid li {
	display: table-cell;
	text-align: left;
	padding-right: 1rem;
	color: #8f8f90;
	font-size: 0.8rem;
}

.innner_grid li span {
	color: #212121;
	font-size: 0.9rem;
	font-weight: 700;
}

.innner_grid li:last-child {
	padding-right: 0px;
}

.title_main {
	display: table;
	width: 100%;
}

.title_main h2 {
	display: table-cell;
	width: 90%;
	vertical-align: middle;
}

.title_main span {
	display: table-cell;
	vertical-align: middle;
}

.recommended {
	position: absolute;
	top: -1px;
	right: 0;
	background: #33b46e;
	color: #fff;
	font-size: 11px;
	padding: 0.3rem 2rem;
	border-radius: 0 20px;
}

.recommended.miss_opportunity {
	position: absolute;
	top: -1px;
	right: 0;
	background: #b43333;
	color: #fff;
	font-size: 11px;
	padding: 0.3rem 2rem;
	border-radius: 0 20px;
}

#PastPerformers {
	background: #f2f4f4;
}

.upcoming_opportunities_list li {
	width: 50% !important;
	display: inline-block;
	margin-bottom: 1rem;
}

.video_box {
	position: relative;
	border-radius: 20px;
}

.video_box:before {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #0000009e;
	top: 0;
	content: "";
	display: block;
	border-radius: 20px;
}

.play_btn {
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0 auto;
	left: 45%;
}

#FAQ .nav {
	align-items: center;
	justify-content: center;
}

#FAQ .nav-tabs {
	border: 0px;
}

#FAQ .nav-tabs .nav-item.show .nav-link,
#FAQ .nav-tabs .nav-link.active,
#FAQ .nav-tabs .nav-link.active:hover {
	border: 0px;
	border-bottom: 2px solid #f37921 !important;
	color: #f37921;
	font-weight: 700;
}

#FAQ .nav-tabs .nav-link {
	color: #8f8f90;
	font-size: 1rem;
	padding: 0.5rem 2rem;
}

#FAQ .nav-tabs .nav-link {
	border: 0px;
}

#FAQ .nav-tabs .nav-link:hover {
	border: 0px !important;
}

#FAQ .tab-content {
	background: #f8f9f9;
	border-radius: 15px;
}

#accordion .card-header h5 span:after {
	content: url(./assets/images/caret-up.png);
	position: absolute;
	right: 0;
	top: 11px;
}

#accordion .card-header h5 span.collapsed:after {
	content: url(./assets/images/caret-down.png);
}

#Faq .card-body {
	padding: 1.25rem 0 0 0;
}

#Faq .card-header {
	background: transparent;
}

#Faq .card-header span {
	padding: 0.75rem 2rem 0.75rem 0;
	font-size: 1rem;
	position: relative;
}

#Faq p {
	font-size: 1rem;
}

.big_card .title_main h2 {
	width: 75%;
}

.title_main h2 a {
	font-size: 13px !important;
	color: #633ee3;
	padding-top: 8px;
	font-weight: 700;
}

.big_card .ipo_box_main_right h2 {
	font-size: 1.25rem;
	display: block;
}

.big_card .innner_grid li {
	font-size: 1.1rem;
}

.big_card .innner_grid li span {
	font-size: 1.1rem;
}

.big_card .innner_grid li {
	width: 25%;
}

.score_card_main {
	background: #f5f8f9;
	border-radius: 20px;
	width: 75%;
	padding: 1.5rem;
	float: right;
}

.analyst_view_main_box h2 {
	font-size: 1.3rem;
	color: #1e1c57;
	margin: 0;
	font-weight: 700;
}

.analyst_view_main_box {
	max-width: 340px;
	width: 100%;
	float: right;
}

.analyst_view td {
	text-align: left;
	color: #828f93;
	font-size: 1rem;
	border-bottom: 1px solid #e6ebed;
	padding: 0.5rem 0;
}

.analyst_view td:last-child {
	color: #535d60;
	font-weight: 700;
}

.score_avrage {
	font-size: 1.1rem;
	color: #ff2c29;
	font-weight: 700;
}

.analyst_view_main_box p {
	color: #828f93;
}

.score_meter {
	background: #fff;
	border: 1px solid #d8dddd;
	border-radius: 20px;
	width: 45%;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

#ScoreMeter {
	position: relative;
}

.section h3 {
	font-size: 1.5rem;
	color: #453e72;
	font-weight: 700;
}

.section p {
	font-size: 1rem;
	color: #717171;
}

.video_outer:after {
	content: "";
	display: block;
	background: #f37921;
	position: absolute;
	width: 100%;
	height: 100%;
	top: -10px;
	left: -10px;
	border-radius: 20px 20px 0 0;
	z-index: -1;
}

#StrengthWeakness {
	border: 1px solid #ffc9a2;
	border-radius: 20px;
	-moz-border-radius: 20px;
	border-collapse: separate;
	border-spacing: 0;
}

#StrengthWeakness th {
	background: #fff8f4;
	padding: 0.75rem;
	font-size: 1rem;
	text-align: center;
	color: #96440d;
	font-weight: 700;
}

#StrengthWeakness td {
	padding: 0.75rem;
	font-size: 14px;
	color: #746860;
	border-bottom: 1px solid #f3e6de;
	margin: 0.5rem;
}

#StrengthWeakness td:first-child {
	border-right: 1px solid #f3e6de;
}

#StrengthWeakness th:first-child {
	border-radius: 20px 0 0 0;
}

#StrengthWeakness th:last-child {
	border-radius: 0px 20px 0 0;
}

.accordion_main_box {
	background: #f8f9f9;
	border-radius: 15px;
	padding: 1rem;
}

.terms_main_tbl {
	max-width: 100%;
	position: relative;
}

.terms_main_tbl table {
	position: relative;
	border-collapse: collapse;
	width: 100%;
}

.terms_main_tbl table td,
th {
	font-size: 13px;
	padding: 0.75rem 1rem !important;
	font-weight: 400;
}

.terms_main_tbl table td {
	font-size: 13px;
	color: #535d60;
	font-weight: 400;
	text-align: center;
	border-bottom: 1px solid #d8dddd;
	line-height: 18px;
}

.terms_main_tbl table th {
	text-align: center;
}

.terms_main_tbl table th:nth-child(1) {
	/*background: #fff;*/
	text-align: left;
	color: #535d60;
}

.terms_main_tbl table thead th {
	background: #f5f8f9 !important;
	color: #828f93;
	border-bottom: 1px solid#D8DDDD;
}

.terms_main_tbl table thead th:first-child {
	left: 0;
	z-index: 1;
}

.terms_main_tbl table tbody th {
	position: -webkit-sticky;
	/* for Safari */
	position: sticky;
	left: 0;
	/*background: #FFF*/
	border-bottom: 1px solid #d8dddd;
}

.bg_gray {
	background: #f5f8f9 !important;
	color: #828f93;
}

.terms_flow_table {
	border: 1px solid #d8dddd;
	border-radius: 20px;
	-moz-border-radius: 20px;
	border-collapse: separate !important;
	border-spacing: 0;
}

.terms_flow_table .top_left_radius_25 {
	border-radius: 20px 0 0 0;
}

.terms_flow_table th:last-child {
	border-radius: 0px 20px 0 0;
}

.benefits_grid img {
	width: 70px;
	margin: 0 auto;
}

.benefits_grid span {
	color: #717171;
	font-size: 1rem;
}

.ipo_popup .modal-content {
	border-radius: 20px;
}

/*modal popup*/
.ipo_popup .modal .close {
	color: #000;
	opacity: 1;
	background: #ffffff;
	vertical-align: top;
	line-height: 15px !important;
	border-radius: 50%;
	right: 10px;
	top: 10px;
	font-size: 20px;
	font-weight: normal;
	z-index: 1099;
}

.ipo_popup .modal-content {
	border: 0px;
	border-radius: 20px !important;
}

.ipo_popup .modal-header {
	border: 0px;
	padding-bottom: 0;
	font-size: 1.4em;
	text-align: left;
	font-weight: 400;
	line-height: 1.2 !important;
}

.ipo_popup h2 {
	color: #212121;
	margin-bottom: 0px;
	font-size: 1.2rem;
	font-weight: 700;
}

.ipo_popup h3 {
	color: #212121;
	font-size: 1.5rem;
	font-weight: 700;
}

.ipo_popup h5 {
	color: #969696;
	font-size: 1.1rem;
}

.ipo_popup p {
	margin-bottom: 1em;
	font-size: 13px;
	color: #787878;
}

.ipo_popup .modal-title {
	line-height: 1.2 !important;
}

.ipo_popup .btn-green {
	border: 0;
	font-size: 1em;
}

.ipo_popup .nav-tabs {
	border-bottom: 0px;
}

.ipo_popup .nav-tabs>li.active>a,
.ipo_popup .nav-tabs>li.active>a:focus,
.ipo_popup .nav-tabs>li.active>a:hover {
	border: 0;
}

.ipo_popup .nav>li>a {
	padding: 0 1em 0 0;
}

.ipo_popup .tab-content {
	padding: 1em 0 0 0;
}

.ipo_popup .modal-body {
	padding: 15px;
}

/*modal popup*/
.dvtimer {
	color: #007bff;
	font: 600 13px/16px 'Nunito', sans-serif;
	transition: 0.4s;
	text-align: center;
	display: block;
}

.dvtimer .OTPtime {
	color: #f37021;
	font-weight: bold;
}

.link-btn {
	border: 0;
	outline: none;
	background: transparent;
	padding: 0;
	color: #007bff;
	font: 600 13px/16px 'Nunito', sans-serif;
	transition: 0.4s;
	cursor: pointer;
	text-decoration: underline;
	display: block;
	text-align: center;
	width: 100%;
}

.form_otp_main .modal-content {
	margin: 0 auto;
}

.form_otp_main h2 {
	color: #212121;
	margin-bottom: 0px;
	font-size: 1.2rem;
	font-weight: 700;
}

.form_otp_main h2:after {
	content: "";
	display: block;
	width: 80px;
	height: 1px;
	margin: 8px auto auto;
	text-align: center;
	background: #f37921;
}

.info {
	color: #717171;
	font-size: 13px;
}

#mobileChange {
	color: #633ee3;
}

.dvcart label {
	display: block;
	margin-bottom: 0.35em;
	font-size: 0.9em;
	color: #8f8f90;
	font-weight: 400;
}

.dvcart input {
	display: block;
	width: 100%;
	padding: .375em .75em;
	font-size: 1rem;
	line-height: 1.5;
	color: #212121;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .5em;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.card_box h2 {
	font-size: 1.05rem;
	color: #363636;
	font-weight: 700;
}

.bid_prize {
	padding: 0.5rem 1rem;
	color: #659d7c;
	background: #eafdf2;
	border-radius: 20px;
	font-size: 14px;
}

#OtherCategory .green_btn {
	padding: 0.4rem 1rem;
	color: #659d7c !important;
	background: #eafdf2;
	border-radius: 20px;
	font-size: 12px !important;
}

.number_count {
	position: relative;
}

.number_count .minus,
.number_count .plus {
	border-radius: 4px;
	padding: 0.5rem 1rem;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-size: 1.5rem;
	color: #868686;
	cursor: pointer;
	line-height: 20px;
	font-weight: bold;
}

.number_count .minus {
	position: absolute;
	left: 0;
}

.number_count .plus {
	position: absolute;
	right: 0;
	top: 0;
}

#StepBox {
	background: #fafafa;
	border-radius: 20px;
}

.step_main {
	display: table;
	margin-bottom: 30px;
	border-bottom: 1px solid #e6ebed;
	padding-bottom: 0.5rem;
	width: 100%;
}

.step_left {
	display: table-cell;
	width: 90px;
	vertical-align: top;
	padding-right: 10px;
}

.step_left img {
	width: 100%;
	height: 55px;
}

.step_right {
	display: table-cell;
	vertical-align: top;
}

.step_right h2 {
	font-size: 1.1rem;
	font-weight: 700;
	color: #453e72;
	padding: 0;
	margin: 0;
}

.step_right p {
	font-size: 14px;
	font-weight: 400;
	color: #636565;
	padding: 0;
	margin: 0;
}

#StepBox h3 {
	color: #212121;
	margin-bottom: 0px;
	font-size: 1.2rem;
	font-weight: 700;
	text-transform: capitalize;
}

#StepBox h3:after {
	content: "";
	display: block;
	width: 80px;
	height: 1px;
	margin: 8px auto auto;
	text-align: center;
	background: #f37921;
}

.inside_gray_box {
	background: #f2f4f4;
	border-radius: 20px;
}

.wrapperslct {
	overflow: hidden;
	position: relative;
}

.wrapperslct:after {
	content: "";
	font-size: 0.8rem;
	pointer-events: none;
	position: absolute;
	right: 1.5rem;
	top: 35%;
	z-index: 1;
	display: block;
	margin: 0px auto;
	width: 10px;
	height: 10px;
	border-top: 2px solid #495057;
	border-left: 2px solid #495057;
	transform: rotate(225deg);
}

select {
	/* remove default caret */
	-webkit-appearance: none;
	/* webkit browsers */
	-moz-appearance: none;
	/* firefox */
	appearance: none;
	/* modern browsers */
	width: 100%;
}

select::-ms-expand {
	display: none;
	/* remove default caret for ie */
}

select {
	display: block;
	width: 100%;
	padding: .475em .75em;
	font-size: 1em;
	line-height: 1.5;
	color: #495057;
	/* background-color: #fff; */
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .5em;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/*.dvcart label sup { color: red; font-size: 15px; }*/
.cs_name {
	color: #212121 !important;
	font-weight: 700;
}

.radioBtn input {
	display: none;
}

.radioBtn .btn {
	height: 40px;
	border-radius: 0 !important;
	margin: 0;
	width: 50%;
	font-weight: bold;
	border-top-left-radius: 0.25em !important;
	border-bottom-left-radius: 0.25em !important;
	line-height: 24px;
}

.radioBtn .btn+.btn {
	margin: 0;
	border-radius: 0 !important;
	border-top-right-radius: 0.25em !important;
	border-bottom-right-radius: 0.25em !important;
}

.radioBtn .btn.active {
	background: #403654;
	border-color: #403654;
	color: #fff;
}

.radioBtn .btn {
	border: 1px solid #ccc;
	background: #fff;
}

.check_mark {
	display: none;
}

.radioBtn .btn.active .check_mark {
	display: inline-block;
}

.inside_gray_box .form-group {
	margin-bottom: 1.5rem;
}

#date-container .datepicker {
	background: url("./assets/images/calendar.svg") no-repeat right 8px center #fff !important;
}

.amt_payment h2 {
	font-size: 1.5rem;
	color: #363636;
	font-weight: 700;
	margin: 0;
}

#addHolder {
	font-size: 14px;
}

.amt_payment a {
	margin: 1rem 0.5rem !important;
}

#SelectHomeBond p {
	color: #bcbcbc !important;
}

#SelectHomeBond span {
	color: #747476 !important;
}

#listBonds h4 {
	font-size: 0.9rem;
	color: #212121;
	font-weight: 700;
}

#listBonds label {
	font-size: 0.9rem;
	color: #939395;
	margin-bottom: 3px;
}

#listBonds>div {
	background: #fff;
	border-bottom: 1px solid #e0e0e0;
}

#listBonds>div .checkbox_main span {
	margin-top: 10px;
}

#listBonds>div .checkbox_main {
	display: block;
	height: inherit;
	margin-bottom: 10px;
}

#listBonds>div .checkbox_main label span {
	font-size: 14px;
	margin: -2px 0 0;
	display: block;
	color: #212121 !important;
	font-weight: 700;
}

#listBonds>div .checkbox_main label span small {
	font-weight: 400;
	font-size: inherit;
}

#listBonds>div #dvInputAmt {
	display: none;
}

/*#listBonds>div.selected {background: rgba(228, 228, 228, 0.62);}*/
#listBonds>div.selected #dvInputAmt {
	display: block;
}

#dvInputAmt .inputAmt {
	float: none;
	margin: 0 auto;
}

#dvInputAmt .inputAmt input {
	background: #fff;
	box-shadow: none;
	font-size: 1.1em;
	font-weight: bold;
	text-align: center;
	border: 1px solid #ced4da;
}

.whats_app_update span {
	font-size: 14px;
	color: #8f8f90;
}

.whats_app_update span a {
	color: #10ba18;
	font-weight: 700;
}

.dateprize_othercat {
	color: #8f8f90;
	font-size: 14px;
	font-weight: 400;
}

.prz span {
	display: inherit;
}

.prz:before {
	content: '|';
	display: inline-block;
	padding: 0 5px;
	color: #8f8f90;
}

.other_category td:nth-child(6) {
	width: 260px;
}

.other_category th {
	color: #716a90 !important;
}

#OtherCategory .inside_gray_box {
	background: #f4f3f9;
}

#OtherCategory .inside_gray_box th {
	background: #f4f3f9 !important;
}

.cd-timeline-navigation {
	list-style: none !important;
}

.events {
	width: 100%;
}

.events ol,
ul li {
	padding: 0px;
}

.cd-horizontal-timeline .events a {
	font-size: 11px !important;
}

.cd-timeline-navigation a.prev {
	left: 0;
}

.allotment_finalisation a {
	font-size: 15px !important;
	color: #716a90 !important;
}

#OtherCategory .card_box {
	box-shadow: none;
}

.other_category_box {
	border-bottom: 1px solid #e0e0e0;
}

.lf_bt_bradius {
	border-radius: 0 0 0 20px;
}

.text-green {
	color: #029b26;
}

.bulletpoints {
	margin: 15px 0;
	padding: 0 0 0 20px;
}

.bulletpoints li {
	padding: 0 0 5px 0;
	font-size: 13px;
}

.confirm_application_table {
	max-width: 300px;
	margin: 0 auto;
}

.confirm_application_table td {
	font-size: 13px;
	text-align: left;
	padding: 0.25rem;
}

.confirm_application_table td:first-child {
	width: 40%;
}

.confirm_application_table td:last-child {
	font-weight: 700;
}

/*score box*/
.cibil_scoreBox {
	width: 297px;
	margin: 0 auto;
	position: relative;
}

.cibil_scoreBox #myCanvas {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
}

.cibil_scoreBox .head {
	position: absolute;
	top: 78px;
	left: 0;
	width: 100%;
	text-align: center;
}

.cibil_scoreBox h2 {
	font-size: 2rem;
	color: #32363e;
	margin: 0;
}

.cibil_scoreBox h3 {
	font-size: 1.25rem;
	margin: 0;
}

.text-excellent {
	color: #22ce77 !important;
}

.text-poor {
	color: #ff6657;
}

.text-average {
	color: #fdca71;
}

.text-good {
	color: #5bd999;
}

/*score box*/
.hide_btn {
	display: none !important;
}

.card-header {
	border: 0px;
}

.accordion-wrapper {
	border-bottom: 1px solid #ccc;
}

/*.form-control::placeholder{font-size: 13px;}*/
.hr_scroll {
	width: 100%;
	overflow-x: auto;
	white-space: nowrap;
}

.hr_scroll .hr_inside {
	display: inline-block;
	width: 50%;
}

.hr_scroll .innner_grid {
	white-space: initial;
}

@media (max-width: 768px) {
	.section {
		padding: 0.75rem 0;
	}

	.section .main_title {
		font-size: 1rem;
		margin-bottom: 0;
	}

	.section h3 {
		font-size: 1rem;
		margin-bottom: 0;
	}

	.section p {
		font-size: 13px;
	}

	.iifl_logo img {
		width: 130px;
	}

	.header_nav {
		font-size: 13px;
	}

	header .header_nav {
		font-size: 10px;
	}

	.banner_content h2 {
		font-size: 1.25rem;
		line-height: 1.4;
	}

	.banner_overlap_band {
		font-size: 13px;
	}

	.card_box {
		padding: 1rem;
	}

	.ipo_box_main_left {
		width: 35px;
	}

	.ipo_box_main_left img {
		width: 35px;
	}

	.ipo_box_main_right h2 {
		font-size: 14px;
		text-align: center;
	}

	.arrow_next img {
		width: 8px;
		margin-right: -5px;
	}

	.ipo_box_main {
		display: block;
	}

	.ipo_box_main_left {
		display: block;
		margin: 5px auto auto auto;
	}

	.ipo_box_main_right {
		padding-left: 0;
		display: block;
	}

	.innner_grid li {
		font-size: 11px;
		padding-right: 12px;
		text-align: center;
	}

	.innner_grid li span {
		font-size: 11px !important;
	}

	.recommended {
		font-size: 9px !important;
		padding: 0.2rem 1rem !important;
	}

	.title_main h2 {
		text-align: center;
		width: 98%;
	}

	.secondary_btn,
	.primary_btn,
	.gray_btn {
		font-size: 13px !important;
	}

	.btn_group a {
		margin: 0px auto;
		display: block;
		float: inherit !important;
	}

	.btn_group .secondary_btn {
		margin-bottom: 12px;
	}

	.banner_right img {
		width: 70%;
		margin: 0 auto;
		text-align: center;
		display: block;
	}

	#FAQ .nav-tabs .nav-link {
		color: #8f8f90;
		font-size: 13px;
		padding: 0.5rem 1rem;
	}

	#Faq .card-header span,
	#Faq p {
		font-size: 13px;
	}

	#Faq .card-body {
		padding: 0.5rem 0;
	}

	.banner_overlap_band {
		margin-top: -15px;
	}

	.big_card .title_main h2 {
		width: 100%;
		display: block;
	}

	.big_card .title_main span {
		display: block;
	}

	.big_card .ipo_box_main_right h2 {
		font-size: 14px;
		text-align: center !important;
	}

	.big_card .btn {
		margin: 0px auto;
		display: block;
		float: inherit !important;
	}

	.big_card .innner_grid li {
		font-size: 12px;
		padding-right: 12px;
		text-align: center;
	}

	.big_card .innner_grid li span {
		font-size: 11px;
	}

	.big_card .innner_grid {
		display: block;
	}

	.big_card .innner_grid li {
		width: 48% !important;
		display: inline-block;
		margin-bottom: 10px;
	}

	.score_meter {
		position: inherit;
		top: inherit;
		transform: inherit;
		width: 100%;
	}

	.score_card_main {
		width: 100%;
	}

	.analyst_view_main_box {
		margin: 0 auto;
		float: inherit;
		display: block;
		max-width: inherit;
	}

	.terms_main_tbl {
		overflow: scroll;
	}

	.terms_main_tbl table th:nth-child(1) {
		background: #fff;
	}

	.terms_main_tbl table td,
	th {
		font-size: 12px;
		white-space: nowrap;
	}

	.benefits_grid span {
		font-size: 13px;
	}

	.form_otp_main h2 {
		font-size: 1rem;
	}

	.amt_payment h2 {
		font-size: 1.2rem;
	}

	.card_box h2 {
		font-size: 14px;
	}

	.hr_scroll .card_box h2 {
		white-space: initial;
	}

	#BIDBOX .form-group {
		margin-bottom: 0.25rem;
	}

	#BIDBOX input {
		padding: .175em .75em !important;
	}

	.number_count .minus,
	.number_count .plus {
		border-radius: 4px;
		padding: 0.35rem 1rem;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		font-size: 1rem;
		color: #868686;
		cursor: pointer;
		line-height: 20px;
		font-weight: bold;
	}

	#addHolder {
		font-size: 11px;
	}

	#StepBox h3,
	.step_right h2 {
		font-size: 1rem;
	}

	.step_left,
	.step_left img {
		width: 40px;
	}

	.inside_gray_box .form-group {
		margin-bottom: 1rem;
	}

	#listBonds label {
		font-size: 11px;
	}

	#dvInputAmt .inputAmt input,
	#listBonds>div .checkbox_main label span {
		font-size: 13px;
	}

	.login,
	.login:hover {
		padding-left: 0px;
	}

	.other_category td:nth-child(6) {
		width: auto;
	}

	.prz span {
		display: inline-block !important;
	}

	.dateprize_othercat {
		font-size: 12px;
		text-align: center;
		margin-bottom: 11px;
		line-height: 16px;
	}

	#OtherCategory .arrow_next p {
		text-align: center !important;
	}

	/*.terms_main_tbl table thead th {position: -webkit-sticky; position: sticky;top: 0;
background:#F5F8F9 !important;color: #828F93;border-bottom: 1px solid#D8DDDD;}*/
	.terms_main_tbl table tbody th {
		position: inherit;
	}

	.ipo_popup h3 {
		color: #212121;
		/* margin-bottom: 0px;*/
		font-size: 1.1rem;
		font-weight: 700;
	}

	.ipo_popup h5 {
		color: #969696;
		/* margin-bottom: 0px;*/
		font-size: 14px;
	}

	/*#myCanvas{width: 240px;}
.cibil_scoreBox img{width: 240px;}*/
	#OtherCategory .green_btn {
		font-size: 11px !important;
	}
}

@media (max-width: 1000px) {
	.hr_scroll .hr_inside {
		width: 100%;
	}
}

@media (min-width: 400px) {
	#listBonds>div .checkbox_main label span+span::before {
		content: '|';
		display: inline-block;
		padding: 0 5px 0 0;
		color: #8f8f90;
	}

	#listBonds>div .checkbox_main label span {
		display: inline-block;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.card_box {
		padding: 1rem;
	}

	.ipo_box_main_right h2 {
		font-size: 1rem;
	}

	.innner_grid li span {
		font-size: 14px;
	}

	.arrow_next img {
		width: 8px;
		margin-right: -5px;
	}

	.secondary_btn,
	.primary_btn {
		font-size: 13px !important;
	}

	.banner_content h2 {
		font-size: 1.75rem;
	}

	.banner_overlap_band {
		font-size: 15px;
	}

	.big_card .ipo_box_main_right h2 {
		font-size: 1rem;
	}

	.big_card .innner_grid li span {
		font-size: 14px;
	}

	.big_card .innner_grid li {
		font-size: 1rem;
	}

	.analyst_view_main_box {
		max-width: 240px;
	}

	.section .main_title {
		font-size: 1.25rem;
	}

	.step_left,
	.step_left img {
		width: 40px;
	}
}

/*button style*/
.primary_btn {
	text-align: center;
	padding: 0.55rem 1.5rem;
	border-radius: 55px;
	color: #fff !important;
	font-size: 0.9rem;
	font-weight: 400;
	display: inline-block;
	margin: 0;
	line-height: 1.1;
	background: #f37921;
	text-decoration: none !important;
	outline: none !important;
	transition: background 0.8s;
}

.primary_btn:hover {
	background: #ea721b radial-gradient(circle, transparent 1%, #ea721b 1%) center/15000%;
	color: #fff !important;
}

.primary_btn:active {
	background-color: #ff832a;
	background-size: 100%;
	transition: background 0s;
}

button {
	outline: none !important;
}

.primary_btn:focus {
	outline: none !important;
}

.secondary_btn {
	cursor: inherit;
	text-align: center;
	padding: 0.55rem 1.5rem;
	border-radius: 55px;
	color: #4d3cbe !important;
	font-size: 0.9rem;
	font-weight: 400;
	display: inline-block;
	margin: 0;
	line-height: 1.1;
	background: #f7f7ff;
	text-decoration: none !important;
	outline: none !important;
	border: 1px dashed #cec7f7;
	transition: background 0.8s;
}

.secondary_btn:hover {
	background: #ebebfb radial-gradient(circle, transparent 1%, #ebebfb 1%) center/15000%;
	color: #4d3cbe !important;
}

.secondary_btn:active {
	background-color: #f9f9ff;
	background-size: 100%;
	transition: background 0s;
}

.secondary_btn:focus {
	outline: none !important;
}

.gray_btn {
	text-align: center;
	padding: 0.55rem 1.5rem;
	border-radius: 55px;
	color: #717171 !important;
	font-size: 0.9rem;
	font-weight: 400;
	display: inline-block;
	margin: 0;
	line-height: 1.1;
	background: #fff;
	text-decoration: none !important;
	outline: none !important;
	border: 1px solid #717171;
	transition: background 0.8s;
}

.gray_btn:hover {
	background: #f7f7f7 radial-gradient(circle, transparent 1%, #f7f7f7 1%) center/15000%;
	color: #717171 !important;
}

.gray_btn:active {
	background-color: #ececec;
	background-size: 100%;
	transition: background 0s;
}

.gray_btn:focus {
	outline: none !important;
}

/*button style*/
/*checkbox*/
.checkbox_main>span:after,
.checkbox_main>span:before {
	content: "";
	display: inline-block;
	background: #e86b1f;
	width: 0;
	height: 0.2em;
	position: absolute;
	transform-origin: 0% 0%;
}

.checkbox_main {
	position: relative;
	height: 0.5em;
	display: inline-block;
	align-items: center;
	width: auto;
}

.checkbox_main>input {
	display: none;
}

.checkbox_main>input:checked~span {
	background: #fff;
	border-color: #dcdcdc;
}

.checkbox_main>input:checked~span:before {
	width: 0.75em;
	height: 0.15em;
	transition: width 0.1s;
	transition-delay: 0.15s;
}

.checkbox_main>input:checked~span:after {
	width: 0.4em;
	height: 0.15em;
	transition: width 0.1s;
	transition-delay: 0.1s;
}

.checkbox_main>input:disabled~span {
	background: #ececec;
	border-color: #dcdcdc;
}

.checkbox_main>input:disabled~label {
	color: #dcdcdc;
}

.checkbox_main>input:disabled~label:hover {
	cursor: default;
}

.checkbox_main>label {
	display: inline-block;
	padding-left: 2rem !important;
	position: relative;
	z-index: 2;
	cursor: pointer;
	margin-bottom: 0;
}

.checkbox_main>span {
	display: inline-block;
	width: 1em;
	height: 1em;
	border: 1px solid #ccc;
	position: absolute;
	left: 0;
	transition: all 0.2s;
	z-index: 1;
	box-sizing: content-box;
	margin: .1em 0 0;
}

.checkbox_main>span:before {
	transform: rotate(-55deg);
	top: 0.75em;
	left: 0.37em;
}

.checkbox_main>span:after {
	transform: rotate(35deg);
	bottom: 0.35em;
	left: 0.2em;
}

/*checkbox*/

/*form label start*/

.error:focus~.form__label {
	color: red;
}

label.error {
	color: red;
	font-size: 12px;
	font-weight: 400;
	padding-top: 3px;
}

.error:focus,
.error:valid {
	border: 0.125rem solid red !important;
}

/*form label end*/

.slick-slide>div {
	margin: 0 1.75rem !important;
}

/* the parent */
.slick-list {
	margin: 0 -1.75rem !important;
}

/* .slider {
	width: calc(100% - 140px);
} */

.loading_screen {
	width: 100%;
	background: rgba(255, 255, 255, 0.6);
	justify-content: center;
	min-height: 100vh;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	backdrop-filter: blur(10px);
}

.loading_screen p {
	margin: 0;
}

.loading_screen.loading_inside {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	min-height: auto;
	backdrop-filter: blur(5px);
	max-height: 100%;
}