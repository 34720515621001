$smobile: 767px;


.main {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
}

.container {
	padding: 40px 70px 36px 70px;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
}

.sideBar {
	width: 380px;
	max-width: 100%;
	height: 100%;
	padding: 40px 32px 108px;
	background-color: #241f55;
	display: flex;
	flex-direction: column;

	h2 {
		font-family: Poppins;
		font-size: 24px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
	}

	.items {
		margin-top: 37px;
		display: flex;
		flex-direction: column;
		gap: 25px;

		div {
			display: flex;
			align-items: center;

			span {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 16px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: #fff;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.main {
		display: flex;
		flex-direction: column;
		min-width: 400px;
		@media screen and (max-width:$smobile) 
		{
			min-width: auto;	
		}
		.container {
			padding: 50px 10px;
			justify-items: center;
			height: auto;
		}
		.sideBar {
			width: 100%;
		}
	}
}