/* Payment Drawer */

.payment-drawer-backdrop.show {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all 0.2s ease;
}

.payment-drawer {
  height: 100vh;
  background: #161126;
  width: 466px;
  position: fixed;
  top: 0;
  right: -466px;
  z-index: 1000;
  padding: 30px;
  padding-top: 100px;
  transition: all 0.2s ease;
}

.payment-drawer.show {
  right: 0;
}

.payment-drawer-haeding {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
}

.payment-drawer-order-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 25px 0;
}

.payment-drawer-gseclogo {
  display: flex;
  color: #ffffff;
}

.payment-drawer-gseclogo img {
  width: 53px;
  margin-right: 15px;
}

.payment-drawer-gseclogo p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.6;
}

.payment-drawer-gseclogo h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
}

.payment-drawer-gsecinfo {
  padding: 25px 0;
}

.payment-drawer-gsecinfo p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b6b6b6;
}

.payment-drawer-gsecinfo h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #c1c1c1;
}

.payment-drawer-paynow-box {
  padding: 26px 15px;
  background: #f7fff7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-drawer-paynow-box.insuf-bal {
  background: #FFE2E2;
}

.payment-drawer-paynow-box h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #1b1b1b;
}

.payment-drawer-paynow-box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.payment-drawer-paynow-box button {
  align-items: center;
  width: 155px;
  height: 47px;
  outline: none;
  border: none;
  background: #008d00;
  border-radius: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.addfund-info {
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: center;
}

.addfund-info p {
  padding-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.06em;
  color: #ffffff;
  margin-bottom: 0;
}

.x-circle img {
  position: absolute;
  top: 25px;
  cursor: pointer;
  right: 25px;
}

.Check_fund_balance {
  background: #3B3844;
  border-radius: 8px;
  padding: 26px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Check_fund_balance h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.Check_fund_balance p {
  margin: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  opacity: 0.7;
}

.Check_fund_balance .spinner-border {
  color: #f36f21;
  width: 30px;
  height: 30px;
  border-right-color: #3B3844;
  animation: spinner-border 1s linear infinite;
}

@media (max-width: 768px) {

  .addfund-info,
  .Check_fund_balance p {
    display: none;
  }

  .Check_fund_balance {
    background: #F3F3F3;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }

  .Check_fund_balance h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }

  .Check_fund_balance .spinner-border {
    border-right-color: #F3F3F3;
  }

  .payment-drawer-gseclogo img {
    width: 46px;
  }

  .payment-drawer {
    background: #fff;
  }

  .payment-drawer {
    height: 100vh;
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1000;
    padding: 20px;
    padding-top: 85px;
  }

  .payment-drawer.show {
    left: 0;
  }

  .payment-drawer-haeding {
    padding-bottom: 30px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #1d1d1d;
  }

  .payment-drawer-order-title {
    padding: 20px 0;
    font-size: 14px;
    color: #3d3d3d;
  }

  .payment-drawer-gseclogo h2 {
    font-size: 16px;
    color: #000000;
  }

  .payment-drawer-gseclogo p {
    font-size: 12px;
    color: #000000;
  }

  .payment-drawer-gsecinfo p {
    color: #7f7f7f;
  }

  .payment-drawer-gsecinfo h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1d1d1d;
  }

  .payment-drawer-mobile-box {
    border: 1px solid #e6e6e6;
    border-radius: 12px;
  }

  .payment-drawer-mobile-wrap {
    padding: 0 15px 0px 15px;
  }

  .payment-drawer-paynow-box {
    padding: 26px 15px;
    background: #effcef;
    border-radius: 0px 0px 11px 11px;
  }

  .payment-drawer-paynow-box button {
    width: 108px;
    height: 40px;
    background: linear-gradient(144.66deg, #32df58 3.94%, #0ea85e 91.87%);
  }

  .payment-drawer-paynow-box h2 {
    font-size: 14px;
    color: #000000;
    opacity: 0.8;
  }

  .payment-drawer-paynow-box p {
    font-size: 11px;
    line-height: 16px;
    opacity: 0.6;
  }

  .x-circle {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    line-height: 48px;
    padding-left: 20px;
  }

  .x-circle img {
    position: unset;
  }
}