.gsec_applied_card {
  background: #ffffff;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.12);
  border-radius: 22px;
  padding: 30px 36px 36px 36px;
  margin-bottom: 30px;
  position: relative;
}

.gsec_applied_card_top {
  display: flex;
  align-items: center;
}

.gsec_applied_card_top h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #303030;
  padding-bottom: 5px;
}

.gsec_applied_card_top p small {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: rgba(48, 48, 48, 0.4);
}

.gsec_applied_card_top p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #01a368;
  background-color: rgba(1, 163, 104, 0.2);
  padding: 5px 8px;
  border-radius: 7px;
  margin-right: 10px;
}

.gsec_applied_card_top p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.gsec_applied_card_top img {
  width: 49px;
  margin-right: 20px;
}

.gsec_applied_card_bottom {
  display: flex;
  gap: 70px;
  padding-top: 20px;
}

.gsec_applied_card_bottom small {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(48, 48, 48, 0.4);
}

.gsec_applied_card_bottom p {
  padding-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #303030;
}

.delete_gsec {
  float: right;
  border: 1px solid #f36f21;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}


@media (max-width: 768px) {
  .gsec_applied_card_top img {
    width: 35px;
  }

  .gsec_applied_card_bottom {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }

  .delete_gsec {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 20px;
    border: 1px solid #f36f21;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
  }

  .gsec_applied_card {
    padding: 10px 10px 80px 10px;
  }

  .gsec_applied_card_top {
    display: block;
  }

  .mob_card {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }

  .gsec_applied_card_top h2 {
    padding-bottom: 0;
  }

  .gsec_applied_card_bottom p {
    font-size: 12px;
    line-height: 18px;
  }
}