$smobile: 767px;
.main {
  min-width: 378px;
  padding-left: 102px;
  padding-right: 70px;
  padding-top: 40px;
  padding-bottom: 56px;
  border: none;
  line-height: 1;
  color: #fff;
  @media screen and (max-width: 768px) {
    padding: 75px 15px;
    background: #fff;
  }

  h1 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #303030;
    margin-bottom: 48px;
  }
}

.nodatafound {
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  background: #eee;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  margin-top: 120px;
}

.noapplieddata {
  display: block;

  h2 {
    font-size: 30px;
    color: #686868;
    margin-top: 20px;
    font-family: Poppins;
  }
  p {
    font-size: 20px;
    color: #a2a2a2;
    line-height: 20px;
    margin: 20px 0;
    line-height: 1.5;
    max-width: 950px;
  }
  a {
    width: 229px;
    height: 48px;
    border-radius: 32px;
    box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
    border: solid 1px #f36f21;
    background-image: linear-gradient(106deg, #ff9556 9%, #f36f21 82%);
    color: #fff;
    font-weight: bold;
    line-height: 1;
    display: flex;
    justify-content: center;
    font-size: 18px;
    align-items: center;
    margin-top: 33px;
    transition: all 0.5s;
    &:hover {
      background: #fff;
      color: #f36f21;
      transition: all 0.5s;
    }
  }
}
