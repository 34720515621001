$smobile:767px;

.main {
	width: 650px;
	height: 648px;
	max-width: 100%;
	max-height: 100%;
	padding: 12px;
	border-radius: 24px;
	-webkit-backdrop-filter: blur(50px);
	backdrop-filter: blur(50px);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	border: solid 1px rgba(255, 255, 255, 0.34);
	background-color: rgba(255, 255, 255, 0.527);
	position: relative;
	@media screen and (max-width:$smobile) 
	{
		width: 100%;
	}
}

div#IPOcancel {
    backdrop-filter: blur(10px);
}

.modalback
{
	backdrop-filter: blur(50px);

	.modalwrap
	{
		min-width: 650px;
		padding: 12px;
		background: rgba(255,255,255,0.5);
		box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
		border-radius: 24px;
		border: solid 1px rgba(255, 255, 255, 0.34);
		min-height: auto;
		@media screen and (max-width:$smobile) 
		{
			width: 100%;
			min-width: auto;
			margin: 0;
		}

		.closeicon
		{
			position: absolute;
			right: 9px;
			width: 20px;
			top: 6px;
			cursor: pointer;
		}
		.headingtitle
		{
			font-size: 24px;
			line-height: 40px;
		}
	}

}



.container {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(30px);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	padding: 40px 24px 24px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;

	span,
	label {
		opacity: 0.8;
		font-family: Poppins;
		font-size: 14px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}
}

.close {
	$dia: 20px;
	width: $dia;
	height: $dia;
	display: grid;
	place-content: center;
	background: none;
	outline: none;
	border: none;
	position: absolute;
	z-index: 200;
	top: 10px;
	right: 10px;

	img {
		height: $dia;
	}
}

.pattern {
	width: 236.7px;
	height: 200px;
	object-fit: contain;
	position: absolute;
	right: 18px;
	bottom: 24px;
}

.head {
	h3 {
		font-family: Poppins;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.67;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
		margin-bottom: 12px;
	}
}

.body {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;

	.input {
		width: 100%;
		display: flex;
		flex-direction: column;

		input {
			width: 320px;
			height: 48px;
			border-radius: 8px;
			background-color: #30303015;
			border: none;
			outline: none;
			padding: 12px 16px;
			margin-top: 9px;

			&::placeholder {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 16px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.5;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}
		}

		a {
			margin-left: 8px;
		}
	}
}

.margin {
	margin-bottom: 80px;
}

.foot {
	display: flex;
	gap: 24px;
	justify-content: flex-end;
	margin-top: 15px;

	.borderbuton
	{
		border: 1px solid #f36f21;
		background: #fff;
		span{
			color: #f36f21;
		}
		@media screen and (max-width:$smobile) 
			{
				font-size: 18px;
			}
	}
	button {
		width: 245px;
		height: 64px;
		max-width: 100%;
		padding: 0 16px 0 24px;
		text-align: center;
		border-radius: 12px;
		box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
		border: solid 1px #f36f21;
		background-image: linear-gradient(113deg, #ff9556 8%, #f36f21 83%);
		display: flex;
		justify-content: center;
		align-items: center;


		span {
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 24px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.24px;
			text-align: center;
			color: #fff;
			@media screen and (max-width:$smobile) 
			{
				font-size: 18px;
			}
		}

		
	}
}

.top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.left {
		display: flex;
		gap: 16px;
		img {
			$dia: 56px;
			height: $dia;
			width: $dia;
			object-fit: contain;
		}

		.heading {
			display: flex;
			flex-direction: column;

			h2 {
				font-family: Poppins;
				font-size: 24px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.67;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
				margin: 0;
			}

			div {
				.green {
					width: 63px;
					height: 24px;
					padding: 4px 8px 3px;
					border-radius: 5px;
					background-color: #01a36818;
					font-family: Poppins;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 3.33;
					letter-spacing: normal;
					text-align: right;
					color: #01a368;
					margin-right: 16px;
				}

				span {
					font-family: Poppins;
					font-size: 14px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.86;
					letter-spacing: normal;
					text-align: left;
					color: rgba(48, 48, 48, 0.4);
				}

				.no {
					height: 20px;
					font-family: Poppins;
					font-size: 14px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.86;
					letter-spacing: normal;
					text-align: left;
					color: #303030;
				}
			}
		}
	}

	.right {
		display: flex;
		gap: 24px;

		.heading {
			display: flex;
			flex-direction: column;

			h2 {
				font-family: Poppins;
				font-size: 24px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.67;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
				margin: 0;
			}

			div {
				.green {
					width: 63px;
					height: 24px;
					padding: 4px 8px 3px;
					border-radius: 5px;
					background-color: #01a36818;
					font-family: Poppins;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 3.33;
					letter-spacing: normal;
					text-align: right;
					color: #01a368;
					margin-right: 16px;
				}

				span {
					font-family: Poppins;
					font-size: 14px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.86;
					letter-spacing: normal;
					text-align: left;
					color: rgba(48, 48, 48, 0.4);
				}

				.no {
					height: 20px;
					font-family: Poppins;
					font-size: 14px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.86;
					letter-spacing: normal;
					text-align: left;
					color: #303030;
				}
			}
		}
	}
}

.bidlisttabl{
	width: 100%;
	margin-top: 15px;
	text-align: center;

	thead {
		tr {
			th {
				opacity: 0.5;
				font-family: Poppins;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 3.33;
				letter-spacing: normal;
				text-align: center;
				color: #303030;
				margin: 0 !important;
				padding: 0 !important;
			}
		}
	}

	tbody
	{
		// tr:nth-child(odd){
		// 	background: #fff;
		// }
		tr {
			padding: 12px 16px;
			border-radius: 4px;
			-webkit-backdrop-filter: blur(30px);
			backdrop-filter: blur(30px);
			box-shadow: 1px 10px 20px 0px rgb(0 0 0 / 16%);
			border: solid 1px #fff;
			background-color: #fff;
			border-bottom: 18px solid transparent;
		
		}
		td{
			padding: 10px 16px;
			font-family: Poppins;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 2.5;
			letter-spacing: normal;
			color: #303030;
			@media screen and (max-width:$smobile) 
			{
				padding: 10px 5px;
			}
			
		}
	}
}
