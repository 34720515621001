$smobile: 767px;
.main {
	width: 100%;
	border-radius: 22px;
	box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
	margin-bottom: 32px;
	padding: 30px 36px 36px 36px;
	@media screen and (max-width:$smobile) 
	{
		padding: 10px;
		position: relative;
	}
}

.top {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media screen and (max-width:$smobile) 
	{
		justify-content: flex-start;
	}

	.left {
		display: flex;
		gap: 16px;
		img {
			$dia: 56px;
			height: $dia;
			width: $dia;
			object-fit: contain;
		}

		.heading {
			display: flex;
			flex-direction: column;

			h2 {
				font-family: Poppins;
				font-size: 24px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.67;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
				margin: 0;
				@media screen and (max-width: 580px) {
					font-size: 18px;
				}
			}

			div {
				.green {
					width: 63px;
					height: 24px;
					padding: 4px 8px 3px;
					border-radius: 5px;
					background-color: #01a36818;
					font-family: Poppins;
					font-size: 12px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 3.33;
					letter-spacing: normal;
					text-align: right;
					color: #01a368;
					margin-right: 16px;
				}

				.yellow {
          width: 63px;
          height: 24px;
          padding: 4px 8px 3px;
          border-radius: 5px;
          background-color: rgba(243, 110, 32, 0.094);
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.33;
          letter-spacing: normal;
          text-align: right;
          color: rgb(243, 110, 32);
          margin-right: 16px;
        }
        .red {
          width: 63px;
          height: 24px;
          padding: 4px 8px 3px;
          border-radius: 5px;
          background-color: rgb(255, 0, 0, 0.094);
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 3.33;
          letter-spacing: normal;
          text-align: right;
          color: rgb(255, 0, 0);
          margin-right: 16px;
        }


				span {
					font-family: Poppins;
					font-size: 14px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.86;
					letter-spacing: normal;
					text-align: left;
					color: rgba(48, 48, 48, 0.4);
				}

				.no {
					height: 20px;
					font-family: Poppins;
					font-size: 14px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.86;
					letter-spacing: normal;
					text-align: left;
					color: #303030;
				}
			}
		}

		@media screen and (max-width: 580px) {
			flex: 4;
		}
	}

	.right {
		display: flex;
		gap: 24px;

		&:empty
		{
			display: none;
		}

		@media screen and (max-width: 580px) {
			display: flex;
			min-width: 130px;
			margin-right: auto;
			grid-gap: 24px;
			flex: 2;
			gap: 24px;
			justify-content: space-around;
			flex-direction: column;
			align-items: center;
		}

		@media screen and (max-width: 340px) {
			gap: 0;
		}

		.outline {
			width: 65px;
			height: 48px;
			border-radius: 32px;
			border: solid 1px #f36f21;
			display: grid;
			place-content: center;
			background: #fff;
		}

		.primary {
			width: 300px;
			height: 48px;
			display: grid;
			place-content: center;
			border-radius: 32px;
			box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
			border: solid 1px #f36f21;
			background-image: linear-gradient(103deg, #ff9556 9%, #f36f21 81%);
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 18px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.18px;
			text-align: right;
			color: #fff;

			@media screen and (max-width: 580px) {
				max-width: fit-content;
				height: max-content;
				font-size: 13px;
				text-align: center;
				padding: 8px;
			}
		}
	}
}

.body {
	margin-top: 38px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	@media screen and (max-width:$smobile) 
	{
		grid-template-columns: 1fr 1fr;
	}

	div {
		display: flex;
		flex-direction: column;
		height: fit-content;

		span {
			opacity: 0.4;
			font-family: Poppins;
			font-size: 12px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 3.33;
			letter-spacing: normal;
			text-align: left;
			color: #303030;
		}

		h4 {
			font-family: Poppins;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			color: #303030;
			margin: 0;
		}
	}
}
.greenwrning.warning
{
	background-color: #21f26715;
	img{
		filter: hue-rotate(100deg);
	}
}
.warning {
	height: 52px;
	width: 100%;
	border-radius: 9px;
	background-color: #f26e2115;
	display: flex;
	align-items: center;
	padding-left: 12px;
	gap: 12px;
	margin-top: 20px;

	img {
		width: 24px;
		height: 24px;
		object-fit: contain;
	}

	span {
		opacity: 0.6;
		font-family: Poppins;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.86;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}
}

.timeline {
	margin-top: 33px;
	width: 100%;
	@media screen and (max-width: 580px) {
		overflow: auto;
	}
	
	.numbers {
		width: 100%;
		display: grid;
		grid-template-columns: auto 1fr auto 1fr auto 1fr auto 1fr auto 1fr auto;
		@media screen and (max-width: 580px) {
			min-width: 640px;
		}
		.number {
			.done {
				$dia: 28px;
				height: $dia;
				width: $dia;
				border-radius: 50%;
				background: linear-gradient(90deg, #21d0f3, #216af3);
				display: grid;
				place-content: center;
				margin-bottom: 10px;
				margin-top: 4px;
			}

			.notDone {
				$dia: 28px;
				height: $dia;
				width: $dia;
				border-radius: 50%;
				background: #e7e5e9;
				display: grid;
				place-content: center;
				margin-bottom: 10px;
				margin-top: 4px;
			}

			.wait {
				$dia: 36px;
				width: $dia;
				height: $dia;
				padding: 2px;
				border-radius: 50%;
				background: #fdedd2;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 6px;

				img {
					$dia: 24px;
					width: $dia;
					height: $dia;
					background: #f3a621;
					border-radius: 50%;
				}
			}
		}
	}

	.text {
		display: flex;
		width: 100%;
		justify-content: space-between;

		@media screen and (max-width: 580px) {
			min-width: 640px;
		}

		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			
				&:first-child{
					align-items: flex-start;
				}
				&:last-child{
					align-items: flex-end;
				}
			

			h5 {
				font-family: Poppins;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: center;
				color: #303030;
				margin: 0;
			}

			span {
				opacity: 0.4;
				font-family: Poppins;
				font-size: 12px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
				@media screen and (max-width: 580px) {
					font-size: 10px;
					text-align: center;
				}
			}
		}
	}

	.doneLine {
		height: 4px;
		background: linear-gradient(90deg, #21d0f3, #216af3);
		margin-top: 14px;
	}

	.line {
		height: 4px;
		background: #ebebeb;
		margin-top: 14px;
	}
}

.foot {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 34px;

	button {
		background: none;
		border: none;
		outline: none;
		display: flex;
		align-items: center;
		gap: 4px;

		span {
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.14px;
			text-align: left;
			color: #2e276f;
		}

		.up {
			transform: rotateZ(180deg);
		}
	}
}


.sgbtoprow
{
	display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
	@media screen and (max-width:$smobile) 
	{		
		line-height: 23px;
		gap: 10px;
		position: relative;
		padding-bottom: 10px;
	}
	
	.sgbdetails
	{
		flex: 1;
		h3{
			color: #303030;
			font-size: 24px;
			width: 100%;
			font-weight: 600;
			margin-bottom: 15px;
		}
		.sgbapplywrap{
			@media screen and (max-width:$smobile) 
			{
				position: absolute;
				left: 0;
				top: 100%;
			}
		}
		span{
			background-color: rgb(1 163 104 / 20%);
			color: #01a368;
			font-size: 12px;
			padding: 5px 8px;
			border-radius: 7px;
			float: left;
			margin-right: 15px;
			@media screen and (max-width:$smobile) 
			{
				width: 102px;
				text-align: center;
			}
			
		}
		p{
			font-size: 14px;
			opacity: 0.8;
			margin-top: 4px;
			float: left;
			font-weight: 400;
			b{
				font-weight: 400;
			}
			@media screen and (max-width:$smobile) 
			{
				font-size: 0;
				b{
					font-size: 14px;
				}
			}
		}
	}
	.applybuttondiv
	{
		margin-left: auto;
		@media screen and (max-width:$smobile) 
		{
			position: absolute;
			bottom: 20px;
			right: 10px;
		}
		a{
			height: 48px;
			display: grid;
			place-content: center;
			border-radius: 32px;
			box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
			border: solid 1px #f36f21;
			background-image: linear-gradient(103deg, #ff9556 9%, #f36f21 81%);
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 18px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.18px;
			text-align: right;
			color: #fff;
			width: 180px;
		}
	}
}
.sgbbottomrow{
	display: flex;
    justify-content: flex-start;
    margin: 25px 0;
	margin-bottom: 0;
    gap: 45px;
	@media screen and (max-width:$smobile) 
	{
		flex-wrap: wrap;
		margin-bottom: 80px;
		margin-top: 50px;
		gap: 0;
	}

	.singlesgbbottom{
		display: flex;
		flex-direction: column;
		gap: 15px;
		line-height: 1;
		@media screen and (max-width:$smobile) 
		{
			margin:10px 0;
			width: 50%;
			float: left;
		}

		span{
			color: #303030;
			opacity: 0.6;
			font-size: 12px;
			font-weight: 500;
		}
		p{
			font-size: 14px;
			color: #303030;
		}
	}
}

.ncdbottom
{
	background-color: rgb(242 110 33 / 10%);
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 25px;
    margin-top: 18px;

	@media screen and (max-width:767px) {
		flex-direction: column;
		
	}
	span{

		display: flex;
		align-items: center;
		gap: 10px;
		@media screen and (max-width:767px) {
			flex-direction: column;
			align-items: flex-start;
		}


		&:first-child{
			flex: 1;
			color: #000;
			opacity: 0.6;
		}

		a{
			color: #f36f21;
		}
	}

}

.ncdmainrow{
	.sgbbottomrow
	{
		margin-bottom: 10px;
	}
	.applybuttondiv
	{
		@media screen and (max-width:767px) {
			display: none !important;
		}
	}
	.ncdmobileapplyrow
	{
		display: none !important;
		.outline {
			width: 65px;
			height: 48px;
			border-radius: 32px;
			border: solid 1px #f36f21;
			display: grid;
			place-content: center;
			background: #fff;
		}
		.applybtn
		{
			margin-left: auto;
			height: 48px;
			display: grid;
			place-content: center;
			border-radius: 32px;
			box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
			border: solid 1px #f36f21;
			background-image: linear-gradient(103deg, #ff9556 9%, #f36f21 81%);
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 18px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.18px;
			text-align: right;
			color: #fff;
			width: 180px;
		}

		@media screen and (max-width:767px) {
			display: flex !important;
		}
	}
}