html {
  font-size: 0.73vw;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #303030;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

p {
  font-size: 1.6rem;
}

/* Header starts here */
#header {
  padding: 4rem 7rem;
  display: flex;
  justify-content: space-between;
}

.header-left {
  display: flex;
  gap: 2.3rem;
}

.header-left img {
  width: 6.2rem;
  height: 6.2rem;
}

.header-left h1 {
  font-size: 2.4rem;
}

.header-left div .header-heading-subtext {
  font-size: 1.6rem;
  display: flex;
  gap: 1rem;
}

.header-left div .header-heading-subtext p span {
  opacity: 0.4;
}

.header-left div .header-heading-subtext p:first-child {
  color: #ff0000;
  opacity: 1;
}

.header-right .steps {
  display: flex;
  width: 100%;
  font-size: 1.4rem;
}

.steps p {
  padding: 0.2rem 0.9rem;
  border-radius: 50%;

  opacity: 1;
}

.steps p:first-child {
  padding: 0.2rem 1.1rem;
}

/* .steps hr {
    border: none;
    background-image: radial-gradient(circle at 0 0, #21f3f3, #214cf3);
    height: 0.2rem;
    opacity: 1;
    width: 100%;
    margin-top: 1.1rem;
  } */

.step-names {
  margin-top: 0.8rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.step-names p {
  font-weight: 600;
  font-size: 1.2rem;
  opacity: 0.6;
  text-align: center;
}

.step-names p:nth-child(1) {
  text-align: left;
}

.step-names p:last-child {
  opacity: 1;
}

.step-names p:nth-child(3) {
  margin-left: 3rem;
}

.step-names p:last-child {
  margin-left: 5.5rem;
}

#container.ncdappl4 {
  padding: 6rem 7rem 9rem 7rem;
  grid-template-columns: auto;
}

#container img {
  width: 6rem;
  height: 6rem;
}

#container h1 {
  margin: 2.4rem 0 0.8rem 0;
  font-size: 2.4rem;
}

#container .subtext {
  display: flex;
  font-size: 1.6rem;
  gap: 1.6rem;
}

#container .subtext p {
  font-weight: 600;
}

#container .subtext p span {
  opacity: 0.6;
  font-weight: 500;
}

.next-steps {
  margin-top: 7.2rem;
  margin-bottom: 11rem;
}

.next-steps h2 {
  font-size: 2rem;
  margin-bottom: 3rem;
}

.next-steps ul {
  list-style: none;
}

.next-steps ul li {
  font-size: 1.4rem;
  opacity: 0.9;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.next-steps ul li span:first-child {
  opacity: 0.8;
  font-size: 2rem;
  background-color: rgb(0, 0, 0, 0.14);
  padding: 0.3rem 1.7rem 0.4rem 1.9rem;
  width: 1.2rem;
  height: 3.9rem;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #241f55;
  font-weight: 600;
  padding: 0;
  width: 40px;
  height: 40px;
  justify-content: center;

}

/* .next-steps ul li:first-child span:first-child {
    padding: 0.3rem 1.36rem 0.4rem 1.9rem;
    width: 1.4rem;
  } */
.next-steps ul li:last-child span:last-child {
  flex: 1;
}

.next-steps ul li:last-child a {
  color: #241f55;
  font-weight: 600;
}

footer {
  background-color: #241f55;
  padding: 1.5rem 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer a {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 2.4rem;
  border-radius: 1.2rem;
}

footer p {
  color: #fff;
  font-size: 2.4rem;
}

footer p span {
  display: block;
  font-size: 1.6rem;
  opacity: 0.6;
}

footer .buttons {
  display: flex;
  gap: 2rem;
}

footer .buttons .download-app {
  font-weight: 600;
  padding: 1.6rem 3.6rem 1.5rem;
  background-color: #ffff;
  color: #241f55;
}

footer .buttons .view-app {
  color: #ffff;
  padding: 1.5rem 6.2rem 1.6rem;
  border: 0.1rem solid #ffff;
}

/* ============================== */
.upi_confirm_model {
  padding: 20px;
  max-width: 638px;
  width: 95%;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  border: 1px solid #ddd;
  margin-top: 30px;
}

.upi_confirm_model_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asba_pay_connector {
  position: relative;
}


.asba_pay_connector:before {
  display: block;
  content: "";
  height: 215px;
  position: absolute;
  border-left: 1px solid rgba(218, 160, 82, 0.53);
  left: 7px;
  top: 8px;
}

.payment_checkdd {
  position: relative;
    z-index: 2;
}
.left-line{
  position: relative;
}
.d-flex.pb-5.left-line:before {
  display: block;
  content: "";
  height: 102%;
  position: absolute;
  border-left: 1px solid rgba(218, 160, 82, 0.53);
  left: 12px;
  top: 8px;
}
.asba_pay_connector{
  display: none;
}
.d-flex.pb-5.left-line:last-child::before{
    display: none;
} 
.step_connect_upi_mobile {
  display: block;
  content: "";
  height: 100px;
  position: absolute;
  border-left: 2px dashed #B4B4B4;
  left: 43px;
  top: 245px;
}

.pay_approve h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  color: #FFA928;
}

.app_recieved span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1D1D1D;
}
.d-flex.py-5.line-left1{
  position: relative;
}
.d-flex.py-5.line-left1:before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  border-left: 2px dashed #B4B4B4;
  left: 10px;
  top: 4rem!important;
}
/* ============================ */

@media (max-width: 769px) {
  .upi_confirm_model_wrapper {
    align-items: flex-start;
  }

  html {
    font-size: 1.29vw;
  }

  .step_connect_upi_mobile {
    left: 31px;
    top: 217px;
    height: 65px;
  }

  #header {
    flex-direction: column-reverse;
    justify-content: column-reverse;
    gap: 3rem;
    padding: 4rem;
  }

  .header-left div .header-heading-subtext {
    flex-direction: column;
    gap: 0;
  }

  .header-left div .header-heading-subtext .seperator {
    display: none;
  }

  .step-names {
    gap: 11rem;
  }

  #container {
    padding: 1rem 5rem 6rem 5rem;
  }

  footer {
    padding: 1.5rem 4rem;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  footer a {
    font-size: 1.8rem;
  }

  footer .buttons {
    gap: 2.5rem;
  }
  .upi_confirm_model{
    padding: 0;
    border:0;
    margin-top:0px;
    width: 100%;
  }
  
}


@media (min-width: 320px) and (max-width: 480px) {
  .step_connect_upi_mobile{
    left: 33px;
  }
  html {
    font-size: 1.9vw;
  }

  #header {
    padding: 4rem 2rem;
  }

  .step-names {
    gap: 4rem;
  }

  #container {
    padding: 1rem 2rem 20rem 2rem;
  }

  #container .subtext {
    flex-direction: column;
    margin-top: 2rem;
    gap: 0;
  }

  #container .subtext p:nth-child(2) {
    display: none;
  }

  footer {
    padding: 1.5rem 2rem;
  }

  footer .buttons {
    flex-direction: column;
    width: 100%;
  }

  footer a {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  footer .buttons .download-app {
    padding: 1.5rem 0;
  }

  footer .buttons .view-app {
    padding: 1.5rem 0;
  }

  .confirm_model_container{
    max-width: 481px;
  }
}