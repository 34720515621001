.order-confirmation {
  padding-top: 100px;
}

.order-confirmation h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #1D1D1D;
}

.order-confirmation-scheme {
  display: flex;
  align-items: center;
}

.order-confirmation-scheme img {
  width: 32px;
  /* height: 32px; */
  /* border-radius: 50%; */
  /* object-fit: cover; */
  margin-right: 10px;
}

.order-confirmation-scheme span,
.order-confirmation-scheme-detail span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1D1D1D;
}

.order-confirmation-scheme-detail {
  display: flex;
  align-items: center;
}

.order-confirmation-scheme-detail small {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7F7F7F;
  margin-right: 10px;
}

.order-confirmation-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}

.order-confirmation-btn button {
  box-sizing: border-box;
  width: 239.15px;
  height: 48px;
  outline: none;
  border: none;
  border: 1px solid #5E16BD;
  border-radius: 96px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.btn-exp {
  color: #FFFFFF;
  background: linear-gradient(90deg, #5C15BC 0%, #8523C6 100%);
  margin-right: 30px;
}

.btn-view {
  color: #5E16BD;
  background-color: #fff;
}

.order-confirmation-what-next {
  padding: 16px;
  gap: 16px;
  width: 330px;
  height: 160px;
  background: #F8F8FF;
  border-radius: 8px;
  margin-top: 30px;
}

.order-confirmation-what-next h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}

.order-confirmation-what-next p,
.order-confirmation-what-next small span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1D1D1D;
}

.order-confirmation-what-next small span {
  font-size: 14px;
}

.order-confirmation-what-next small {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 21px;
  color: #7F7F7F;
}

.order-con-title {
  padding: 20px 0;
}

.order-confirmation-btn {
  padding: 30px 0;
}

.order-schname-detail {
  display: none;
}


@media(max-width:768px) {
  .order-confirmation-info {
    display: none;
  }

  .order-confirmation {
    padding-top: 30px;
    padding-bottom: 120px;
  }

  .order-confirmation h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .order-confirmation-btn {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }

  .btn-exp {
    margin-right: 0;
  }

  .order-confirmation-btn button {
    width: 153px;
    font-weight: 500;
    font-size: 14px;
  }

  .order-confirmation-what-next h2 {
    font-size: 16px;
    line-height: 24px;
  }

  .order-confirmation-what-next p {
    font-size: 14px;
    line-height: 21px;
    padding: 10px 5px 0 0;
  }

  .order-confirmation-what-next small {
    font-size: 12px;
    line-height: 18px;
  }

  .check-icon {
    text-align: center;
  }

  .schname-logo {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }

  .schname-logo img {
    width: 32px;
    margin-right: 10px;
  }

  .order-schname-detail {
    display: block;
    border: 1px solid #E6E6E6;
    border-radius: 12px;
    padding: 20px 15px;
  }

  .order-schname-detail small {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7F7F7F;
  }

  .order-schname-detail h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1D1D1D;
    padding-top: 5px;
  }

  .schname-logo span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
  }

  .schname-logo p {
    margin: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: capitalize;
    color: #000000;
    opacity: 0.6;
  }
}