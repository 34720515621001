.detailshead {
  margin-top: 20px;
  padding: 20px;
  padding-top: 80px;
  border-bottom: 1px dashed #b8ade2;
  background: #fcf9ff url("assets/gsecbadge.png");
  background-repeat: no-repeat;
  background-position: center right;

  .topDetails {
    padding-right: 100px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 170%;
    }
    h2 {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      background: linear-gradient(90deg, #7328d9 0%, #8d26d2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6d6d6d;
    }
  }
  .detailsTags {
    white-space: nowrap;
    overflow: auto;
    margin-top: 20px;
    display: flex;
    gap: 15px;

    span {
      white-space: nowrap;
      padding: 8px 10px;
      background: #fafeff;
      border: 1px solid #e6d6fb;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #000000;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.investmentDetails {
  padding: 20px;
  background: #fcf9ff;
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #898989;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #303030;
  }
  .tenureReturns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #acabab;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #303030;
    }
  }
}
.investCmsDetails {
  padding: 20px;
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #313131;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    span {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      color: #8728d5;
      background: #f8f0ff;
      border-radius: 4px;
      padding: 2px 5px;
      display: flex;
      gap: 5px;
    }
  }
  .govtBlock {
    background: url("assets/gsecbadge.png"),
      linear-gradient(90deg, #7328d9 0%, #8d26d2 100%);
    border-radius: 8px;
    min-height: 144px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: right center;

    h2 {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
    }

    .readNow {
      margin-top: 24px;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #ffffff;
    }
  }
}

.gsecIndeemandWrap {
  padding: 20px;
  .GsecIndemand {
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #1d1d1d;
      margin-bottom: 18px;
    }
    .SingleIndemand {
      margin: 18px 0;
      position: relative;
      background: linear-gradient(131.53deg, #4940a9 4.06%, #241f55 96.05%);
      border-radius: 20px;
      padding: 20px;
      width: 100%;
      float: left;

      .timer {
        background: linear-gradient(136.05deg, #fbbf00 4.24%, #ff421d 102.48%);
        position: absolute;
        top: 0;
        right: 20px;
        padding: 5px 10px;
        border-radius: 20px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        transform: translate(0px, -50%);
        display: flex;
        align-items: center;
        gap: 7px;
      }

      .gsecType {
        background: #160f58;
        position: absolute;
        top: -8px;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        padding: 3px 10px;
        left: 20px;
        border-radius: 0 0px 5px 5px;

        &::after {
          content: "";
          width: 0;
          height: 0;
          border-bottom: 8px solid #160f58;
          border-right: 16px solid transparent;
          top: 0px;
          position: absolute;
          left: 100%;
          transform: translate(-0.3px, 0px);
        }
      }

      .head {
        display: grid;
        grid-template-columns: 70px auto;
        align-items: center;
        gap: 20px;
        margin-top: 25px;

        img {
          max-width: 100%;
          background: #ffffff;
          border-radius: 15px;
        }
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;
        }
      }
      .investmentDetailsCard {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #b2afd3;
        }
        h2 {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
        }
      }
      button {
        background: linear-gradient(144.66deg, #ff9556 3.94%, #f36f21 91.87%);
        border: 1px solid #f36f21;
        border-radius: 96px;
        width: 100%;
        max-width: 240px;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin: auto;
        display: block;
        min-height: 50px;
      }
    }
  }
}

.othersInvestmnet {
  .searchSection {
    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    input {
      background: url("./assets/icon9.png");
      background-repeat: no-repeat;
      background-position: left 20px center;
      border: 1px solid #c6c6c6;
      border-radius: 50px;
      height: 40px;
      padding-left: 45px;
      margin-top: 15px;
      margin-bottom: 20px;
      width: 100%;
      max-width: none;
    }
  }

  .sorting {
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    font-size: 13px;
    line-height: 18px;
    color: #3a3387;
    gap: 12px;
    white-space: nowrap;
    overflow: auto;
    // margin-bottom: 20px;

    span {
      padding: 2px 8px;
      display: flex;
      align-items: center;
      border: 1px solid #a6a6a6;
      border-radius: 18px;
      gap: 8px;
    }
  }

  width: 100%;
  padding: 0 20px;
  .singleDetails {
    box-shadow: 0px 2px 12px rgb(0 0 0 / 10%);
    margin: 15px 0;
    padding: 18px;
    position: relative;
    width: 100%;
    float: left;
    border-radius: 16px;

    .topTag {
      position: absolute;
      right: 18px;
      top: 0;
      background: #daffe1;
      padding: 2px 8px;
      color: #13b03f;
      transform: translate(0px, -50%);
    }
    .headTop {
      display: grid;
      grid-template-columns: 50px auto;
      gap: 15px;
      align-items: center;
      img {
        max-width: 100%;
      }
      h2 {
        font-weight: 700;
        font-size: 20px;
      }
      span {
        font-weight: 300;
        font-size: 12px;
      }
    }
    .investmentDetailsCard {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      h2 {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
      }
      p {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}

.noResult {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter_popup {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  z-index: 101;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  z-index: 1111;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.filter_popup.open {
  bottom: 200px;
}

.filter_heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 20px;
}

.filter_heading h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  color: #000000;
}

.filter_name {
  padding-bottom: 60px;
  height: 250px;
}

.filter_name h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 21px;
  color: #1d1d1d;
  padding: 20px 0;
  padding-left: 15px;
}

.active_filter {
  position: relative;
  color: #f36f21 !important;
}

.active_filter::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 100%;
  bottom: 0;
  left: -10px;
  background: linear-gradient(144.66deg, #ff9556 3.94%, #f36f21 91.87%);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.filterType {
  padding: 10px;
}

.filterDivider {
  border-right: 1px solid #ececec;
  padding: 0 20px;
}

.filter_option {
  padding-left: 20px;
}

.filter_option p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15em;
  color: #000000;
  padding-bottom: 20px;
}

.filter_option input {
  margin-right: 10px;
}

.filter_option label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #7f7f7f;
}

.apply_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
}

.apply_filter h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 21px;
  color: #868686;
}

.apply_filte_btn {
  background: linear-gradient(144.66deg, #ff9556 3.94%, #f36f21 91.87%);
  border: 1px solid #f36f21;
  border-radius: 96px;
  padding: 15px 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.filter_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000091;
  height: 100%;
  z-index: 111;
}

.filter_tenure {
  display: flex;
  //   gap: 0.8rem;
  //   position: relative;
  //   padding: 0;
  align-items: center;
  //   border: 1px solid red;
  margin-bottom: 20px;
}

.filter_tenure label {
  margin-bottom: 0;
}

.filter_tenure input {
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  appearance: none;
  border: 2px solid #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter_tenure input:checked {
  border: 2px solid #8d26d2;
}

input[type="radio"]:after {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  position: relative;
  /* background-color: #d1d3d1; */
  content: "";
  display: inline-block;
  visibility: visible;
}

input[type="radio"]:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  position: relative;
  background-color: #8d26d2;
  content: "";
  display: inline-block;
  visibility: visible;
}

.filter_tenure input {
  width: 20px;
  height: 20px;
}

.filterAndSort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mobilePagination {
  padding: 30px 20px 100px 20px;
}
