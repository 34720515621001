.gsec_cancel_modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.gsec_cancel_modal {
  position: relative;
  width: 503px;
  height: 353px;
  background: #ffffff;
  border-radius: 24px;
  padding: 30px;
}

.gsec_cancel_modal_top h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #303030;
  padding-top: 20px;
}

.gsec_cancel_modal_top img {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.gsec_cancel_modal_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gsec_cancel_modal_inner h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #303030;
}

.gsec_cancel_modal_inner p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(48, 48, 48, 0.4);
}

.gsec_cancel_modal_btn button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  outline: none;
  width: 50%;
  border-radius: 8px;
  height: 48px;
  width: 100%;
}

.gsec_btn_yes {
  border: 1px solid #f36f21;
  color: #f36f21;
  background: #fff;
}

.gsec_btn_no {
  background: linear-gradient(144.66deg, #ff9556 3.94%, #f36f21 91.87%);
  color: #ffffff;
  border: none;
}

.gsec_cancel_modal_issue {
  display: flex;
  align-items: center;
}

.gsec_cancel_modal_issue img {
  width: 50px;
  margin-right: 15px;
}

.gsec_cancel_modal_inner {
  padding: 30px 0;
}

.bid_cancel_loader .loading_screen.loading_inside {
  border-radius: 24px;
}

@media (max-width: 768px) {
  .gsec_cancel_modal {
    width: 70%;
    padding: 20px;
  }

  .gsec_cancel_modal_inner h2 {
    font-size: 14px;
  }

  .gsec_cancel_modal_issue img {
    width: 40px;
    margin-right: 10px;
  }

  .gsec_cancel_modal_top h2 {
    font-size: 20px;
  }

  .gsec_cancel_modal_inner {
    padding: 40px 0;
  }
}

@media (max-width: 576px) {
  .gsec_cancel_modal {
    width: 90%;
  }
}