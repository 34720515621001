.main {
	width: 100%;
	height: 100%;
	padding-top: 64px;
	display: grid;
	grid-template-rows: 1fr auto;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;

	.dematrepeat{
		display: block;

		.singledemat
		{
			display: flex;
			width: 100%;

			.inputs
			{
				flex-direction: row;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-gap: 24px;
				gap: 24px;
				align-items: end;
				margin-top: 25px;
				@media screen and (max-width: 768px) {
					grid-template-columns: auto auto;
					align-items: center;
					direction: ltr;
					grid-auto-flow: column;
				}
				div
				{
					display:flex;
					flex-wrap: wrap;
					span
					{
						display: block;
						opacity: 0.6;
						font-family: Poppins;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						letter-spacing: normal;
						text-align: left;
						color: #303030;
						margin-bottom: 15px;
					}
					input{
						height: 48px;
						border: none;
						outline: none;
						padding: 0 16px;
						border-radius: 8px;
						background-color: #30303018;
						width: 100%;
						
					}
					
				}
				.removeDmat
				{
					width: 65px;
					height: 48px;
					padding: 11px 20px 13px 21px;
					border-radius: 32px;
					box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
					border: solid 1px #f36f21;
					background: none;
					@media screen and (max-width: 768px) {
						grid-row: 1/3;
					}
				}
			}
		}
	}

	h2 {
		font-family: Poppins;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		margin-bottom: 16px;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	.input1 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 24px;

		.toggleContainer {
			@media screen and (max-width: 768px) {
				width: 61.11%;
				min-width: 220px;
			}
		}

		div {
			display: flex;
			flex-direction: column;

			.light {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.86;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}

			select {
				// width: 264px;
				height: 48px;
				padding: 0 16px;
				display: flex;
				align-items: center;
				border-radius: 8px;
				background-color: #30303015;
				font-family: Poppins;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
				border: none;
				outline: none;
			}
		}
	}

	.addHolder {
		display: flex;
		align-items: center;
		background: none;
		outline: none;
		border: none;
		width: fit-content;
		gap: 10px;
		color: #2e276c;
		margin-top: 48px;

		span {
			font-family: Poppins;
			font-size: 16px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			text-align: left;
			color: #2e276c;
		}
	}
	a.addHolder {
		margin-top: 20px;
	}

	.dAccountOpen {
		background-image: linear-gradient(107deg, #21f3f3 -77%, #214cf3 96%);
		color: #ffff;
		 border-radius: 5rem;
		box-sizing: border-box;
		padding: 1.1rem 0;
		text-align: center;
		transition: all 0.5s ease;
		cursor: pointer;display: inline-block;max-width: 220px;
		margin-top: 15px;
		&:hover {
			color: #fff;
			opacity: 0.8;
		}
	}
}

.form {
	h3 {
		margin-top: 48px;
		font-family: Poppins;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	.inputs {
		margin-top: 16px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 24px;

		div {
			display: flex;
			flex-direction: column;
			gap: 9px;

			span {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 14px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}
		}

		input {
			height: 48px;
			border: none;
			outline: none;
			padding: 0 16px;
			border-radius: 8px;
			background-color: #30303018;
			font-size: 16px;
			color: rgb(0, 0, 0, 0.6);
			width: 100%;

			&::placeholder {
				opacity: 0.6;
				font-family: Poppins;
				font-size: 16px;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: normal;
				text-align: left;
				color: #303030;
			}
		}
	}
}

.buttons {
	margin: 30px 0 10px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;

	.outline {
		width: 217px;
		height: 64px;
		display: grid;
		place-content: center;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.2px;
		text-align: right;
		color: #f36f21;

		background: none;
		outline: none;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		img{
			transform: rotate(180deg);
		}
	}

	.primary {
		width: 217px;
		height: 64px;
		padding: 0 16px 0 24px;
		border-radius: 12px;
		box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
		border: solid 1px #f36f21;
		background-image: linear-gradient(113deg, #ff9556 8%, #f36f21 83%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.24px;
		text-align: right;
		color: rgba(255, 255, 255, 0.603);
	}
}

@media only screen and (max-width: 1024px) {
	.main {
		.container {
			.form {
				.inputs {
					display: flex;
					flex-direction: column;
					width: 90%;
					min-width: 264px;
				}
			}
		}
		.buttons {
			.outline {
				width: 50%;
			}
			.primary {
				width: 50%;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.main {
		padding-left: 10px;
		padding-right: 10px;
		.container {
			.input1 {
				display: flex;
				flex-direction: column;
				select {
					width: 73.3%;
					min-width: 264px;
				}
			}
			.form {
				.inputs {
					display: flex;
					flex-direction: column;
					width: 73.3%;
				}
			}
		}
		.buttons {
			.outline {
				width: 40%;
			}
			.primary {
				width: 45%;
			}
		}
	}
}