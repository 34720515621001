.payment-detail {
  display: none;
}

.payment-detail h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-top: 30px;
  padding-bottom: 15px;
}

.payment-detail-box-content {
  display: flex;
  justify-content: space-between;
}

.payment-detail-box-content p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #1D1D1D;
  margin: 0;
}

.payment-detail-box-content small {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #7F7F7F;
}

.payment-detail-box-content h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #1D1D1D;
}

.payment-detail-box {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.to-pay-amount {
  display: flex;
  justify-content: space-between;
  border-top: 1px dashed #ccc;
  padding-top: 20px;
}

.to-pay-amount h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #1D1D1D;
}


@media (max-width: 992px) {

  .payment-detail {
    display: block;
  }
}