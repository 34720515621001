$smobile: 767px;
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

.two_line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main {
  padding: 42px 70px 80px 112px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.fallback {
  display: block;
  flex-direction: column;
  width: 100%;
  text-align: center;
  filter: grayscale(1);
  img {
    margin-bottom: 5px !important;
    position: static !important;
    width: auto !important;
  }
  p {
    font-size: 24px;
    color: #a2a2a2;
    line-height: 28px;
    @media screen and (max-width: $smobile) {
      font-size: 12px;
      line-height: 18px;
      padding: 10px;
    }
  }
}
.hidecstominp {
  display: none;
}
.custwrapwrap {
  display: inline;
  span {
    position: relative;
    top: 0;
    z-index: 9;
    bottom: 0;
    display: flex;
    left: 15px;
    align-items: center;
  }
}
.custombox {
  height: 50px;
  padding: 15px;
  width: 200px;
  float: right !important;
  position: relative;
  left: 325px;
  border-radius: 10px;
  border: none;
  padding-left: 33px;
  color: #fff;
}
.section1 {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    gap: 24px;
    align-items: center;

    .innerLeft {
      $dia: 80px;
      width: $dia;
      height: $dia;
      border-radius: 50%;
      overflow: hidden;

      @media screen and (max-width: $smobile) {
        flex: 1;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .sec2mobile {
      display: none;
      @media screen and (max-width: $smobile) {
        flex-direction: row;
        flex: 4;
        flex-wrap: wrap;
        gap: 10px 20px;
        margin-top: 0px;
        display: flex;
        h1 {
          width: 100%;
          margin: 0;
        }
        button {
          background: rgb(26, 26, 26);
          flex: 1;
          text-align: center;
          font-size: 11px;
        }
        .secmobilescheme {
          flex: 2;
          color: #303030;
        }
      }
    }

    .innerRight {
      height: 100%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: $smobile) {
        display: none;
      }
      // justify-content: space-between;

      h1 {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
        margin: 0;
      }

      span {
        opacity: 0.4;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $smobile) {
      width: 100%;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: 20px;
    }

    .topLine {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: right;
      color: #303030;
      width: fit-content;
    }

    .bottomLine {
      display: flex;
      align-items: flex-end;
      gap: 8px;

      h3 {
        margin: 0;
        font-family: Poppins;
        font-size: 40px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
      }

      span {
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: left;
        color: #303030;
      }
    }
  }
}

.section2 {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  @media screen and (max-width: $smobile) {
    display: none;
  }

  button {
    width: 182px;
    height: 36px;
    display: grid;
    place-content: center;
    border-radius: 6px;
    background-color: #0ad533;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: 0.07px;
    text-align: right;
    color: #fff;
    border: none;
  }

  div {
    display: flex;
    align-items: center;
    gap: 14.3px;

    img {
      $dia: 23.7px;
      width: $dia;
      height: $dia;
      object-fit: contain;
    }

    span {
      opacity: 0.8;
      font-family: Poppins;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2e276f;
    }
  }
}

.section3 {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 20px;

  .red {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #f00;
    @media screen and (max-width: $smobile) {
      text-align: left !important;
      font-size: 14px;
      span {
        color: rgba(48, 48, 48, 0.6);
        font-weight: 600;
        font-size: 16px;
      }
      .date {
        color: #636363;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .dot {
    $dia: 4px;
    height: $dia;
    width: $dia;
    border-radius: 50%;
    background: #000;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 4px;
    @media screen and (max-width: $smobile) {
      align-items: flex-start;
    }

    h6 {
      margin: 0;
      opacity: 0.8;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #303030;
      @media screen and (max-width: $smobile) {
        color: rgba(48, 48, 48, 0.6);
        font-weight: 600;
        font-size: 16px;
      }
    }

    span {
      margin: 0;
      opacity: 0.8;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: left;
      color: #303030;
      font-family: Poppins;
      font-weight: normal;
      @media screen and (max-width: $smobile) {
        font-size: 14px;
        line-height: 2;
        font-weight: 600;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    span {
      font-family: Poppins;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2e276f;
      @media screen and (max-width: $smobile) {
        font-size: 16px;
      }
    }
  }
}

.section4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.7fr;
  gap: 27px;
  margin-top: 48px;

  .columnDouble.columnsinglmob {
    .companyHealth.item {
      width: 100%;
    }
  }

  .columnDouble {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media screen and (min-width: 2000px) {
      flex-direction: row;
      align-items: center;
    }

    .item {
      width: 100%;
      height: 258px;
      border-radius: 24px;
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      border: solid 2px #fff;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

      @media screen and (max-width: 767px) {
        height: auto;
      }

      &:hover {
        @include scale(1.02);
      }
    }

    .subscriptions {
      padding: 15px 12.5px 15px 12.5px;
      // background: url('../../assets/images/IPODetails/subscription.svg');
      background-size: cover;
      background-position-y: bottom;
      position: relative;
      @media screen and (max-width: $smobile) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
      }
      img {
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        margin: auto;
        @media screen and (max-width: $smobile) {
          position: absolute;
          width: 100%;
          margin-bottom: 0;
        }
      }

      h6 {
        margin: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: center;
        color: #303030;

        @media screen and (max-width: $smobile) {
          margin-top: 10px;
        }
      }
    }

    .companyHealth {
      padding: 15px 15px 15px 15px;
      position: relative;
      @media screen and (max-width: $smobile) {
        padding-top: 10px;
      }
      h6 {
        margin: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: center;
        color: #303030;
      }

      .left {
        position: absolute;
        top: 64px;
        left: 20px;
        width: 83px;
        height: 83px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: url("../../assets/images/IPODetails/Group\ 15292.svg");
        @media screen and (max-width: $smobile) {
          left: 0;
        }

        span {
          opacity: 0.8;
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }

        .bold {
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.06px;
          text-align: center;
          color: #fff;
        }
      }

      .right {
        position: absolute;
        bottom: 25px;
        right: 27px;
        width: 114px;
        height: 114px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: url("../../assets/images/IPODetails/Ellipse\ 231.svg");
        @media screen and (max-width: $smobile) {
          right: 0;
          bottom: 0;
        }

        span {
          opacity: 0.8;
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.42;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }

        .bold {
          font-family: Poppins;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.08px;
          text-align: right;
          color: #fff;
        }
      }
    }

    .expected {
      padding: 15px 0 15px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      // background: url('../../assets/images/IPODetails/Mask\ Group\ 76.svg');
      background-size: cover;
      background-position-y: bottom;
      background-repeat: no-repeat !important;
      background-position: center !important;
      overflow: hidden;
      @media screen and (max-width: $smobile) {
        padding-bottom: 0;
      }
      img {
        width: 100%;
        object-fit: cover;
        position: relative;
        top: -100px;
        bottom: 0;
        @media screen and (max-width: 1280px) {
          top: -76px;
        }
        @media screen and(max-width: 1250px) {
          top: -50px;
        }
        @media screen and (max-width: 1160px) {
          top: -20px;
        }
      }
      h6 {
        margin: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: left;
        color: #303030;
        position: relative;
        z-index: 9;
      }

      h5 {
        margin: 0;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.08px;
        text-align: left;
        color: #000;
        position: absolute;
        bottom: 15px;
      }
    }

    .promoter {
      display: flex;
      flex-direction: column;
      background: url("../../assets/images/IPODetails/Mask\ Group\ 77.svg");
      background-size: cover;
      background-position: center;

      h6 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: left;
        color: #303030;
        padding-left: 23px;
        margin-top: 25px;
      }

      .left {
        position: absolute;
        top: 57.5%;
        left: 0;
        transform: translate(0, -50%);
        padding-left: 12px;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;

        h6 {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.08px;
          text-align: left;
          color: #fffcf3;
          padding: 0;
          margin: 0;
        }

        span {
          opacity: 0.8;
          font-family: Poppins;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.06px;
          text-align: left;
          color: #fffcf3;
          width: 45px;
        }
      }

      .right {
        position: absolute;
        top: 55%;
        right: 2.5%;
        transform: translate(0, -50%);
        padding-right: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;

        h6 {
          padding: 0;
          margin: 0;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.06px;
          text-align: left;
          color: #fffcf3;
        }

        span {
          opacity: 0.8;
          font-family: Poppins;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.05px;
          text-align: left;
          color: #fffcf3;
        }
      }
    }

    .insight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px 15px 15px 15px;

      @media screen and (max-width: $smobile) {
        padding: 12px 12px 15px 12px;
      }

      h6 {
        margin: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: center;
        color: #303030;
      }

      .meter {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        .top {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.06px;
          text-align: left;
          color: #303030;
        }

        img {
          width: 188px;

          @media screen and (max-width: $smobile) {
            width: 100%;
            height: auto;
          }
        }

        .bottom {
          width: 100%;
          display: flex;
          justify-content: space-between;

          span {
            opacity: 1;
            font-family: Poppins;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.05px;
            text-align: left;
            color: #303030;
          }
        }
      }

      a {
        width: 100%;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        color: #2e276f;

        @media screen and (max-width: $smobile) {
          line-height: 18px;
          padding-top: 5px;
        }
      }
    }

    .blocktimer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding: 15px;

      h6 {
        margin: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        text-align: left;
        color: #303030;
        position: relative;
        z-index: 9;
        text-align: center;
      }
      .cardcounter {
        font-size: 24px;
        color: #2e276f;
        font-weight: 500;
        @media screen and (max-width: $smobile) {
          font-size: 18px;
        }

        span {
          &:first-child {
            width: 100%;
            display: block;
            text-align: center;
            font-weight: 900;
            text-transform: capitalize;
          }
        }
      }
    }

    .performance {
      padding: 15px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: 100%;

      .fallback {
        img {
          height: auto;
        }
      }

      .item {
        box-shadow: none;
      }

      h6 {
        text-align: center;
        margin: 0;
        padding: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        color: #303030;
        flex: 1;
      }

      .bars {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;
          height: fit-content;

          h6 {
            padding: 0;
            margin: 0;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.06px;
            text-align: left;
            color: #303030;
          }

          div {
            width: 32px;
            height: 33px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #216bf3;
            opacity: 0.2;
          }

          span {
            width: fit-content;
            opacity: 0.8;
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.06px;
            text-align: left;
            color: #303030;
          }
        }

        .bar1 {
          div {
            height: 33px;
          }
        }

        .bar2 {
          div {
            height: 63px;
          }
        }

        .bar3 {
          div {
            height: 86px;
            opacity: 1;
            background: linear-gradient(153deg, #21f3f3 -29%, #214cf3 117%);
          }
        }
      }

      img {
        margin-bottom: 45px;
      }

      span {
        text-align: center;
        width: 100%;
        opacity: 0.33;
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.06px;
        color: #303030;
        @media screen and (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }

  .columnSingle {
    @media screen and (max-width: $smobile) {
      min-width: 100% !important;
    }
    .item {
      width: 100%;
      height: 100%;
      border-radius: 26px;
      background-image: linear-gradient(143deg, #3f369a 5%, #241f55 104%);
      padding: 33px 24px 24px 24px;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        padding: 0;
        margin: 0;
        font-family: Poppins;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.11px;
        text-align: left;
        color: #fff;
        text-align: center;

        @media screen and (max-width: $smobile) {
          font-size: 20px;
        }
      }

      .subHeading {
        opacity: 0.8;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
      }

      .slider {
        width: 100%;
        position: relative;
        margin-top: 30px;
        .slidervaluwrp {
          position: absolute;
          top: -50px;
          width: 150px;
          transform: translate(-50px, 0);

          @media screen and (max-width: $smobile) {
            top: -35px;
          }
        }
        .filledwidth {
          background: rgb(255, 255, 255);
          height: 10px;
          position: relative;
          bottom: -20px;
          z-index: 1;
          pointer-events: none;
          border-radius: 10px;
          min-width: 18px;
          @media screen and (max-width: 992px) {
            bottom: -18px;
          }
          @media screen and (max-width: $smobile) {
            bottom: -17px;
          }

          &::before {
            content: "";
            width: 25px;
            height: 25px;
            background: #2173f3;
            position: absolute;
            z-index: 9;
            border: 6px solid #fff;
            border-radius: 50%;
            top: -8px;
            right: 0;
            pointer-events: none;
          }
        }
        .sliderbar {
          cursor: pointer;
        }
        .sliderbar::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;
          width: 25px; /* Set a specific slider handle width */
          height: 25px; /* Slider handle height */
          background-image: linear-gradient(to left, #2191f3, #2159f3);
          cursor: pointer; /* Cursor on hover */
          border-radius: 50%;
          border: 6px solid #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          position: relative;
          z-index: 9;
          opacity: 0;
        }

        .custommorerange {
          height: 40px;
          height: 40px;
          border: none;
          border-radius: 8px;
          width: 150px;
          float: right;
          opacity: 1;
          background: #fff;
          padding: 5px;
        }
        input {
          width: 100%;
          -webkit-appearance: none; /* Override default CSS styles */
          appearance: none;
          width: 100%; /* Full-width */
          height: 8px; /* Specified height */
          outline: none; /* Remove outline */
          opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
          -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
          transition: opacity 0.2s;
          opacity: 1;
          border-radius: 5px;
          background-image: linear-gradient(to left, #2191f3, #2159f3);
        }

        .sub {
          margin-top: 9px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .initiallimit {
            flex: 1;
          }
          .belowlimt {
            padding-right: 15px;
          }
          span {
            opacity: 0.4;
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
          }
        }
      }

      .sharePrice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: $smobile) {
          flex-direction: column;
          align-items: flex-start;
        }

        .left {
          display: flex;
          flex-direction: column;
          @media screen and (max-width: $smobile) {
            /* flex-wrap: nowrap; */
            width: 100%;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            margin-bottom: 20px;
          }

          h4 {
            opacity: 0.8;
            font-family: Poppins;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            @media screen and (max-width: $smobile) {
              font-size: 14px;
            }
          }

          span {
            opacity: 0.4;
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
          }
        }

        .toggle {
          width: 204px;
          height: 48px;
          border-radius: 24px;
          background-color: rgba(255, 255, 255, 0.1);
          position: relative;
          @media screen and (max-width: $smobile) {
            width: 100%;
          }

          .names {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            position: relative;
            z-index: 10;

            .fade {
              opacity: 0.3;
            }

            span {
              font-family: Roboto;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #fff;
            }
          }

          .toggler {
            width: 102px;
            height: 48px;
            padding-left: 35px;
            border-radius: 24px;
            background: linear-gradient(135deg, #21a6f3, #214cf3);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            transition: left 0.3s ease;
            color: #fff;
            @media screen and (max-width: $smobile) {
              width: 50%;
              font-size: 18px !important;
              font-weight: 400;
              color: #fff;
            }
          }

          .active {
            left: 102px;
            transition: left 0.3s ease;
            @media screen and (max-width: $smobile) {
              left: 50%;
            }
          }
        }
      }

      .totalQty {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          opacity: 0.8;
          font-family: Poppins;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }

        span {
          opacity: 0.4;
          font-family: Poppins;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }
      }

      .apply {
        width: 100%;
        height: 64px;
        border-radius: 32px;
        box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
        border: solid 1px #f36f21;
        background-image: linear-gradient(104deg, #ff9556 9%, #f36f21 81%);
        display: grid;
        place-content: center;
        border: none;
        font-family: Poppins;
        font-size: 23px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.23px;
        text-align: left;
        color: #fff;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.05);
          transition: all 0.3s;
        }
      }
    }
  }
}

.section5 {
  margin-top: 66px;
  width: 100%;

  .header {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: left;
    color: #313131;
  }

  .cards {
    margin-top: 35px;
    display: flex;
    gap: 38px;
    @media screen and (min-width: 767px) {
      flex-direction: row;
    }
    @media screen and (max-width: 760px) {
      flex-direction: column;
    }

    .card1 {
      width: 578px;
      height: 412px;
      margin: 1px 0 0;
      border-radius: 24px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      @media screen and (min-width: 767px) {
        flex: 1;
      }

      iframe {
        height: 100%;
        width: 100%;
      }

      .image {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: grid;
        place-content: center;
        padding-bottom: 10rem;
        background: #d61e30;
        color: #fff;
        font-family: Poppins;
        font-weight: 900;
        font-size: 3rem;
      }

      .gradient {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(36, 31, 85, 0),
          rgba(36, 31, 85, 0.9) 65%,
          #241f55
        );
        z-index: 2;
      }

      .text {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 16px 32px 16px;

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          $dia: 64px;
          height: $dia;
          width: $dia;
        }

        .foot {
          display: flex;
          flex-direction: column;

          h6 {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.08px;
            text-align: left;
            color: #fff;
            margin: 0;
          }

          span {
            margin-top: 12px;
            width: 112px;
            height: 32px;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.233);
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.93;
            letter-spacing: 0.06px;
            text-align: left;
            color: #fff;
            display: grid;
            place-content: center;
          }
        }
      }
    }

    .card2 {
      width: 578px;
      height: 412px;
      margin: 0 0 1px 38px;
      padding: 16px 16px 32px 32px;
      border-radius: 20px;
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
      background-image: linear-gradient(318deg, #3f369a 97%, #3b338f 7%);
      padding: 16px 16px 32px 32px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      @media screen and (min-width: 767px) {
        flex: 1;
        margin: 0;
        max-width: 50%;
      }

      .back {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background: url("../../assets/images/IPODetails/Mask\ Group\ 81@3x.png");
        background-position-y: bottom;
        background-size: cover;
        background-position-x: right;
      }

      .head {
        position: relative;
        z-index: 3;

        h5 {
          font-family: Poppins;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.13px;
          text-align: left;
          color: #fff;
        }
      }

      .foot {
        position: relative;
        z-index: 3;

        a {
          width: 403px;
          height: 64px;
          display: grid;
          place-content: center;
          border-radius: 30px;
          box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
          background-color: #fff;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
          font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.24px;
          text-align: left;
          color: #322b7a;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
            transition: all 0.3s;
          }
        }
      }
    }

    .card3funding {
      background: linear-gradient(90deg, #7328d9 0%, #8d26d2 100%);
      border-radius: 20px;
      flex: 1 1 0;
      padding: 30px;

      @media screen and (min-width: 767px) {
        max-width: 50%;
      }

      button.fundingbtn {
        background: rgba(255, 255, 255, 0.24);
        border-radius: 4px;
        padding: 6px 17px;
        border: none;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
      }
      h2 {
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        color: #ffffff;
        margin-top: 26px;
        margin-bottom: 24px;
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        span.bold {
          font-weight: 700;
          font-size: 16px;
          white-space: nowrap;
        }
        br {
          @media screen and (max-width: $smobile) {
            display: none;
          }
        }
      }
      .btnwrap {
        margin-top: 32px;
        display: flex;
        gap: 24px;
        flex-direction: row;
        a {
          width: 207px;
          height: 56px;
          background: rgba(255, 255, 255, 0.24);
          border-radius: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #ffffff;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.1);
            transition: all 0.3s;
          }
        }
        a.applynow {
          background: #f36f21;
        }
      }
    }
  }
}

.section6 {
  margin-top: 64px;
  position: relative;
  &::before {
    content: "";
    filter: blur(102px);
    background-color: #f36f21;
    width: 100%;
    height: 100%;
    position: absolute;
    left: -10%;
    z-index: -1;
    opacity: 0.2;
    bottom: 0;
  }
  .header {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: left;
    color: #313131;
  }

  .container {
    width: 100%;
    margin-top: 32px;
    padding: 36px 36px 36px 60px;
    border-radius: 19px;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    border: solid 2px #fff;
    background-color: #fff;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 90px;

    .left {
      ul {
        list-style: inherit;
        padding-left: 20px;
      }
      p {
        opacity: 0.8;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
      }

      .columns {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: column;

          h6 {
            margin: 0;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.06px;
            text-align: left;
            color: #303030;
          }

          span {
            opacity: 0.6;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #303030;
            margin-top: 8px;
          }
        }
      }

      .list {
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .item {
          display: flex;
          gap: 15px;

          div {
            $dia: 8px;
            height: $dia;
            width: $dia;
            border-radius: 50%;
            background: black;
            margin-top: 7px;
          }

          span {
            opacity: 0.8;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #303030;
          }
        }

        a {
          margin-left: 23px;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.14px;
          text-align: left;
          color: #2e276f;
        }
      }
    }

    .right {
      height: 464px;
      border-radius: 19px;
      border: solid 2px #fff;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.section7 {
  margin-top: 64px;
  position: relative;
  &::before {
    content: "";
    border-radius: 255px;
    filter: blur(42px);
    background-color: #f36f21;
    width: 210%;
    height: 130%;
    position: absolute;
    top: 0;
    left: -110px;
    bottom: 0;
    z-index: -1;
    opacity: 0.1;
  }

  .header {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: left;
    color: #313131;

    @media screen and (max-width: $smobile) {
      font-size: 28px;
      line-height: 35px;
    }
  }
  .card {
    .top {
      width: auto;
      padding: 0 10px;
    }
  }
  .cards {
    padding: 26px 0;
    display: flex;
    gap: 16px;
    overflow: auto;

    .card1 {
      width: 596px;
      height: 334px;
      padding: 24px 32px 32px 24px;
      border-radius: 24px;
      background-image: linear-gradient(116deg, #4940a9 3%, #241f55 98%);
      display: flex;
      gap: 12px;
      position: relative;

      .top {
        position: absolute;
        width: auto;
        padding: 0 10px;
        height: 36px;
        display: grid;
        place-content: center;
        border-radius: 37px;
        background-image: linear-gradient(109deg, #fbbf00 2%, #ff421d 99%);
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        right: 20px;
        top: 0;
        z-index: 5;
        transform: translate(0, -50%);

        &::before {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          content: "";
          width: 109px;
          height: 36px;
          opacity: 0.69;
          border-radius: 37px;
          background-image: linear-gradient(109deg, #fbbf00 2%, #ff421d 99%);
          -webkit-filter: blur(6px);
          filter: blur(6px);
        }

        span {
          position: relative;
          z-index: 10;
        }
      }

      img {
        width: 64px;
        height: 64px;
      }

      .right {
        height: 100%;
        position: relative;

        h3 {
          font-family: Poppins;
          font-size: 32px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }

        .subHeading {
          span {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
          }

          .light {
            margin-left: 5px;
            opacity: 0.67;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 200;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.06px;
            text-align: left;
            color: #fff;
          }
        }

        .sub {
          margin-top: 33px;
          display: flex;
          align-items: flex-end;

          img {
            width: 24px;
            height: 24px;
            margin: 0 12px 0 0;
            object-fit: contain;
          }

          span {
            opacity: 0.8;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ffb780;
          }
        }

        .invest {
          margin-top: 33px;
          display: flex;
          align-items: flex-end;

          img {
            width: 24px;
            height: 24px;
            margin: 0 12px 0 0;
            object-fit: contain;
          }

          span {
            opacity: 0.8;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
          }
        }

        .buttons {
          position: absolute;
          bottom: 0;
          display: flex;

          .button1 {
            width: 184px;
            height: 48px;
            margin: 0 20px 0 0;
            border-radius: 24px;
            box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
            background-color: #fbfbfc;
            border: none;
            display: grid;
            place-content: center;
            -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.16px;
            text-align: left;
            color: #241f55;
          }

          .button2 {
            width: 184px;
            height: 48px;
            border-radius: 24px;
            box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
            background-color: #fbfbfc10;
            border: none;
            -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.16px;
            text-align: left;
            color: #fbfbfc;
            display: grid;
            place-content: center;
          }
        }
      }
    }

    .card {
      min-width: 290px;
      max-width: 290px;
      //width: 290px;
      height: 400px;
      padding: 24px;
      border-radius: 24px;
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
      position: relative;
      background: #fff;
      h3 {
        @media screen and (max-width: 767px) {
          min-height: 100px;
        }
      }
      @media screen and (max-width: 767px) {
        min-height: 400px;
        height: auto;
      }

      .top {
        position: absolute;
        height: 36px;
        display: grid;
        place-content: center;
        border-radius: 37px;
        background-image: linear-gradient(109deg, #fbbf00 2%, #ff421d 99%);
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        right: 20px;
        top: 0;
        z-index: 5;
        transform: translate(0, -50%);

        span {
          position: relative;
          z-index: 10;
        }
      }
      .cardBody {
        display: grid;
        grid-template-rows: 60px auto;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          object-fit: contain;
          overflow: hidden;
        }

        .body {
          margin-top: 9px;
          display: grid;
          grid-template-rows: 90px 28px 25px;
          grid-gap: 30px;
          @media screen and (max-width: 767px) {
            grid-template-rows: auto auto auto;
            grid-gap: 10px;
          }
          h3 {
            font-family: Poppins;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 25px;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            padding-right: 24px;
          }

          .subHeading {
            margin-top: 9px;
            opacity: 0.6;
            font-family: Poppins;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.06px;
            text-align: left;
            color: #fff;
          }

          .invest {
            margin-top: 25px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 767px) {
              margin: 0;
              align-items: center;
            }

            img {
              width: 24px;
              height: 24px;
              margin: 0 12px 0 0;
              object-fit: contain;
            }

            span {
              opacity: 0.8;
              font-family: Poppins;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #fff;
            }
          }
        }
      }

      .buttons {
        // position: absolute;
        // bottom: 24px;
        @media screen and (max-width: 767px) {
          position: static;
        }

        button {
          width: 100%;
          height: 48px;
          margin: 41px 6px 1px 0;
          border-radius: 24px;
          display: grid;
          place-content: center;
          box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
          background-color: #fbfbfc;
          border: none;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.16px;
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main {
    .section3 {
      a {
        text-align: center;
      }
    }
    .section4 {
      display: flex;
      flex-direction: column-reverse;
      .columnSingle {
        min-width: 320px;
        @media screen and (max-width: $smobile) {
          min-width: auto;
        }
      }
      .columnDouble {
        flex-direction: row;
        min-width: 320px;
        @media screen and (max-width: $smobile) {
          min-width: auto;
        }
        .item {
          width: 50%;
        }
        .expected {
          padding-top: 10px;
          img {
            height: 100%;
            width: 100%;
            top: auto;
          }
          h6 {
            @media screen and (max-width: $smobile) {
              text-align: center;
              padding: 0px 10px;
            }
          }
        }
        .performance {
          padding-left: 5px;
          padding-top: 15px;
          h6 {
            text-align: center;
          }
        }
      }
    }
    .section5 {
      .card2 {
        .foot {
          a {
            font-size: 16px;
            width: 80%;
            text-align: center;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main {
    .fallback {
      img {
        width: auto;
        height: auto;
        max-width: 150px;
      }
    }
    padding: 100px 20px 20px 20px;
    .section1 {
      flex-direction: column;
      justify-content: center;
      align-content: center;
      .right {
        align-self: start;
      }
    }
    .section3 {
      min-width: 320px;
      display: grid;
      grid-template-columns: 50% 50%;
      @media screen and (max-width: $smobile) {
        min-width: auto;
      }
      @media screen and (max-width: 375px) {
        grid-template-columns: 40% 60%;
      }
      .red {
        flex-direction: column;
        display: flex;
        text-align: center;
      }
      .info {
        display: flex;
        flex-direction: column;
      }
      .dot {
        display: none;
      }
    }
    .section4 {
      display: flex;
      flex-direction: column-reverse;
      @media screen and (max-width: $smobile) {
        gap: 0;
      }
      .fullmobilecolumn.columnDouble {
        @media screen and (max-width: $smobile) {
          // flex-direction: column;
          .blocktimer {
            width: 50%;
          }
        }
      }
      .columnDouble {
        flex-direction: row;

        @media screen and (max-width: $smobile) {
          margin-top: 15px;
          gap: 0;
          div {
            &:first-child {
              margin-right: 10px;
            }
          }
        }
        .subscriptions {
          .fallback {
            img {
              width: auto;
              height: auto;
            }
          }
        }
        .item {
          width: 50%;
        }
        .expected {
          padding-top: 10px;
          img {
            height: 100%;
            width: 100%;
            top: auto;
          }
        }
        .performance {
          padding-left: 5px;
          padding-top: 15px;
          @media screen and (max-width: $smobile) {
            width: 50%;
            margin-bottom: 0;
          }
          h6 {
            text-align: center;
          }
        }
      }
    }
    .section5 {
      .cards {
        .card1 {
          height: 300px;
          width: 100%;
          iframe {
            height: 100%;
            width: 100%;
          }
        }
        .card2 {
          width: 100%;
          // height: 300px;
          margin-left: 0px;
          @media screen and (max-width: 767px) {
            padding: 20px;
          }
          .foot {
            a {
              width: 80%;

              @media screen and (max-width: 767px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .section6 {
      .container {
        display: flex;
        flex-direction: column-reverse;
        padding: 24px 10px;
        .right {
          margin: auto 5%;
          height: auto;
          width: 90%;
        }
      }
    }
    .section7 {
      .cards {
        flex-direction: row;
        overflow-x: scroll;
        align-items: flex-end;
        height: max-content;
        padding-bottom: 20px;
        padding-top: 30px;
        .card {
          .top {
            width: auto;
            padding: 0 10px;
          }
          .cardBody {
            .body {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.ncd_haeder {
  width: 100%;
  height: 87px;
  line-height: 87px;
  background: #ffffff;
  // box-shadow: 0px 4px 7px #ecddf3;
  padding-left: 100px;
}
