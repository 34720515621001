.main {
	width: 100%;
	height: 100%;
	padding-top: 64px;
	display: grid;
	grid-template-rows: 1fr auto;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;

	.upiinputwrp
	{
		display: flex;
		gap: 25px;

		.upiusermsg
		{
			margin: 10px 0;
			padding: 0 5px;
			font-size: 14px;
			font-weight: normal;
			text-align: left;
			color: #a2a2a2;
			max-width: 350px;
			.upifetchsuccess
			{
				color: rgba(0, 128, 0, 1);
				font-weight: 800;
			}
			.upifetchfailed
			{
				color: rgb(255, 0, 0);
			}
		}
	}

	h2 {
		margin: 0;
		font-family: Poppins;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
		margin-bottom: 21px;
	}

	span {
		opacity: 0.6;
		font-family: Poppins;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.86;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	.input {
		width: 377px;
		height: 48px;
		border-radius: 8px;
		background-color: #30303013;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		grid-template-rows: 100%;
		padding: 12px 0;
		@media screen and (max-width:767px) {
			display: flex;
			flex-wrap: nowrap;
			max-width: 300px;
			position: relative;
		}

		input {
			height: 100%;
			background: none;
			border: none;
			outline: none;
			padding: 0 16px;
			width: auto;
		}

		select {
			height: 100%;
			background: none;
			border: none;
			outline: none;
			padding: 0;
			padding-left: 10px;
			border-left: solid 0.5px #70707048;
			border-radius: 0;
			@media screen and (max-width:767px) {
				padding: 0;
				padding-left: 10px;
				width: 120px;
				position: absolute;
				right: 0;
				top: 0px;
				padding-right: 10px;
			}
		}
	}

	h5 {
		margin-top: 80px;
		opacity: 0.6;
		font-family: Poppins;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.5;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
		margin-bottom: 4px;
	}

	h1 {
		font-family: Poppins;
		font-size: 32px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		margin: 0;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	a {
		margin-top: 36px;
		font-family: Poppins;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.5;
		letter-spacing: normal;
		text-align: left;
		color: #2e276c;
	}
}

.buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.outline {
		width: 217px;
		height: 64px;		
		place-content: center;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.2px;
		text-align: right;
		color: #f36f21;

		background: none;
		outline: none;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px;
		img{
			transform: rotate(180deg);
		}
	}

	.primary {
		width: 217px;
		height: 64px;
		padding: 0 16px 0 24px;
		border-radius: 12px;
		box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
		border: solid 1px #f36f21;
		background-image: linear-gradient(113deg, #ff9556 8%, #f36f21 83%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.24px;
		text-align: right;
		color: rgba(255, 255, 255, 0.603);
	}
}

.ASBAcontainer {
	width: 100%;
	display: flex;
	flex-direction: column;

	h2 {
		margin: 0;
		font-family: Poppins;
		font-size: 20px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
		margin-bottom: 21px;
	}

	.inputs {
		display: flex;
		flex-wrap: nowrap;
		gap: 1.5rem;
		margin-bottom: 4rem;
		
	}

	.inputContainer {
		flex: 1 1 0;
		span {
			opacity: 0.6;
			font-family: Poppins;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 2.86;
			letter-spacing: normal;
			text-align: left;
			color: #303030;
		}

		.input {
			width: 100%;
			max-width: 370px;
			height: 48px;
			border-radius: 8px;
			background-color: #30303013;
			display: grid;
			grid-template-columns: 1fr auto;
			align-items: center;
			grid-template-rows: 100%;
			padding: 12px 0;

			input {
				height: 100%;
				background: none;
				border: none;
				outline: none;
				padding: 0 16px;
			}

			select {
				height: 100%;
				background: none;
				border: none;
				outline: none;
				padding: 0 76px 0 16px;
				border-left: solid 0.5px #70707048;
				border-radius: 0;
			}
		}
	}

	h5 {
		opacity: 0.6;
		font-family: Poppins;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.5;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
		margin-bottom: 4px;
	}

	h1 {
		font-family: Poppins;
		font-size: 32px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		margin: 0;
		letter-spacing: normal;
		text-align: left;
		color: #303030;
	}

	a {
		margin-top: 36px;
		font-family: Poppins;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 2.5;
		letter-spacing: normal;
		text-align: left;
		color: #2e276c;
	}
}

.buttons {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;

	.outline {
		width: 217px;
		height: 64px;
		
		place-content: center;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.2px;
		text-align: right;
		color: #f36f21;

		background: none;
		outline: none;
		border: none;
	}

	.primary {
		width: 217px;
		height: 64px;
		padding: 0 16px 0 24px;
		border-radius: 12px;
		box-shadow: 0 6px 48px 0 rgba(28, 28, 28, 0.08);
		border: solid 1px #f36f21;
		background-image: linear-gradient(113deg, #ff9556 8%, #f36f21 83%);
		display: flex;
		align-items: center;
		justify-content: space-between;
		-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
		font-family: Poppins;
		font-size: 24px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.24px;
		text-align: right;
		color: rgba(255, 255, 255, 0.603);
	}
}

@media only screen and (max-width: 768px) {
	.main {
		padding-left: 10px;
		padding-right: 10px;
		overflow: hidden;
		.container {
			overflow: hidden;
			.upiinputwrp {
				display: flex;
				flex-direction: column;
				// .input {
				// 	width: 90%;
				// }
			}
		}
		.buttons {
			.outline {
				width: 40%;
			}
			.primary {
				width: 45%;
			}
		}
	}
	.ASBAcontainer {
		padding-left: 10px;
		padding-right: 10px;
		.inputs {
			flex-direction: column;
		}
	}
}