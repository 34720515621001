.sectionSlider {
	margin-bottom: 12px;
	.header {
		margin-top: 52px;
		width: 100%;
		padding-right: 70px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 24px;

		h3 {
			font-family: Poppins;
			font-size: 24px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.1px;
			text-align: left;
			color: #313131;
		}

		a {
			-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
			font-family: Poppins;
			font-size: 20px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: -0.2px;
			text-align: left;
			color: #2e276f;
		}
	}
}

.videoItem {
	width: 556px !important;
	max-width: 100% !important;
	height: auto;
	position: relative;
	i{
		position: absolute;
		font-size: 100px;
		color: #FF0000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: max-content;
		height: max-content;
		pointer-events: none;
		transition: all 0.3s;

		@media only screen and (max-width: 768px) {
			font-size: 60px;
		}
		
	}
	&:hover{
		i{
			transform: scale(1.1);
			transition: all 0.3s;
		}
	}
	img {
		width: 100%;
	}
}

.videoSliderItem {
	border-radius: 24px;
	overflow: hidden;
}

.sliderButtons {
	width: 100%;
	display: flex;
	margin-top: 24px;
	padding-right: 70px;
	justify-content: flex-end;
	gap: 28px;

	.button {
		width: 50px;
		height: 50px;
		padding: 17px 17px 16px 16px;
		border-radius: 25px;
		background: linear-gradient(136deg, #3f369a 4%, #241f55 103%);
		display: grid;
		place-content: center;
		border: none;
	}
}
.buttonInactive {
	border-radius: 40px;
	box-shadow: 0 3px 48px 0 rgba(28, 28, 28, 0.04);
	background: #29282821 !important;
}

@media only screen and (max-width: 768px) {
	.videoItem {
		width: 360px !important;
		max-width: 100% !important;

		img {
			width: 100%;
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}
}
